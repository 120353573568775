import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useState } from 'react';
import classNames from 'classnames';
import arrowForwardIcon from '@mui/icons-material/ArrowForward';

const Container = styled.div`
    ${(props) => css`
        margin: 0 23px 32px;
        padding: 24px;
        border: 1px solid ${props.theme.colors.outlineLight};
        border-radius: 10px;
        ${!props.theme.screen.desktop &&
        css`
            margin: 0 16px 24px;
            padding: 16px;
        `}
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 17px;
            line-height: 20px;
        `}
    `}
`;

const Text = styled.div`
    ${(props) => css`
        margin-top: 32px;
        font-family: 'Albert-Regular';
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        ${!props.theme.screen.desktop &&
        css`
            margin-top: 16px;
            font-size: 16px;
            line-height: 19px;
        `}
    `}
`;

const LongDescription = styled.div`
    transition: max-height 0.7s ease;
    overflow: hidden;
    max-height: 0;
    &.slide {
        max-height: 2000px;
    }
`;

const Button = styled.div`
    ${(props) => css`
        margin-top: 16px;
        display: flex;
        align-items: center;
        color: ${props.theme.colors.brandRed};
        font-family: 'Albert-Regular';
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        cursor: pointer;
        ${!props.theme.screen.desktop &&
        css`
            margin-top: 24px;
        `}
    `}
`;

const Arrow = styled(arrowForwardIcon)`
    height: 18px !important;
    width: 18px !important;
    margin-left: 8px;
    &.up {
        rotate: 270deg;
    }
`;

type DescriptionProp = {
    description: string;
    descriptionLong: string;
};

export const Description = (props: DescriptionProp) => {
    const { t } = useTranslation();
    const [descriptionOpen, setDescriptionOpen] = useState(false);

    return (
        <>
            {(props.description.length > 0 || props.descriptionLong.length > 0) && (
                <Container>
                    <Header>{t('description')}</Header>
                    {props.description.length > 0 && <Text>{props.description}</Text>}
                    <LongDescription className={classNames({ slide: descriptionOpen })}>
                        {props.descriptionLong.length > 0 && (
                            <Text
                                className="measuringWrapper"
                                dangerouslySetInnerHTML={{ __html: props.descriptionLong }}
                            />
                        )}
                    </LongDescription>
                    {props.descriptionLong && (
                        <Button
                            onClick={() => {
                                setDescriptionOpen((prev) => !prev);
                            }}
                        >
                            {descriptionOpen ? <>{t('hide_description')}</> : <>{t('full_description')}</>}
                            <Arrow className={classNames({ up: descriptionOpen })} />
                        </Button>
                    )}
                </Container>
            )}
        </>
    );
};
