import { connect } from 'react-redux';
import { IState } from '../../../store';
import { TopicHead } from '../../../types/api';

const PureTopicBreadcrumb = (head: TopicHead) => {
    return <span>{head?.name}</span>;
};

const mapStateToProps = (state: IState, props: any) => {
    const { headId } = props.match.params;

    const head = state.categories.categoriesList
        .flatMap((category) => category.topics)
        .flatMap((topic) => topic.heads)
        .find((head) => head.id === parseInt(headId));

    return (
        head || {
            name: '...',
        }
    );
};

export default connect(mapStateToProps)(PureTopicBreadcrumb);
