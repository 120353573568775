import { useState } from 'react';
import { ReactComponent as StarImg } from '../../assets/images/BusinessListCard/star_icon.svg';
import styled from 'styled-components';

const StarRating = styled.div`
    @media screen and (max-width: 400px) {
        transform: translateX(-7px);
    }
`;

const StarButton = styled.button`
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
`;
const Star = styled(StarImg)`
    width: 18px;
    height: 19px;
    margin-right: 4px;
    &.on {
        filter: none;
    }
    &.off {
        filter: grayscale(100);
        opacity: 40%;
    }
`;
interface Props {
    rating: number;
    ratingName: string;
    setRating: (value: number, ratingName: string) => void;
}

export const StarsClickable = (props: Props) => {
    const [hover, setHover] = useState(0);
    return (
        <StarRating className="star-rating">
            {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                    <StarButton
                        type="button"
                        key={index}
                        onClick={() => props.setRating(index, props.ratingName)}
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(props.rating)}
                    >
                        <Star className={index <= (hover || props.rating) ? 'on' : 'off'} />
                    </StarButton>
                );
            })}
        </StarRating>
    );
};
