import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ResponsiveLine } from '@nivo/line';
import SouthIcon from '@mui/icons-material/South';

import { IWorkerStatistics, WorkerStatisticItem } from '../../../../types/api';

import classnames from 'classnames';
import styled, { css } from 'styled-components';
import { IState } from '../../../../store';

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 16px 0;
`;

const ChartWrapper = styled.div`
    width: 100%;
    height: 272px;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            height: 332px;
        `}
`;

const TooltipWrapper = styled.div`
    ${(props) =>
        css`
            background-color: ${props.theme.colors.white};
            border-radius: 8px;
            padding: 8px;
            box-shadow: 0px 3px 0px ${(props) => props.theme.colors.outlineAndPlaceholder};
        `}
`;

const BottomWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 6px;
`;

const ShowMoreButton = styled.div`
    color: ${(props) => props.theme.colors.brandRed};
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    margin: 0 16px;
`;

const ShowMoreText = styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 120%;
`;

const DataTextWrapper = styled.div`
    font-family: 'Albert-Regular';
    display: flex;
    align-items: center;
    font-size: 12px;
    letter-spacing: 0.005em;
    margin: 0 16px;
    color: ${(props) => props.theme.colors.secondaryBlack};
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            font-size: 13px;
            width: auto;
        `}
`;

const ArrowIcon = styled(SouthIcon)`
    transition: transform 0.2s ease-in-out;
    font-size: 14px !important;
    &.up {
        transform: rotate(-180deg);
    }
`;

const ChartBottom = styled.div`
    ${(props) =>
        css`
            color: ${props.theme.colors.secondaryBlack};
            margin: 0 6px 0 0;
            &:first-child {
                margin-right: 20px;
                width: 90px;
            }
        `}
`;

interface Props {
    employeeStatistics: IWorkerStatistics;
}

export const EmployeeAmountChart = (props: Props) => {
    const { t } = useTranslation();
    const { screenViewPort } = useSelector((state: IState) => state.global);

    const [showMore, setShowMore] = useState<boolean>(false);

    const formatData = (data: WorkerStatisticItem[]) => {
        const formattedData = data.map((item) => {
            return {
                x: new Date(item.date).toLocaleDateString('lt-LT'),
                y: item.count,
            };
        });
        return { id: 'Darbuotojai', color: '#bfdbeb', data: formattedData };
    };

    return (
        <Container>
            <ChartWrapper>
                <ResponsiveLine
                    enableSlices="x"
                    data={[formatData(props.employeeStatistics.totalCount)]}
                    sliceTooltip={({ slice }) => {
                        return (
                            <TooltipWrapper>
                                <div>{slice.points[0].data.xFormatted.toString().replace('00:00:00', '')}</div>
                                <div>{slice.points[0].data.yFormatted}</div>
                            </TooltipWrapper>
                        );
                    }}
                    margin={{ top: 10, right: 30, bottom: screenViewPort !== 'desktop' ? 80 : 30, left: 50 }}
                    axisTop={null}
                    axisRight={null}
                    pointSize={1}
                    pointBorderWidth={2}
                    pointLabelYOffset={-12}
                    colors={(data) => data.color}
                    yScale={{
                        type: 'linear',
                        min: 'auto',
                        max: 'auto',
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickValues: 7,
                    }}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: screenViewPort !== 'desktop' ? 90 : 0,
                        legendOffset: 36,
                        legendPosition: 'middle',
                        format: (value) => {
                            const index = props.employeeStatistics.totalCount.findIndex((item) => item.date === value);
                            return index % 5 === 0 || index === props.employeeStatistics.totalCount.length - 1
                                ? value
                                : '';
                        },
                    }}
                />
            </ChartWrapper>
            <BottomWrapper>
                {props.employeeStatistics.totalCount.length > 0 && (
                    <ShowMoreButton onClick={() => setShowMore((prev) => !prev)}>
                        <ShowMoreText>{!showMore ? t('expand_data') : t('collapse_data')}</ShowMoreText>
                        <ArrowIcon
                            className={classnames({
                                up: showMore,
                            })}
                        />
                    </ShowMoreButton>
                )}
                {showMore && (
                    <div>
                        {props.employeeStatistics.totalCount
                            .slice(-8)
                            .reverse()
                            .map((item, index) => {
                                return (
                                    <DataTextWrapper key={index}>
                                        <ChartBottom>{new Date(item.date).toLocaleDateString('lt-LT')}</ChartBottom>
                                        <ChartBottom>{t('employee_amount')}</ChartBottom>
                                        <ChartBottom>{item.count}</ChartBottom>
                                    </DataTextWrapper>
                                );
                            })}
                    </div>
                )}
            </BottomWrapper>
        </Container>
    );
};
