import classNames from 'classnames';
import styled, { css } from 'styled-components';

const Card = styled.img<{ height?: string | number; width?: string | number; maxWidth?: string | number }>`
    height: 100%;
    width: 100%;
    border-radius: 0 0 10px 10px;
    &.clean-border {
        border-radius: 10px;
    }
    ${(props) =>
        props.height &&
        css`
            height: ${props.height};
        `}
    ${(props) =>
        props.width &&
        css`
            width: ${props.width};
            max-width: ${props.maxWidth};
        `}
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            width: 250px;
            height: 250px;
        `}
`;

const StyledLink = styled.a`
    ${(props) => css`
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        ${props.theme.screen.tablet &&
        css`
            &.review-ad {
                width: 50%;
            }
        `}
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
        `}
        &.w-full {
            flex: 1;
            display: flex;
            justify-content: center;
        }
    `}
`;

const InnerWrapper = styled.div<{ height?: string | number; width?: string | number; maxWidth?: string | number }>`
    ${Card} {
        ${(props) =>
            props.height &&
            css`
                height: ${props.height};
            `}
        ${(props) =>
            props.width &&
            css`
                width: ${props.width};
                max-width: ${props.maxWidth};
            `}
        ${(props) =>
            !props.height &&
            css`
                height: 250px;
            `}
        ${(props) =>
            !props.width &&
            css`
                width: 250px;
            `}
    }
`;
const Heading = styled.div`
    text-align: center;
    ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px 16px;
        height: 92px;
        font-family: 'Albert-Regular';
        font-size: 24px;
        font-weight: 700;
        color: ${props.theme.colors.black};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 18px;
        `};
    `};
`;

interface Props {
    adUrl: string;
    adHref: string;
    header?: string;
    customDimensions?: {
        width?: string;
        height?: string;
        maxWidth?: string;
    };
    fullWidth?: boolean;
    cleanBorder?: boolean;
    reviewAd?: boolean;
}
export const AdCard = (props: Props) => {
    return (
        <StyledLink
            href={props.adHref}
            target="_blank"
            rel="noreferrer"
            className={classNames({
                'w-full': props.fullWidth,
                'review-ad': props.reviewAd,
            })}
        >
            <InnerWrapper
                height={props.customDimensions?.height || '100%'}
                width={props.customDimensions?.width}
                maxWidth={props.customDimensions?.maxWidth}
            >
                {/* {props.header && <Heading>{props.header}</Heading>} */}
                <Card
                    className={classNames({
                        'clean-border': props.cleanBorder,
                    })}
                    src={props.adUrl}
                    height={props.customDimensions?.height || '100%'}
                    width={props.customDimensions?.width}
                    maxWidth={props.customDimensions?.maxWidth}
                />
            </InnerWrapper>
        </StyledLink>
    );
};
