import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

const Container = styled.div`
    ${(props) => css`
        overflow: auto;
        overflow-y: hidden;
        margin-top: 24px;
        display: flex;
        gap: 40px;
        border-bottom: 1px solid ${props.theme.colors.greyBg};
        ${props.theme.screen.tablet &&
        css`
            margin-top: 48px;
        `}
        ${props.theme.screen.mobile &&
        css`
            margin-top: 32px;
        `}
    `}
`;
const Button = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        color: ${props.theme.colors.outlineAndPlaceholder};
        text-transform: uppercase;
        white-space: nowrap;
        padding-bottom: 20px;
        cursor: pointer;
        &:hover {
            border-bottom: 1px solid ${props.theme.colors.brandRed};
            color: ${props.theme.colors.brandRed};
            padding-bottom: 19px;
        }
    `}
`;

type Props = {
    handleClick: (refInfo: string) => void;
    menuList: { label: string; navClick: string }[];
};

export const CompanyMenuButtons = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Container>
            {props.menuList.map((item, index) => (
                <Button key={index} onClick={() => props.handleClick(item.navClick)}>
                    {t(item.label)}
                </Button>
            ))}
            {/* <Button onClick={() => props.handleClick('information')}>{t('information')}</Button>
            <Button onClick={() => props.handleClick('about')}>{t('about_the_company')}</Button>
            <Button onClick={() => props.handleClick('employees')}>{t('employees')}</Button>
            <Button>{t('finance')}</Button>
            <Button>{t('courts')}</Button>
            <Button>{t('procurement')}</Button>
            <Button onClick={() => props.handleClick('feedback')}>{t('feedback')}</Button> */}
        </Container>
    );
};
