import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';

import styled, { css } from 'styled-components';
import globe from '../../../assets/images/SlidingCardIcons/globe.svg';
import letter from '../../../assets/images/SlidingCardIcons/letter_icon.svg';
import phone from '../../../assets/images/SlidingCardIcons/phone_icon.svg';
import pin from '../../../assets/images/SlidingCardIcons/pin_icon.svg';
import { ReactComponent as People } from '../../../assets/images/SlidingCardIcons/people_icon.svg';
import { ReactComponent as Euro } from '../../../assets/images/SlidingCardIcons/euro_icon.svg';
import { ReactComponent as Calendar } from '../../../assets/images/SlidingCardIcons/calendar_icon.svg';
import { ReactComponent as Hammer } from '../../../assets/images/SlidingCardIcons/hammer_icon.svg';
import { CompanyData } from '../../../types/api';

const Card = styled.div`
    ${(props) => css`
        height: fit-content;
        position: sticky;
        top: 0;
        &.mobile-off {
            position: relative;
        }
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
        `}
    `}
`;

const Divider = styled.div`
    ${(props) => css`
        height: 1px;
        width: 100%;
        background-color: ${props.theme.colors.outlineLight};
        margin: 24px 0;
        ${!props.theme.screen.desktop &&
        css`
            margin: 18px 0;
            &.mobile-top {
                margin: 32px 0 16px 0;
            }
            &.mobile-bot {
                margin: 16px 0 32px 0;
            }
        `}
    `}
`;

const AditionalContainer = styled.div`
    ${(props) => css`
        border: 1px solid ${props.theme.colors.greyBg};
        background-color: ${props.theme.colors.white};
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        margin-top: 16px;
        ${!props.theme.screen.desktop &&
        css`
            padding: 16px 24px;
        `}
    `}
`;
const LogoText = styled.p`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 14px;
            line-height: 20px;
        `}
    `}
`;
const SmallText = styled.p`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        margin: 0 0 7px 0;
        color: ${props.theme.colors.outlineAndPlaceholder};
        &.desktop-margin {
            margin: 0 0 8px 0;
        }
    `}
`;
const CenterContainer = styled.div`
    ${(props) => css`
        border: 1px solid ${props.theme.colors.greyBg};
        border-radius: 10px;
        padding: 16px;
        margin-top: 16px;
        display: block;
        box-shadow: 0px 4px 10px 0px ${props.theme.colors.shadow};
    `}
`;
const TextUnderSmall = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        display: flex;
        align-items: center;
        .icon {
            min-width: 24px;
            margin-right: 4px;
        }
        ${!props.theme.screen.desktop &&
        css`
            font-size: 14px;
            line-height: 20px;
        `}
    `}
`;
const Row = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Logo = styled.img`
    max-width: 66px;
    max-height: 66px;
`;

const Text = styled.a`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        display: flex;
        justify-content: space-between;
        margin: 0 0 17px 0;
        text-decoration: none;
        cursor: pointer;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 13px;
            line-height: 16px;
        `}
        &.no-margin {
            margin: 0;
            display: flex;
            align-items: center;
        }
        &.last {
            margin-bottom: 0;
        }
        ${props.theme.screen.mobile &&
        css`
            &.contacts {
                flex-direction: column;
                margin-bottom: 0;
            }
            &.first {
                order: 4;
            }
            &.second {
                order: 2;
            }
            &.third {
                order: 3;
            }
            &.fourth {
                order: 1;
            }
        `}
    `}
`;

const Icon = styled.img`
    ${(props) => css`
        ${props.theme.screen.mobile &&
        css`
            height: 24px;
            min-width: 24px;
        `}
    `}
`;

const MobileText = styled.p`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        margin: 8px 0 0 0;
        color: ${props.theme.colors.outlineAndPlaceholder};
    `}
`;

const ContactInfo = styled.div`
    width: calc(100% - 16px);
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
`;
const OneOfFour = styled.div`
    width: 48%;
`;

const ContactContainer = styled.div``;

const showInMapClicked = (lat: number, long: number) => {
    if (lat && long) {
        window.open('https://maps.google.com?q=' + lat + ',' + long);
    }
};

type Props = {
    data: CompanyData;
};

export const CompanyMenuCard = (props: Props) => {
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();

    return (
        <Card>
            {screenViewPort === 'mobile' && (
                <ContactContainer>
                    <Divider className="mobile-top" />
                    <ContactInfo>
                        <Text
                            className="contacts first"
                            onClick={(e) => {
                                showInMapClicked(props.data.address.latitude, props.data.address.longitude);
                            }}
                        >
                            <Icon src={pin} />
                            <MobileText>{t('address')}</MobileText>
                        </Text>
                        <Text className="contacts second" href={`tel:${props.data.phone}`}>
                            <Icon src={phone} />
                            <MobileText>{t('phone')}</MobileText>
                        </Text>
                        <Text className="contacts third" href={`mailto:${props.data.email}`}>
                            <Icon src={letter} />
                            <MobileText>{t('email')}</MobileText>
                        </Text>
                        <Text className="last contacts fourth" href={props.data.website}>
                            <Icon src={globe} />
                            <MobileText>{t('website')}</MobileText>
                        </Text>
                    </ContactInfo>
                    <Divider className="mobile-bot" />
                </ContactContainer>
            )}
            {screenViewPort !== 'tablet' && (
                <CenterContainer>
                    <Row>
                        {props.data.workerStatistics.totalCount.length > 0 && (
                            <OneOfFour>
                                <SmallText>{t('employees')}</SmallText>
                                <TextUnderSmall>
                                    <>
                                        <People className="icon" />
                                        {
                                            props.data.workerStatistics.totalCount[
                                                props.data.workerStatistics.totalCount.length - 1
                                            ].count
                                        }
                                    </>
                                </TextUnderSmall>
                            </OneOfFour>
                        )}
                        <OneOfFour>
                            <SmallText>{t('authorized_capital')}</SmallText>
                            <TextUnderSmall>
                                <Euro className="icon" />
                                {props.data.startingCapital}
                            </TextUnderSmall>
                        </OneOfFour>
                    </Row>
                    <Divider />
                    <Row>
                        <OneOfFour>
                            <SmallText>{t('registration_date')}</SmallText>
                            <TextUnderSmall>
                                <Calendar className="icon" />
                                {props.data.registrationDate}
                            </TextUnderSmall>
                        </OneOfFour>
                        {props.data.legalStatus && (
                            <OneOfFour>
                                <SmallText>{t('legal_status')}</SmallText>
                                <TextUnderSmall>
                                    <Hammer className="icon" />
                                    {t(`legal_statuses.${props.data.legalStatus}`)}
                                </TextUnderSmall>
                            </OneOfFour>
                        )}
                    </Row>
                </CenterContainer>
            )}
            {screenViewPort === 'mobile' && (
                <AditionalContainer>
                    <LogoText>{props.data.name}</LogoText>
                    {props.data.logo && <Logo src={props.data.logo.logoPath} />}
                </AditionalContainer>
            )}
        </Card>
    );
};
