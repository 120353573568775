import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, CircularProgress, Modal, TextareaAutosize, Checkbox, FormControlLabel, FormControl } from '@mui/material';
import { StarsClickable } from '../../Stars/StarsClickable';
import Close from '@mui/icons-material/Close';

import { postCompanyFeedback } from '../../../services/company.service';
import { FeedbackRatings, FullFeedback, Review } from '../../../types/api';

import { ReactComponent as CameraIcon } from '../../../assets/images/BusinessListCard/camera.svg';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';

import styled, { css } from 'styled-components';
import { ImageView } from './components/ImageView';
import { IState } from '../../../store';
import { editUserFeedback } from '../../../services/reviews.services';

const Heading = styled.div`
    font-size: 24px;
    font-weight: 700;
`;

const CriteriaContainer = styled.div`
    ${(props) => css`
        max-width: 400px;
        display: flex;
        flex-direction: column;
        color: ${props.theme.colors.black};
        gap: 18px;
    `};
`;

const CriteriaTitle = styled.div`
    font-size: 18px;
`;

const Criteria = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 400px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const CriteriaName = styled.div`
    font-size: 12px;
    flex: 1;
    text-transform: uppercase;
`;

const TextArea = styled(TextareaAutosize)`
    ${(props) => css`
        width: calc(100% - 32px) !important;
        height: 250px !important;
        border-radius: 4px;
        padding: 6px 16px;
        border: 1px solid ${props.theme.colors.outlineLight};
        color: ${props.theme.colors.black};
        font-size: 16px;
        font-family: 'Albert-Regular';
        ${props.theme.screen.mobile &&
        css`
            height: 150px !important;
        `}
        &:focus {
            outline: none;
        }
    `};
`;

const SubmitRatingButton = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        background-color: ${props.theme.colors.brandRed};
        color: ${props.theme.colors.white};
        font-size: 15px;
        font-weight: 500;
        width: 100%;
        height: 42px;
        min-height: 42px;
        &:hover {
            transition: box-shadow 0.2s ease-in-out;
            box-shadow: 0px 3px 0px ${(props) => props.theme.colors.outlineAndPlaceholder};
            cursor: pointer;
            font-weight: 700;
        }
    `};
`;

const CloseIcon = styled(Close)`
    ${(props) => css`
        position: absolute;
        top: 16px;
        right: 16px;
        color: ${props.theme.colors.black};
        &:hover {
            cursor: pointer;
        }
    `};
`;

const ImageUploadWrapper = styled.div`
    width: 100%;
    display: flex;
`;

const UploadTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Text = styled.div`
    font-size: 18px;
    font-weight: 400;
    font-family: 'Albert-Regular';
    line-height: 120%;
    color: ${(props) => props.theme.colors.black};
    &.small {
        font-size: 11px;
    }
`;
const UploadButton = styled.label`
    cursor: pointer;
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 18px;
    border-radius: 10px;
    ${(props) => css`
        background-color: ${props.theme.colors.greyBg};
        color: ${props.theme.colors.black};
    `}
`;

const ImageContainer = styled.div`
    position: relative;
`;
const DeleteIcon = styled(HighlightOffTwoToneIcon)`
    position: absolute;
    top: -8px;
    right: -11px;
    ${(props) => css`
        color: ${props.theme.colors.black};
        &:hover {
            cursor: pointer;
        }
    `}
`;

const ImagePreviewWrapper = styled.div`
    display: flex;
    position: relative;
    flex-wrap: wrap;
    gap: 10px;
`;
const ImagePreview = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
    border: 1px solid ${(props) => props.theme.colors.outlineLight};
`;

const ErrorText = styled.div`
    color: ${(props) => props.theme.colors.brandRed};
    font-size: 12px;
    font-weight: 500;
    font-family: 'Albert-Regular';
`;

const Camera = styled(CameraIcon)``;

interface Props {
    companyName: string;
    boughtInteractive: boolean;
    companyId: number;
    feedback?: Review;
    isEdit?: boolean;
    show: boolean;
    onClose: () => void;
}
export const FeedbackModal = (props: Props) => {
    const { t } = useTranslation();

    const { screenViewPort } = useSelector((state: IState) => state.global);

    const [errors, setErrors] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<string>(props.feedback?.feedback || '');
    const [ratings, setRatings] = useState<FeedbackRatings>({
        rating_speed: props.feedback?.ratingSpeed || 0,
        rating_quality: props.feedback?.ratingQuality || 0,
        rating_qp_ratio: props.feedback?.ratingQpRatio || 0,
        rating_expectation: props.feedback?.ratingExpectation || 0,
    });
    const [terms_agreement, setFeedbackTerms] = useState<boolean>(props.feedback?.terms_agreement || false);
    const [localLoading, setLocalLoading] = useState<boolean>(false);
    const [images, setImages] = useState<any>(props.feedback?.attachments ? [...props.feedback.attachments] : []);
    const [showError, setShowError] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<any>(null);

    const uploadImage = async (e: any) => {
        setShowError(false);
        const filesLength = e.target.files?.length;
        if (e.target.files && filesLength > 0) {
            if (filesLength + images.length > 10) {
                setShowError(true);
                return;
            }
            setLocalLoading(true);

            const imagesArray: any[] = [];
            for (let i = 0; i < e.target.files.length; i++) {
                const image = e.target.files[i];
                const type = image.type;

                const base64 = await new Promise<string | ArrayBuffer | null>((resolve) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = () => resolve(null);
                    reader.readAsDataURL(image);
                });

                if (base64) {
                    let imgObj = {
                        base64: base64.toString(),
                        name: image.name,
                        type,
                        size: image.size,
                    };
                    imagesArray.push(imgObj);
                }
            }

            setImages((prevImages: any) => [...prevImages, ...imagesArray]);
            setLocalLoading(false);
        }
        e.target.value = null;
    };

    const handleRating = (rating: number, ratingName: string) => {
        setRatings({ ...ratings, [ratingName]: rating });
    };

    const handleFeedbackTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeedbackTerms(event.target.checked);
    };

    const submitRating = () => {
        if (!terms_agreement) {
            setErrors(true);
        } else {
            setErrors(false);
            const body: FullFeedback = {
                ...ratings,
                feedback,
                terms_agreement,
            };
            if (images.length > 0) {
                body.files = images;
            }
            if (!props.isEdit) {
                postCompanyFeedback(props.companyId, body).then((res) => {
                    props.onClose();
                    window.location.reload();
                });
                return;
            }
            if (props.feedback) {
                editUserFeedback(props.companyId, props.feedback.id, body).then((res) => {
                    props.onClose();
                    window.location.reload();
                });
            }
        }
    };

    const deleteImage = (index: number) => {
        let imagesArray = [...images];
        imagesArray.splice(index, 1);
        setImages(imagesArray);
    };

    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={props.show} onClose={() => props.onClose()}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '10px',
                        background: 'white',
                        height: 'auto',
                        width: screenViewPort === 'desktop' ? '600px' : 'calc(90% - 32px)',
                        minHeight: screenViewPort === 'desktop' ? '600px' : 'calc(90% - 32px)',
                        padding: screenViewPort === 'desktop' ? '32px' : '16px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        '@media screen and (max-height: 700px)': {
                            transform: 'translate(-50%, -30%)',
                        },
                        '@media screen and (max-height: 400px)': {
                            transform: 'translate(-50%, -20%)',
                        },
                    }}
                >
                    <CloseIcon onClick={() => props.onClose()} />
                    <Heading>{props.companyName}</Heading>
                    <CriteriaContainer>
                        <CriteriaTitle>{t('ratings.title')}:</CriteriaTitle>
                        {Object.keys(ratings).map((rating: string, index) => (
                            <Criteria key={index}>
                                <CriteriaName>{t(`ratings.${rating}`)}</CriteriaName>
                                <StarsClickable
                                    rating={ratings[rating as keyof typeof ratings]}
                                    setRating={handleRating}
                                    ratingName={rating}
                                />
                            </Criteria>
                        ))}
                    </CriteriaContainer>
                    <TextArea
                        minRows={5}
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        placeholder={`${t('ratings.feedback_placeholder')}`}
                    />
                    <ImageUploadWrapper>
                        <input
                            id="image-upload"
                            onChange={uploadImage}
                            type="file"
                            multiple
                            accept="image/jpeg, image/png, image/jpg"
                            hidden
                        />
                        <UploadButton htmlFor="image-upload">
                            <Camera />
                            <UploadTextWrapper>
                                <Text>{t('ratings.upload_images')}</Text>
                                <Text className="small">{t('ratings.upload_images_note')}</Text>
                            </UploadTextWrapper>
                        </UploadButton>
                    </ImageUploadWrapper>
                    <ImagePreviewWrapper>
                        {images.length > 0 &&
                            images.map((image: any, index: number) => (
                                <ImageContainer key={index}>
                                    <ImagePreview
                                        alt={image.name || image.id}
                                        src={image.base64 || image.contentPath}
                                        onClick={() => setPreviewImage(image)}
                                    />
                                    <DeleteIcon onClick={() => deleteImage(index)} />
                                </ImageContainer>
                            ))}
                        {localLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: 'black',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-14px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                        {showError && <ErrorText>{t('ratings.max_images_error')}</ErrorText>}
                    </ImagePreviewWrapper>
                    {!!props.boughtInteractive && (
                        <>
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={terms_agreement}
                                            onChange={handleFeedbackTerms}
                                            sx={{
                                                alignItems: 'flex-start',
                                                py: 0,
                                                '&.Mui-checked': {
                                                    color: '#DD0014',
                                                },
                                            }}
                                        />
                                    }
                                    label={t('terms_agreement')}
                                    sx={{
                                        alignItems: 'flex-start',
                                        color: '#737373',
                                        fontSize: 11,
                                        fontWeight: 400,
                                    }}
                                    disableTypography={true}
                                />
                            </FormControl>
                            {errors && <ErrorText>{t('ratings.terms_error')}</ErrorText>}
                        </>
                    )}
                    <SubmitRatingButton onClick={() => submitRating()}>{t('ratings.submit_rating')}</SubmitRatingButton>
                </Box>
            </Modal>
            {previewImage && (
                <ImageView
                    url={previewImage.base64 || previewImage.contentPath}
                    onClose={() => setPreviewImage(null)}
                />
            )}
        </>
    );
};
