export const theme = {
    colors: {
        black: '#191D21',
        secondaryBlack: '#474545',
        outlinesAndPlaceholders: '#7E7E7E',
        outlineAndPlaceholder: '#737373',
        outlineLight: '#E1E2E1',
        greyBg: '#F5F5F5',
        white: '#FFFFFF',
        brandRed: '#DD0014',
        hoverRed: '#F05136',
        green: '#02BE6C',
        darkNeutral: '#434E60',
        neutral: '#D9DFD7',
        lightNeutral: '#E9EDE7',
        shadow: 'rgba(33, 33, 33, 0.1)',
        error: '#E6A63B',
        success: '#DCEDC8',
        brandGreen: '#02BE6C',
        informationSmallCardBackground: '#edf3ea',
    },
    screen: {
        desktop: false,
        tablet: false,
        mobile: false,
    },
};
