import styled, { css } from 'styled-components';
import lext from '../../../assets/images/BusinessListCard/lext.png';
import { ReactComponent as redArrow } from '../../../assets/images/BusinessListCard/red_arrow.svg';
import { Stars } from '../../Stars/Stars';
import { CompanyListItem } from '../../../types/api';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import checkCircleIcon from '@mui/icons-material/CheckCircle';

const albertRegular = css`
    font-family: 'Albert-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
`;
const TitleContainer = styled.div<{ noRating: boolean }>`
    ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: ${props.noRating ? '0' : '24px'};
        ${props.theme.screen.mobile &&
        css`
            margin-bottom: ${props.noRating ? '0' : '16px'};
        `}
    `}
`;
const Logo = styled.img`
    ${(props) => css`
        height: 48px;
        width: 23%;
        margin-right: 16px;
        ${props.theme.screen.mobile &&
        css`
            height: 46px;
            width: 40%;
            margin-bottom: 16px;
        `}
    `}
`;
const Title = styled.h3`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        margin: 0px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 18px;
            line-height: 22px;
        `}
    `}
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    ${(props) => css`
        ${props.theme.screen.mobile &&
        css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        `}
    `}
`;
const StarsContainer = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        ${props.theme.screen.mobile &&
        css`
            display: block;
        `}
    `}
`;
const Label = styled.p`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0.1em;
        padding: 8px 12px;
        color: ${props.theme.colors.white};
        border-radius: 10px;
        text-transform: uppercase;
        margin: 0;
        &.green {
            background-color: #02be6c;
        }
        &.orange {
            background-color: #f05136;
        }
        &.grey {
            background-color: #434e60;
        }
    `}
`;
const Lext = styled.img`
    ${(props) => css`
        width: 83px;
        height: 30px;
        margin-left: 16px;
        ${!props.theme.screen.desktop &&
        css`
            width: 62px;
            height: 28px;
        `}
        ${props.theme.screen.mobile &&
        css`
            margin: 24px 0 0 0;
        `}
    `}
`;
const Button = styled.div`
    ${(props) => css`
        cursor: pointer;
        display: flex;
        align-items: center;
        ${props.theme.screen.mobile &&
        css`
            &.margin-top {
                margin-top: 8px;
            }
        `}
    `}
`;
const ReviewText = styled.p`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        color: ${props.theme.colors.brandRed};
        margin: 16px 0 0 0;
        &.no-margin {
            margin-top: 0;
        }
        ${!props.theme.screen.desktop &&
        css`
            display: block;
            margin-top: 24px;
        `}
        ${props.theme.screen.mobile &&
        css`
            margin-top: 16px;
        `}
    `}
`;
const RedArrow = styled(redArrow)`
    ${(props) => css`
        margin: 16px 0 0 8px;
        &.no-margin {
            margin-top: 0;
        }
        ${props.theme.screen.tablet &&
        css`
            margin-top: 24px;
        `}
        ${props.theme.screen.mobile &&
        css`
            margin-top: 16px;
        `}
    `}
`;
const Divider = styled.div`
    ${(props) => css`
        height: 1px;
        background-color: ${props.theme.colors.outlineLight};
        margin: 24px 0;
        width: 100%;
    `}
`;
const BusinessClassTitle = styled.p`
    ${(props) => css`
        font-family: 'Albert-Bold';
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        margin: 0 40px 0 0;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 13px;
            line-height: 16px;
        `}
        ${props.theme.screen.mobile &&
        css`
            margin-bottom: 8px;
        `}
    `}
`;
const Text = styled.p`
    ${(props) => css`
        ${albertRegular}
        display: flex;
        flex-wrap: wrap;
        gap: 4px 0;
        justify-content: flex-start;
        margin: 0 0 17px 0;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 13px;
            line-height: 16px;
        `}
        &.no-margin {
            margin: 0;
            display: flex;
            align-items: center;
        }
    `}
`;
const LabelContainer = styled.div`
    display: flex;
    align-items: center;
`;
const RubricsContainer = styled.div`
    ${(props) => css`
        display: flex;
        ${props.theme.screen.mobile &&
        css`
            display: block;
        `}
    `}
`;

const Rubric = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-right: 16px;
`;

type Props = {
    data: CompanyListItem;
    cardType: string;
    label?: string;
    activityClass?: string;
};
export const CardTop = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { categoriesList } = useSelector((state: IState) => state.categories);
    const { screenViewPort } = useSelector((state: IState) => state.global);

    const noRating = () => {
        return !props.data?.feedbackCount;
    };
    const labelColor = () => {
        if (props.label === t('we_recommend')) {
            return 'green';
        } else if (props.label === t('the_most_visited')) {
            return 'orange';
        } else if (props.label === t('top_rated')) {
            return 'grey';
        } else return '';
    };

    const cardInnerPage = (e: any) => {
        e.stopPropagation();
        const state = { scrollTo: 'feedback' };
        navigate(`/imones-kortele/${props.data.id}`, { state });
    };

    const navigateToTopic = (topicId: number, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const category = categoriesList.find((category) => category.topics.find((topic) => topic.id === topicId));
        if (category) {
            navigate(`/imoniu-sarasas/${category.id}/${topicId}`);
        }
    };

    return (
        <>
            <TitleContainer noRating={noRating()}>
                <Header>
                    {props.data.logo?.logoPath && <Logo src={props.data.logo.logoPath} />}
                    <Title>{props.data.name}</Title>
                </Header>
            </TitleContainer>
            <StarsContainer>
                {!noRating() && (
                    <Stars
                        rating={props.data.rating}
                        feedbackCount={props.data.feedbackCount}
                        starClass={'bigger-stars'}
                    />
                )}
                <LabelContainer>
                    {screenViewPort === 'desktop' && props.cardType === 'a' && (
                        <Label className={labelColor()}>{props.label}</Label>
                    )}
                    {props.data.lextIntegration && <Lext src={lext} />}
                </LabelContainer>
            </StarsContainer>
            <Button onClick={(e) => cardInnerPage(e)}>
                <ReviewText>{t('write_a_review')}</ReviewText>
                <RedArrow />
            </Button>
            <Divider />
            <RubricsContainer>
                <BusinessClassTitle>{t('rubrics')}:</BusinessClassTitle>
                <Text className="no-margin">
                    {props.data.topics.map((rubric) => (
                        <Rubric onClick={(e) => navigateToTopic(rubric.id, e)} key={rubric.id}>
                            {rubric.name}
                        </Rubric>
                    ))}
                </Text>
            </RubricsContainer>
            <Divider />
        </>
    );
};
