import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ReactComponent as Logo } from '../../assets/images/header_logo.svg';
import { ReactComponent as LogoRed } from '../../assets/images/header_logo_red.svg';
import { ReactComponent as Expandable } from '../../assets/images/expand_less.svg';
import personOutlineIcon from '@mui/icons-material/PersonOutline';
import { LanguageSwitcher } from './LanguageSwitcher';
import { BurgerMenu } from './BurgerMenu';
import { ROUTE_NAMES } from '../../constants/routes';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LoginModal } from '../LoginModal/LoginModal';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../store';
import { UserProfile } from './UserProfile';
import { setLoginModalOpen } from '../../store/global.slice';

const Button = styled.button``;

const HeaderWrapper = styled.div<{ isHome: boolean }>`
    ${(props) => css`
        z-index: 10;
        height: 74px;
        top: 0;
        position: sticky;
        background-color: ${props.isHome ? 'rgba(27, 23, 23, 0.5)' : `${props.theme.colors.white}`};
        font-family: 'Albert-Regular';
        font-size: 15px;
        color: ${props.isHome ? `${props.theme.colors.white}` : `${props.theme.colors.black}`};
        border-bottom: 1px solid ${props.theme.colors.outlineLight};
        .anchor {
            text-decoration: none;
            color: ${props.isHome ? `${props.theme.colors.white}` : `${props.theme.colors.black}`};
        }
        ${Button} {
            color: ${props.isHome ? `${props.theme.colors.white}` : `${props.theme.colors.black}`};
            &.red-btn {
                color: ${props.theme.colors.white};
                padding: 12px 24px;
                background-color: ${props.theme.colors.brandRed};
                border-radius: 100px;
                border: none;
                margin: 0 24px;
                cursor: pointer;
                transition: 0.2s ease-in-out;
                :hover {
                    background-color: ${props.theme.colors.hoverRed};
                }
            }
        }
        &.page-scrolled {
            background-color: rgba(27, 23, 23);
        }
    `}
`;
const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    &.language-switcher {
        width: 40px;
        height: 100%;
    }
`;
const HeaderContainer = styled.div`
    height: 100%;
    display: flex;
    max-width: 1254px;
    margin: 0px auto;
    align-items: center;
    padding: 0 16px;
`;
const HeaderLogo = styled(Logo)`
    height: 40px;
    margin-right: 48px;
`;
const HeaderLogoRed = styled(LogoRed)`
    height: 40px;
    margin-right: 48px;
`;
const Expand = styled(Expandable)`
    margin-left: 6px;
`;
const InformationContainer = styled.div`
    .products {
        margin-right: 64px;
    }
    @media screen and (width < 1000px) {
        display: none;
    }
`;
const LoginContainer = styled.div`
    align-items: center;
    margin-left: auto;
    display: flex;
    @media screen and (width < 1000px) {
        display: none;
    }
`;
const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const Login = styled.div`
    cursor: pointer;
`;
const PersonOutlineIcon = styled(personOutlineIcon)`
    ${(props) => css`
        color: ${props.theme.colors.white};
        &.not-home {
            color: ${props.theme.colors.brandRed};
        }
    `}
`;

export const Header = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { me } = useSelector((state: IState) => state.user);
    const { loginModalOpen, screenViewPort } = useSelector((state: IState) => state.global);

    const [pageScroll, setPageScroll] = useState(false);
    const [userAction, setUserAction] = useState<string>('');

    const location = useLocation();

    const changeColor = () => {
        setPageScroll(window.scrollY > 20);
    };
    const isHome = () => {
        return location.pathname === '/';
    };

    const handleAction = (action: string) => {
        setUserAction(action);
        dispatch(setLoginModalOpen(true));
    };

    useEffect(() => {
        window.addEventListener('scroll', changeColor);
        return () => {
            window.addEventListener('scroll', changeColor);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HeaderWrapper isHome={isHome()} className={classNames({ 'page-scrolled': pageScroll && isHome() })}>
            <HeaderContainer>
                <Link className="anchor" to={ROUTE_NAMES.HOME}>
                    {isHome() ? <HeaderLogo /> : <HeaderLogoRed />}
                </Link>
                {screenViewPort === 'desktop' && (
                    <>
                        <InformationContainer>
                            <Link className="products anchor" to={ROUTE_NAMES.PRODUCTS}>
                                {t('products')}
                            </Link>
                            <Link className="anchor" to={ROUTE_NAMES.EU_PROJECTS}>
                                {t('EU_projects')}
                            </Link>
                        </InformationContainer>
                        <LoginContainer>
                            {!me ? (
                                <ActionWrapper>
                                    <PersonOutlineIcon className={classNames({ 'not-home': !isHome() })} />
                                    <Login className="anchor" onClick={() => handleAction('login')}>
                                        {t('login')}
                                    </Login>
                                    <Button className="red-btn" onClick={() => handleAction('register')}>
                                        {t('register')}
                                    </Button>
                                </ActionWrapper>
                            ) : (
                                <UserProfile />
                            )}
                            <ItemWrapper className="language-switcher">
                                <LanguageSwitcher />
                            </ItemWrapper>
                        </LoginContainer>
                    </>
                )}
                {screenViewPort !== 'desktop' && <BurgerMenu isHome={isHome()} />}
            </HeaderContainer>
            {loginModalOpen && <LoginModal isLoginOpen={loginModalOpen} selectedAction={userAction || 'login'} />}
        </HeaderWrapper>
    );
};
