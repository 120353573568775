import styled, { css } from 'styled-components';
import { IconById } from './IconById';
import { useNavigate } from 'react-router-dom';
import { ICategory } from '../../types/api';

const MainCardContainer = styled.div`
    ${(props) => css`
        align-items: center;
        max-width: 1254px;
        height: fit-content;
        z-index: 10;
        background-color: ${props.theme.colors.white};
        display: flex;
        flex-wrap: wrap;
    `}
`;

const Card = styled.div`
    ${(props) => css`
        align-items: center;
        z-index: 10;
        width: 100%;
        background-color: ${props.theme.colors.white};
        font-family: 'Albert-Regular';
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: ${props.theme.colors.secondaryBlack};
        cursor: pointer;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 13px;
            line-height: 16px;
        `}
    `}
`;
const CardInner = styled.div`
    ${(props) => css`
        border: 1px solid ${props.theme.colors.outlineLight};
        padding: 15px 16px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        ${!props.theme.screen.desktop &&
        css`
            display: block;
            padding: 10px;
        `}
    `}
`;
const Text = styled.p`
    ${(props) =>
        css`
            margin: auto;
            ${!props.theme.screen.desktop &&
            css`
                margin-top: 8px;
            `}
        `}
`;

interface Props {
    category: ICategory;
}

export const CategoryCard = (props: Props) => {
    const navigate = useNavigate();

    const navigateToBusinessList = () => {
        const state = { categoryId: props.category.id };
        navigate(`imoniu-sarasas/${props.category.id}`, { state });
    };

    return (
        <MainCardContainer onClick={navigateToBusinessList}>
            <Card>
                <CardInner>
                    <IconById id={props.category.id} />
                    <Text className="category-name">{props.category.name}</Text>
                </CardInner>
            </Card>
        </MainCardContainer>
    );
};
