import styled, { css } from 'styled-components';
import { Categories } from '../../components/Categories/Categories';
import { RecentReviews } from '../../components/RecentReviews/RecentReview';
import { Slider } from '../../components/CompaniesOfTheWeekSlider/Slider';
import { useSelector } from 'react-redux';
import { IState } from '../../store';

const LandingPageMain = styled.div`
    display: flex;
    justify-content: center;
`;
const CardContainer = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 1254px;
        padding: 0 16px 80px;
        ${props.theme.screen.tablet &&
        css`
            padding-bottom: 40px;
        `}
        ${props.theme.screen.mobile &&
        css`
            padding-bottom: 34px;
        `}
    `}
`;

const Divider = styled.div`
    ${(props) => css`
        height: 1px;
        background-color: ${props.theme.colors.outlineLight};
        width: 100%;
    `}
`;

export const LandingPage = () => {
    const { recommendedCompanies, bannersList, gateIsActive } = useSelector((state: IState) => state.global);

    return (
        <>
            {bannersList.W005[0]?.image && !gateIsActive && <Divider />}
            <LandingPageMain>
                <CardContainer>
                    <Categories />
                    <RecentReviews />
                    {recommendedCompanies.length > 0 && (
                        <Slider
                            cardsData={recommendedCompanies}
                            amountPerScreen={{ desktop: 3, tablet: 2, mobile: 1 }}
                            showHeader={true}
                        />
                    )}
                </CardContainer>
            </LandingPageMain>
        </>
    );
};
