import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from '../../store/language/language.slice';
import localStorage from '../../commons/localStorage';

import { IState } from '../../store';
import { Languages, SELECTED_LANGUAGE_KEY, SiteLangID } from '../../constants/languages';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import styled, { css } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

const SelectedLanguage = styled.div`
    min-width: 40px;
    height: 74px;
    display: flex;
    align-items: center;
`;
const LanguageDropdown = styled.div`
    ${(props) => css`
        position: absolute;
        background-color: ${props.theme.colors.white};
        top: 74px;
        transform: translateX(-30px);
        color: ${props.theme.colors.black};
        ${!props.theme.screen.desktop &&
        css`
            top: 0px;
            display: flex;
            position: relative;
            color: ${props.theme.colors.black};
            width: 50px;
            margin-left: 16px;
        `}
    `}
`;
const LanguageOption = styled.div`
    ${(props) => css`
        padding: 17px 20px 17px 16px;
        cursor: pointer;
        &:hover {
            background-color: ${props.theme.colors.greyBg};
        }
        ${!props.theme.screen.desktop &&
        css`
            &:hover {
                background-color: ${props.theme.colors.greyBg};
            }
        `}
    `}
`;
const Divider = styled.div`
    ${(props) => css`
        height: 1px;
        background-color: ${props.theme.colors.greyBg};
    `}
`;
const LanguageSelect = styled.div`
    cursor: pointer;
`;
const Wrap = styled.div`
    display: flex;
`;
const Underline = styled.div`
    ${(props) => css`
        display: none;
        height: 1px;
        width: 40px;
        background-color: ${props.theme.colors.black};
        transform: translateY(-25px);
        pointer-events: none;
    `}
`;
const Icon = styled(ArrowDropDown)`
    ${(props) => css`
        pointer-events: none;
        width: 10px;
        transform: translate(-20px, 26px);
        ${!props.theme.screen.desktop &&
        css`
            transform: translate(-30px, 10px);
        `}
    `}
`;

const LanguageWrap = styled.div`
    width: 40px;
    &.active {
        ${Underline} {
            display: block;
        }
        ${Icon} {
            rotate: 180deg;
            transform: translate(20px, -26px);
        }
    }
`;
const Language = styled.div`
    ${(props) => css`
        &.selected-language {
            color: ${props.theme.colors.brandRed};
        }
    `}
`;

const fallbackLanguageList = [Languages.LT, Languages.EN, Languages.RU];

export const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const userSelectedLanguage = localStorage.get(SELECTED_LANGUAGE_KEY);
    const { selectedLanguage } = useSelector((state: IState) => state.language);
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const languageList = Object.keys(i18n?.services?.resourceStore?.data) || fallbackLanguageList;
    const navigate = useNavigate();
    const [languageModal, setLanguageModal] = useState(false);

    const switchLanguage = (locale: string) => {
        const upperLocale: any = locale.toUpperCase();

        localStorage.put(SELECTED_LANGUAGE_KEY, { [locale.toUpperCase()]: SiteLangID[upperLocale] });
        navigate(0); // refresh page to get data by the selected language
    };

    useEffect(() => {
        if (userSelectedLanguage) {
            const locale = Object.keys(userSelectedLanguage)[0].toLocaleLowerCase();
            dispatch(changeLanguage(locale));
        }
        if (screenViewPort !== 'desktop') {
            setLanguageModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleMouseOver = () => {
        setLanguageModal(true);
    };

    const handleMouseOut = () => {
        if (screenViewPort === 'desktop') {
            setLanguageModal(false);
        }
    };

    const fullLanguageNameConverter = (locale: any) => {
        switch (locale) {
            case 'lt':
                return 'Lietuvių';
            case 'en':
                return 'English';
            case 'ru':
                return 'Русский';
        }
    };

    return (
        <LanguageWrap className={classNames({ active: languageModal })}>
            <>
                {screenViewPort === 'desktop' && (
                    <LanguageSelect>
                        <Wrap>
                            <SelectedLanguage
                                onClick={() => {
                                    if (screenViewPort === 'desktop') {
                                        setLanguageModal((state) => !state);
                                    }
                                }}
                                onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                            >
                                {selectedLanguage.toLocaleUpperCase()}
                            </SelectedLanguage>
                            <Icon />
                        </Wrap>
                        <Underline />
                    </LanguageSelect>
                )}
                {languageModal && (
                    <LanguageDropdown onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                        {languageList.map((locale, i) => (
                            <Language
                                key={i}
                                className={classNames({ 'selected-language': locale === selectedLanguage })}
                            >
                                <LanguageOption onClick={() => switchLanguage(locale)} key={locale}>
                                    {screenViewPort === 'desktop'
                                        ? fullLanguageNameConverter(locale)
                                        : locale.toUpperCase()}
                                </LanguageOption>

                                <Divider />
                            </Language>
                        ))}
                    </LanguageDropdown>
                )}
            </>
        </LanguageWrap>
    );
};
