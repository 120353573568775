type Props = {
    number: string;
};

export const NumberFormatter = (props: Props) => {
    const numberFormatting = (phone: string) => {
        const firstThreeNumbers = phone.substring(0, 4);
        if (firstThreeNumbers === '+370') {
            const validNumber = phone.replace(/(\d{3})(\d{3})(\d{5})/, '$1 $2 $3');
            return validNumber;
        } else {
            return phone;
        }
    };
    return <>{numberFormatting(props.number)}</>;
};
