import { useTranslation } from 'react-i18next';

import { ReactComponent as SmallLogo } from '../../../../assets/images/InnerCompanyPage/information_small_card_img.svg';

import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IState } from '../../../../store';

const Card = styled.div`
    ${(props) => css`
        flex: 1;
        background-color: ${props.theme.colors.informationSmallCardBackground};
        display: flex;
        align-items: center;
        padding: 16px;
        border-radius: 10px;
        ${!props.theme.screen.desktop &&
        css`
            padding: 10px 16px;
        `}
    `}
`;

const Icon = styled(SmallLogo)`
    ${(props) => css`
        padding-right: 16px;
        &.hide {
            display: none;
        }
        ${!props.theme.screen.desktop &&
        css`
            height: 18px;
            width: 18px;
            padding-right: 8px;
        `}
    `}
`;

const Wrapper = styled.div``;

const Text = styled.p`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: ${props.theme.colors.outlineAndPlaceholder};
        margin: 0 0 8px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 11px;
            line-height: 13px;
            display: flex;
            align-items: center;
        `}
    `}
`;

const Number = styled.span`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        color: ${props.theme.colors.black};
        margin: 0;
        overflow-wrap: anywhere;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 16px;
            line-height: 19px;
            width: 100%;
        `}
    `}
`;

const ValueDate = styled.span`
    ${(props) => css`
        color: #737373;
        font-family: 'Albert-Regular';
        font-size: 11px;
        font-weight: 400;
        margin-left: 6px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 8px;
        `}
    `}
`;

interface Props {
    title: string;
    value: string;
    date?: string;
}

export const InfoCard = (props: Props) => {
    const { t } = useTranslation();
    const { screenViewPort } = useSelector((state: IState) => state.global);

    return (
        <Card>
            <Icon
                className={classNames({
                    hide: screenViewPort !== 'desktop',
                })}
            />
            <Wrapper>
                <Text>
                    <Icon
                        className={classNames({
                            hide: screenViewPort === 'desktop',
                        })}
                    />
                    {props.title}
                </Text>
                {props.value ? <Number>{props.value}</Number> : <Number>{t('no_information_provided')}</Number>}
                {props.date && <ValueDate>{`(${props.date})`}</ValueDate>}
            </Wrapper>
        </Card>
    );
};
