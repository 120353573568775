import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { IState } from '../../store';

import arrowForwardIcon from '@mui/icons-material/ArrowForward';
import { SliderCard } from './SliderCard';
import { BannerItem, ChildCompanies, CompanyData, CompanyListItem, Review } from '../../types/api';
import { useTranslation } from 'react-i18next';
import { BranchesCard } from '../CompanyInnerComponents/AboutCompany/AboutCompanyComponents/PlaceAndWorkingHours/BranchesCard';
import { RecentReviewCard } from '../RecentReviews/RecentReviewCard';

const SliderContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;
const SliderWrap = styled.div`
    max-width: 1254px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;
const SliderCardsContainer = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 30px;
        flex: 1;
        &.two-rows {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            ${props.theme.screen.tablet &&
            css`
                grid-template-columns: repeat(2, 1fr);
            `}
            ${props.theme.screen.mobile &&
            css`
                grid-template-columns: 1fr;
            `}
        }
        ${props.theme.screen.mobile &&
        css`
            gap: 0 23px;
            &.branches-mobile {
                flex-direction: column;
                gap: 24px;
            }
        `}
    `}
`;
const SliderNavigationContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
`;
const Header = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 32px;
        font-weight: 700;
        line-height: 38px;
        margin-bottom: 24px;
        color: ${props.theme.colors.black};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 16px;
        `};
    `}
`;
const ArrowSide = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: 24px;
        margin: 0 16px;
        cursor: pointer;
        fill: ${props.theme.colors.black};
        border: 1px solid ${props.theme.colors.black};
        border-radius: 50px;
        ${props.theme.screen.mobile &&
        css`
            width: 29px;
            height: 29px;
        `}
        &.left-arrow {
            rotate: 180deg;
        }
        &.disabled {
            pointer-events: none;
            filter: opacity(0.4);
        }
    `}
`;
const ArrowForwardIcon = styled(arrowForwardIcon)`
    width: 16px !important;
`;
const Dot = styled.div`
    ${(props) => css`
        height: 6px;
        width: 6px;
        margin: 0 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        background-color: ${props.theme.colors.outlineAndPlaceholder};
        &.active {
            background-color: ${props.theme.colors.black};
        }
    `}
`;
interface Props {
    amountPerScreen: {
        desktop: number;
        tablet: number;
        mobile: number;
    };
    gallerySize?: boolean;
    cardsData: CompanyData[] | CompanyListItem[] | (Review | BannerItem)[];
    showHeader?: boolean;
    class?: string;
    noAdsSlider?: boolean;
    twoRows?: boolean;
}
interface State {
    cardsPerPage: number;
    currentPage: number;
    shownCards: CompanyData[] | ChildCompanies[] | (Review | BannerItem)[];
    pageCount: number;
}

export const Slider = (props: Props) => {
    const { t } = useTranslation();
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const [state, setState] = useState<State>({
        currentPage: 1,
        cardsPerPage: props.amountPerScreen.desktop,
        shownCards: [],
        pageCount: Math.ceil(props.cardsData.length / props.amountPerScreen.desktop),
    });

    const calculateCardsPerPage = () => {
        setCardsView(props.amountPerScreen[screenViewPort]);
    };

    const setCardsView = (cardsPerPage: number) => {
        setState((prev) => {
            const index = findCardIndex(prev.shownCards[0]);
            const pageCount = Math.ceil(props.cardsData.length / cardsPerPage);
            const calculatedPage =
                Math.floor(index / cardsPerPage) + 1 > pageCount ? pageCount : Math.floor(index / cardsPerPage) + 1;
            const currentPage = index === 0 ? 1 : calculatedPage;

            return {
                ...prev,
                cardsPerPage: cardsPerPage,
                pageCount,
                currentPage,
            };
        });
    };

    const findCardIndex = (cardData: CompanyData | ChildCompanies | (Review | BannerItem)) => {
        if (!cardData) {
            return 0;
        }
        return props.cardsData.findIndex((card) => card.id === cardData.id);
    };

    useEffect(() => {
        setState((prev: any) => ({
            ...prev,
            shownCards: props.cardsData.slice(
                (state.currentPage - 1) * state.cardsPerPage,
                state.currentPage * state.cardsPerPage
            ),
        }));
    }, [state.currentPage, state.cardsPerPage, props.cardsData]);

    useEffect(() => {
        calculateCardsPerPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenViewPort]);

    const goToPrevPage = () => {
        setState((prev) => ({
            ...prev,
            currentPage: prev.currentPage - 1,
        }));
    };

    const goToNextPage = () => {
        setState((prev) => ({
            ...prev,
            currentPage: prev.currentPage + 1,
        }));
    };

    const goToSpecificPage = (page: number) => {
        setState((prev) => ({
            ...prev,
            currentPage: page,
        }));
    };

    return (
        <SliderContainer>
            <SliderWrap>
                {props.showHeader && <Header>{t('companies_that_we_recommend')}</Header>}
                <SliderCardsContainer className={(props.class, classNames({ 'two-rows': props.twoRows }))}>
                    {state.shownCards.map((info, index) =>
                        !('description' in info) ? (
                            'createdAt' in info ? (
                                <RecentReviewCard
                                    data={info}
                                    index={index}
                                    noAdsSlider={props.noAdsSlider}
                                    twoRows={props.twoRows}
                                    key={index}
                                />
                            ) : (
                                <BranchesCard data={info} key={index} />
                            )
                        ) : (
                            <SliderCard data={info} key={index} />
                        )
                    )}
                </SliderCardsContainer>
                <SliderNavigationContainer>
                    <ArrowSide
                        className={classNames('left-arrow', { disabled: state.currentPage === 1 })}
                        onClick={goToPrevPage}
                    >
                        <ArrowForwardIcon />
                    </ArrowSide>
                    {Array.from(Array(state.pageCount), (e, i) => (
                        <Dot
                            key={i}
                            className={classNames({ active: state.currentPage === i + 1 })}
                            onClick={() => goToSpecificPage(i + 1)}
                        ></Dot>
                    ))}
                    <ArrowSide
                        className={classNames('right-arrow', { disabled: state.currentPage === state.pageCount })}
                        onClick={goToNextPage}
                    >
                        <ArrowForwardIcon />
                    </ArrowSide>
                </SliderNavigationContainer>
            </SliderWrap>
        </SliderContainer>
    );
};
