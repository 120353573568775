export const SELECTED_LANGUAGE_KEY = 'selected_language';

export enum Languages {
    LT = 'lt',
    EN = 'en',
    RU = 'ru',
}

export enum SiteLangID {
    LT = 4,
    EN = 5,
    RU = 6,
}
