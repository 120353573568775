import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MUIPagination, { PaginationRenderItemParams } from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { PaginationItem } from '@mui/material';

import { getSearchCompaniesList } from '../../services/company.service';
import { resetPagination } from '../../store/companies/companies.slice';

import { IState } from '../../store';
import { CompanyListItem } from '../../types/api';

import eastIcon from '@mui/icons-material/East';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
    setCompanies: (companies: CompanyListItem[]) => void;
}

const StyledStack = styled(Stack)`
    ${(props) => css`
        display: flex;
        padding-bottom: 80px;
        .pagination-item {
            background-color: ${props.theme.colors.white} !important;
            margin: 0;
            padding: 0;
            &.selected {
                font-weight: 700;
            }
        }
    `}
`;

const ArrowPrevious = styled(eastIcon)`
    transform: rotate(180deg);
`;

const ArrowWrapper = styled.div`
    border-radius: 50%;
    width: 24px;
    border: 1px solid ${(props) => props.theme.colors.outlineAndPlaceholder};
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${ArrowPrevious} {
        color: ${(props) => props.theme.colors.outlineAndPlaceholder};
        width: 16px;
        height: 16px;
        &.rotate {
            transform: rotate(0deg);
        }
    }
`;

const Counter = styled.div`
    font-size: 16px;
    color: ${(props) => props.theme.colors.outlineAndPlaceholder};
`;

export const Pagination = (props: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { pagination } = useSelector((state: IState) => state.company);
    const pageCount = Math.ceil(pagination.total / pagination.perPage);

    const handlePageChange = (event: React.ChangeEvent<unknown> | null, value: number) => {
        getSearchCompaniesList(value, pagination.query).then((res) => {
            window.scrollTo(0, 0);
            props.setCompanies(res.data);
        });
    };

    useEffect(() => {
        return () => {
            dispatch(resetPagination());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ArrowHolder = ({ rotate }: { rotate?: boolean }) => {
        return (
            <ArrowWrapper>
                <ArrowPrevious className={classNames({ rotate })} />
            </ArrowWrapper>
        );
    };

    const endItem = Math.min(pagination.currentPage * pagination.perPage, pagination.total);

    return (
        <StyledStack
            sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}
        >
            <MUIPagination
                siblingCount={0}
                boundaryCount={1}
                count={pageCount}
                page={pagination.currentPage}
                shape="rounded"
                variant="text"
                onChange={handlePageChange}
                renderItem={(item: PaginationRenderItemParams) => (
                    <PaginationItem
                        {...item}
                        classes={{ root: 'pagination-item', selected: 'selected' }}
                        slots={{ previous: () => <ArrowHolder />, next: () => <ArrowHolder rotate={true} /> }}
                    />
                )}
            />
            <Counter>
                {endItem} {t('of')} {pagination.total}
            </Counter>
        </StyledStack>
    );
};
