import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { IconById } from './IconById';

import { IState } from '../../store';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import classNames from 'classnames';
import styled, { css } from 'styled-components';

const ContainerHolder = styled.div`
    z-index: -1;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    padding: 16px 0;
    ${(props) => css`
        background: ${props.theme.colors.lightNeutral};
        border: 1px solid ${props.theme.colors.outlineLight};
        color: ${props.theme.colors.black};
        &.transparent {
            background: transparent;
            border: none;
            color: ${props.theme.colors.white};
            ${AllCategories} {
                color: ${props.theme.colors.white};
            }
        }
    `}
`;

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    font-size: 15px;
    z-index: 1;
    overflow: auto;
    position: relative;
    padding: 0 16px;
    ${(props) => css`
        ${props.theme.screen.mobile &&
        css`
            display: grid;
            grid-template-columns: 1fr 1fr;
            ${CategoryName} {
                width: 96px;
            }
        `}
    `}
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
`;

const CategoryName = styled.div`
    width: 158px;
`;

const Arrow = styled(KeyboardArrowDownIcon)`
    margin-left: 4px;
    font-size: 16px !important;
`;

const AllCategories = styled.div`
    ${(props) => css`
        color: ${props.theme.colors.brandRed};
    `}
`;

export const PopularCategoriesBar = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const { screenViewPort } = useSelector((state: IState) => state.global);
    const { categoriesList } = useSelector((state: IState) => state.categories);

    const isLandingPage = location.pathname === '/';

    const navigateToBusinessList = (categoryId: number) => {
        navigate(`imoniu-sarasas/${categoryId}`);
    };

    const calculateSlice = () => {
        return screenViewPort !== 'tablet' ? 5 : 3;
    };

    return (
        <ContainerHolder
            className={classNames({
                transparent: location.pathname === '/',
            })}
        >
            <Container>
                {categoriesList.slice(0, calculateSlice()).map((category) => (
                    <Wrapper key={category.id} onClick={() => navigateToBusinessList(category.id)}>
                        {screenViewPort === 'desktop' ? (
                            <IconById
                                id={category.id}
                                customStyles={{
                                    color: isLandingPage ? '#FFF' : '#191D21',
                                    width: '20px',
                                    height: '18px',
                                }}
                            />
                        ) : (
                            <IconById
                                id={category.id}
                                customStyles={{
                                    color: isLandingPage ? '#FFF' : '#191D21',
                                    width: '20px',
                                    height: '20px',
                                }}
                            />
                        )}
                        <CategoryName>{category.name}</CategoryName>
                        <Arrow />
                    </Wrapper>
                ))}
                <Wrapper>
                    <AllCategories onClick={() => navigate('imoniu-katalogas')}>{t('more')}</AllCategories>
                    <Arrow />
                </Wrapper>
            </Container>
        </ContainerHolder>
    );
};
