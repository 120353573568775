import styled, { css } from 'styled-components';
import { ReactComponent as bubble } from '../../../assets/images/InnerCompanyPage/bubble.svg';
import { ReactComponent as icon } from '../../../assets/images/InnerCompanyPage/big_card_icon.svg';

const Card = styled.div`
    ${(props) => css`
        border: 1px solid ${props.theme.colors.outlineLight};
        border-radius: 10px;
        flex: 1;
    `}
`;
const HeaderContainer = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        background-color: ${props.theme.colors.greyBg};
        border-radius: 10px 10px 0 0;
        padding: 16px;
    `}
`;
const InfoContainer = styled.div`
    padding: 16px 16px 6px;
`;
const Icon = styled(icon)`
    ${(props) => css`
        margin-right: 12px;
        ${!props.theme.screen.desktop &&
        css`
            height: 18px;
            width: 18px;
            margin-right: 8px;
        `}
    `}
`;
const Text = styled.p`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        margin: 0 4px 0 0;
        color: ${props.theme.colors.darkNeutral};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 17px;
            line-height: 20px;
        `}
    `}
`;
const Bubble = styled(bubble)`
    margin-bottom: 5px;
`;

interface Props {
    titleInfo: string;
    children: React.ReactNode;
}

export const BusinessGreyLogoCard = (props: Props) => {
    return (
        <Card>
            <HeaderContainer>
                <Icon />
                <Text>{props.titleInfo}</Text>
                <Bubble />
            </HeaderContainer>
            <InfoContainer>{props.children}</InfoContainer>
        </Card>
    );
};
