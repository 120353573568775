import styled, { css } from 'styled-components';
import { CompanyData } from '../../../types/api';
import lext from '../../../assets/images/BusinessListCard/lext.png';
import { Stars } from '../../Stars/Stars';
import { useTranslation } from 'react-i18next';
import arrow from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../../store';
import { setFeedbackModalOpen, setLoginModalOpen } from '../../../store/global.slice';

const Container = styled.div`
    ${(props) => css`
        display: flex;
        margin-top: 28px;
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
        `}
    `}
`;
const Logo = styled.img`
    ${(props) => css`
        max-height: 100px;
        max-width: 100px;
        margin-right: 40px;
        object-fit: contain;
        ${!props.theme.screen.desktop &&
        css`
            max-height: 74px;
            max-width: 74px;
            margin-right: 16px;
        `}
        ${props.theme.screen.mobile &&
        css`
            margin-bottom: 8px;
        `}
    `}
`;
const Info = styled.div``;
const Name = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 32px;
        font-weight: 700;
        line-height: 38px;
        margin-bottom: 32px;
        ${props.theme.screen.tablet &&
        css`
            margin-bottom: 16px;
        `}
        ${props.theme.screen.mobile &&
        css`
            margin-bottom: 8px;
        `}
    `}
`;
const StatusLext = styled.div`
    ${(props) => css`
        display: flex;
        height: 28px;
        margin-bottom: 32px;
        ${props.theme.screen.tablet &&
        css`
            order: 2;
            margin-left: 20px;
        `}
        ${props.theme.screen.mobile &&
        css`
            margin-bottom: 24px;
        `}
    `}
`;
const Status = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        font-family: 'Albert-Regular';
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        text-transform: uppercase;
        white-space: nowrap;
        letter-spacing: 0.1em;
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.darkNeutral};
        border-radius: 50px;
        padding: 8px 12px;
        margin-right: 16px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 10px;
            line-height: 12px;
        `}
    `}
`;
const LextLogo = styled.img``;
const StarsContainer = styled.div`
    ${(props) => css`
        display: flex;
        ${props.theme.screen.tablet &&
        css`
            flex-direction: column;
        `}
    `}
`;
const ReviewButton = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        font-family: 'Albert-Regular';
        cursor: pointer;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        color: ${props.theme.colors.brandRed};
        border: 1px solid ${props.theme.colors.brandRed};
        border-radius: 50px;
        padding: 12px 24px;
        margin-left: 16px;
        ${props.theme.screen.tablet &&
        css`
            margin: 28px 0 0;
        `}
    `}
`;
const ButtonText = styled.div``;
const Icon = styled(arrow)`
    height: 18px !important;
    width: 18px !important;
    margin-left: 8px;
`;

const Wrapper = styled.div`
    ${(props) =>
        css`
            ${props.theme.screen.tablet &&
            css`
                display: flex;
            `};
        `}
`;

type Props = {
    data: CompanyData;
};
export const CompanyMenuMain = (props: Props) => {
    const dispatch = useDispatch();
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const { me } = useSelector((state: IState) => state.user);
    const { t } = useTranslation();

    const handleModalOpen = () => {
        if (me) {
            dispatch(setFeedbackModalOpen(true));
            return;
        }
        dispatch(setLoginModalOpen(true));
    };

    return (
        <Container>
            {props.data.logo?.logoPath && <Logo src={props.data.logo?.logoPath} />}
            <Info>
                <Name>{props.data.name}</Name>
                <Wrapper>
                    {props.data.lextIntegration && (
                        <StatusLext>
                            {/* No information provided. */}
                            {/* <Status>{t('top_rated')}</Status> */}
                            {props.data.lextIntegration && <LextLogo src={lext} />}
                        </StatusLext>
                    )}
                    <StarsContainer>
                        <Stars
                            rating={props.data.rating}
                            feedbackCount={props.data.feedbackCount}
                            starClass="company-inner-page"
                        />
                        <ReviewButton onClick={() => handleModalOpen()}>
                            <ButtonText>{screenViewPort === 'mobile' ? t('write') : t('write_a_review')}</ButtonText>
                            <Icon />
                        </ReviewButton>
                    </StarsContainer>
                </Wrapper>
            </Info>
        </Container>
    );
};
