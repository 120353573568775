import { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { CompanyData } from '../../types/api';
import { getIndividualCompany } from '../../services/company.service';
import { setBreadcrumb, setFeedbackModalOpen } from '../../store/global.slice';
import styled, { css } from 'styled-components';
import { Information } from '../../components/CompanyInnerComponents/Information/Information';
import { AboutCompany } from '../../components/CompanyInnerComponents/AboutCompany/AboutCompany';
import { Finance } from '../../components/CompanyInnerComponents/Finance/Finance';
import { Employees } from '../../components/CompanyInnerComponents/Employees/Employees';
import { Courts } from '../../components/CompanyInnerComponents/Courts/Courts';
import { Procurement } from '../../components/CompanyInnerComponents/Procurement/Procurement';
import { Feedback } from '../../components/CompanyInnerComponents/Feedback/Feedback';
import { FeedbackModal } from '../../components/CompanyInnerComponents/Feedback/FeedbackModal';
import { RightSideSlidingCard } from '../../components/CompanyInnerComponents/RightSideSlidingCard/RightSideSlidingCard';
import { IState } from '../../store';
import { CompanyMenuCard } from '../../components/CompanyInnerComponents/CompanyMenu/CompanyMenuCard';
import { CompanyMenuMain } from '../../components/CompanyInnerComponents/CompanyMenu/CompanyMenuMain';
import { CompanyMenuButtons } from '../../components/CompanyInnerComponents/CompanyMenu/CompanyMenuButtons';

const InnerCompanyPageContainer = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        padding: 0 16px;
        background-color: ${props.theme.colors.greyBg};
    `}
`;
const MenuWrap = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 16px;
`;
const CompanyMenuWrap = styled.div`
    ${(props) => css`
        max-width: 1254px;
        width: 100%;
        border-bottom: 1px solid ${props.theme.colors.greyBg};
    `}
`;
const InnerCompanyPageWrap = styled.div`
    ${(props) => css`
        max-width: 1254px;
        width: 100%;
        display: grid;
        grid-template-columns: 70% calc(30% - 30px);
        gap: 30px;
        ${props.theme.screen.tablet &&
        css`
            grid-template-columns: 62% calc(38% - 30px);
        `}
        ${props.theme.screen.mobile &&
        css`
            grid-template-columns: 1fr;
        `}
    `}
`;

const SideBar = styled.div`
    ${(props) => css`
        margin-top: 32px;
        ${props.theme.screen.mobile &&
        css`
            order: 1;
        `}
    `}
`;
const InformationSide = styled.div`
    ${(props) => css`
        margin: 32px 0;
        display: flex;
        flex-direction: column;
        gap: 40px;
        ${props.theme.screen.tablet &&
        css`
            margin-top: 24;
            gap: 32px;
        `}
        ${props.theme.screen.mobile &&
        css`
            gap: 32px;
            order: 2;
            margin-top: 32;
        `}
    `}
`;
const Wrapper = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: 70% calc(30% - 30px);
        width: 100%;
        justify-content: space-between;
        ${props.theme.screen.mobile &&
        css`
            grid-template-columns: 1fr;
        `}
    `}
`;
const ReferenceWrapper = styled.div``;

export const InnerCompanyPage = () => {
    const [companyData, setCompanyData] = useState<CompanyData>();
    const { feedbackModalOpen } = useSelector((state: IState) => state.global);
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const references = useRef<{ [key: string]: HTMLDivElement | null }>({
        information: null,
        feedback: null,
        about: null,
        employees: null,
        finance: null,
    });

    const showEmployees =
        companyData &&
        (companyData.workerStatistics?.totalCount?.length > 0 || companyData.financialStats.sodraDebt.length > 0);

    const showFinances =
        companyData &&
        (companyData.financialStats?.grosssumRes?.length > 0 ||
            companyData.financialStats?.netProfit?.length > 0 ||
            companyData.financialStats?.longtermWorth?.length > 0 ||
            companyData.financialStats?.shorttermWorth?.length > 0 ||
            companyData.financialStats?.payables?.length > 0);

    const urlParams = useParams();
    const { state } = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (urlParams.companyId) {
            getIndividualCompany(parseInt(urlParams.companyId)).then((response: CompanyData) => {
                setCompanyData(response);
                if (state?.scrollTo) {
                    scrollToElement(state.scrollTo);
                }
                dispatch(
                    setBreadcrumb({
                        key: 'companyName',
                        value: response.name,
                    })
                );
            });
        }

        return () => {
            dispatch(setFeedbackModalOpen(false));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams.companyId]);

    const handleClick = (refName: string) => {
        const scrollTo = (reference: HTMLDivElement | null) => {
            if (reference) {
                const height = '100px';
                reference.style.scrollMargin = height;
                reference.scrollIntoView({ block: 'start' });
            }
        };
        if (references.current[refName]) {
            scrollTo(references.current[refName]);
        }
    };

    const menuList = () => {
        let list = [];
        list.push({ label: 'information', navClick: 'information' });
        list.push({ label: 'about_the_company', navClick: 'about' });
        if (showEmployees) {
            list.push({
                label: 'employees',
                navClick: 'employees',
            });
        }
        if (showFinances) {
            list.push({
                label: 'finance',
                navClick: 'finance',
            });
        }
        list.push({ label: 'feedback', navClick: 'feedback' });

        return list;
    };

    const scrollToElement = (elementName: string) => {
        const targetElement = document.getElementById(elementName);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            {companyData && (
                <>
                    {feedbackModalOpen && (
                        <FeedbackModal
                            companyName={companyData.name}
                            boughtInteractive={companyData.boughtInteractive}
                            companyId={companyData.id}
                            show={feedbackModalOpen}
                            onClose={() => dispatch(setFeedbackModalOpen(false))}
                        />
                    )}
                    <MenuWrap>
                        <CompanyMenuWrap>
                            <Wrapper>
                                <CompanyMenuMain data={companyData} />
                                <CompanyMenuCard data={companyData} />
                            </Wrapper>
                            <CompanyMenuButtons handleClick={handleClick} menuList={menuList()} />
                        </CompanyMenuWrap>
                    </MenuWrap>
                    <InnerCompanyPageContainer>
                        <InnerCompanyPageWrap>
                            <InformationSide>
                                <ReferenceWrapper ref={(el) => (references.current.information = el)}>
                                    <Information data={companyData} />
                                </ReferenceWrapper>
                                <ReferenceWrapper ref={(el) => (references.current.about = el)}>
                                    <AboutCompany data={companyData} />
                                </ReferenceWrapper>
                                {showFinances && (
                                    <ReferenceWrapper ref={(el) => (references.current.finance = el)}>
                                        <Finance data={companyData} />
                                    </ReferenceWrapper>
                                )}
                                {showEmployees && (
                                    <ReferenceWrapper ref={(el) => (references.current.employees = el)}>
                                        <Employees
                                            financialStats={companyData.financialStats}
                                            workerStats={companyData.workerStatistics}
                                        />
                                    </ReferenceWrapper>
                                )}
                                {/* Client asked to hide this part until further notice */}
                                {/* <Courts data={companyData} /> */}
                                <Procurement />
                                <ReferenceWrapper ref={(el) => (references.current.feedback = el)}>
                                    <Feedback
                                        logo={companyData.logo}
                                        companyId={companyData.id}
                                        companyName={companyData.name}
                                        separateRatings={companyData.seperateRating}
                                        rating={companyData.rating}
                                        feedbackCount={companyData.feedbackCount}
                                        boughtInteractive={companyData.boughtInteractive}
                                    />
                                </ReferenceWrapper>
                            </InformationSide>
                            {screenViewPort !== 'mobile' && (
                                <SideBar>
                                    <RightSideSlidingCard data={companyData} />
                                </SideBar>
                            )}
                        </InnerCompanyPageWrap>
                    </InnerCompanyPageContainer>
                </>
            )}
        </>
    );
};
