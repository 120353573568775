import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FinanceChart } from './components/FinanceChart';
import { SummaryCard } from './components/SummaryCard';

import { CompanyData } from '../../../types/api';
import { IState } from '../../../store';

import { ReactComponent as icon } from '../../../assets/images/InnerCompanyPage/green_chart.svg';
import { ReactComponent as icon2 } from '../../../assets/images/InnerCompanyPage/big_card_icon.svg';

import styled, { css } from 'styled-components';
import classNames from 'classnames';

import { BusinessMainCard } from '../BusinessCardWrap/BusinessMainCard';

import { pluralStringFormater } from '../../Formatters/PluralStringFormater';
import { BusinessMainCardFinance } from './BuisinessMainCardFinance';

const Wrapper = styled.div`
    ${(props) => css`
        border-top: 1px solid ${props.theme.colors.outlineLight};
    `}
`;

const Container = styled.div`
    ${(props) => css`
        margin: 32px 23px 32px;
        ${!props.theme.screen.desktop &&
        css`
            margin: 32px 16px 24px;
        `}
    `}
`;

const Heading = styled.div`
    font-weight: 700;
    font-size: 32px;
    color: ${(props) => props.theme.colors.black};
    line-height: 120%;
    flex: 1;
    ${(props) => css`
        color: ${props.theme.colors.black};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 20px;
        `}
    `}
`;

const Statistics = styled.div`
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: space-between;
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;

const Statistic = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;

const Label = styled.div`
    color: ${(props) => props.theme.colors.brandGreen};
    font-weight: 700;
    font-size: 12px;
    line-height: 120%;
    ${(props) => css`
        color: ${props.theme.colors.brandGreen};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 11px;
        `}
    `}
`;

const StatisticValue = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${(props) => props.theme.colors.secondaryBlack};
    font-size: 16px;
    line-height: 120%;
`;

const StatisticIcon = styled(icon)`
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            width: 13px;
            height: 16px;
        `}
    `}
`;

const InfoCardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: 30px 0;
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            grid-template-columns: repeat(2, 1fr);
            gap: 24px;
        `}
    `}
`;

const Separator = styled.div`
    width: calc(100% + 48px);
    margin-left: -24px;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 30px;
    ${(props) => css`
        background: ${props.theme.colors.outlineLight};
        ${!props.theme.screen.desktop &&
        css`
            width: calc(100% + 32px);
            margin-left: -16px;
        `}
    `}
`;

const ChartsContainer = styled.div`
    display: flex;
    gap: 30px;
    width: 100%;
    height: 100%;
    &.column {
        margin-top: 30px;
    }
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            &.column {
                margin-top: 24px;
                flex-direction: column;
                gap: 24px;
            }
        `}
    `}
`;

const ChartHolder = styled.div`
    ${(props) => css`
        position: relative;
        height: 100%;
        width: calc(67% + 15px);
        border: 1px solid ${(props) => props.theme.colors.outlineLight};
        border-radius: 10px;
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;

const ChartHeader = styled.div`
    display: flex;
    padding: 0 12px;
    background: ${(props) => props.theme.colors.greyBg};
    align-items: center;
    border-radius: 10px 10px 0 0;
    height: 62px;
`;

const ChartTitle = styled.div`
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    ${(props) => css`
        color: ${props.theme.colors.darkNeutral};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 17px;
        `}
    `}
`;

const ChartIcon = styled(icon2)`
    ${(props) => css`
        margin-right: 12px;
        ${!props.theme.screen.desktop &&
        css`
            height: 18px;
            width: 18px;
            margin-right: 8px;
        `}
    `}
`;
const ProfitContainer = styled.div`
    width: 33%;
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;

interface Props {
    data: CompanyData;
}

export const Finance = (props: Props) => {
    const { t } = useTranslation();
    const { screenViewPort } = useSelector((state: IState) => state.global);

    const { financialStats } = props.data;

    const cardData = {
        title: t('finance'),
        firstChart: t('employees'),
        firstNumber: `${
            props.data.workerStatistics.totalCount.length > 0
                ? props.data.workerStatistics.totalCount[props.data.workerStatistics.totalCount.length - 1].count
                : 0
        } ${
            props.data.workerStatistics.totalCount.length > 0 &&
            pluralStringFormater(
                props.data.workerStatistics.totalCount[props.data.workerStatistics.totalCount.length - 1].count,
                t(`workers.zero`),
                t(`workers.singular`),
                t(`workers.plural`)
            )
        }`,
        firstNumberDate: `(${
            props.data.workerStatistics.totalCount.length > 0
                ? props.data.workerStatistics.totalCount[props.data.workerStatistics.totalCount.length - 1].date
                : null
        })`,
        secondChart: t('vaccination_level'),
        secondNumber: props.data.vaccinationLevel > 0 ? `${props.data.vaccinationLevel}` : null,
    };

    return (
        <BusinessMainCardFinance data={cardData}>
            <Wrapper>
                <Container>
                    <ChartsContainer>
                        {financialStats?.grosssumRes?.length > 0 && (
                            <ChartHolder
                                className={classNames({
                                    'full-width': screenViewPort !== 'desktop',
                                })}
                            >
                                <ChartHeader>
                                    <ChartIcon />
                                    <ChartTitle>{t('income')}</ChartTitle>
                                </ChartHeader>
                                <FinanceChart data={financialStats.sales} />
                            </ChartHolder>
                        )}
                        {financialStats?.netProfit?.length > 0 && screenViewPort === 'desktop' && (
                            <ProfitContainer>
                                <SummaryCard data={financialStats.netProfit} title={t('profit')} />
                            </ProfitContainer>
                        )}
                    </ChartsContainer>
                    <ChartsContainer className="column">
                        {financialStats?.netProfit?.length > 0 && screenViewPort !== 'desktop' && (
                            <SummaryCard data={financialStats.netProfit} title={t('profit')} />
                        )}
                        {financialStats?.longtermWorth?.length > 0 && (
                            <SummaryCard data={financialStats.longtermWorth} title={t('longterm_worth')} />
                        )}
                        {financialStats?.shorttermWorth?.length > 0 && (
                            <SummaryCard data={financialStats.shorttermWorth} title={t('shortterm_worth')} />
                        )}
                        {financialStats?.payables?.length > 0 && (
                            <SummaryCard data={financialStats.payables} title={t('payables')} />
                        )}
                    </ChartsContainer>
                </Container>
            </Wrapper>
        </BusinessMainCardFinance>
    );
};
