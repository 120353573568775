import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import styled from 'styled-components';

const PreviewImage = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: none;
`;

interface Props {
    imagePaths: string[];
    onClose: () => void;
}

export const GalleryToModal = (props: Props) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % props.imagePaths.length);
    };

    return (
        <Modal open={props.imagePaths.length > 0} onClose={props.onClose}>
            <Box
                style={{ outline: 'none' }}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: 'none',
                }}
            >
                {props.imagePaths.length > 0 && (
                    <PreviewImage alt="view" src={props.imagePaths[currentIndex]} onClick={handleNext} />
                )}
            </Box>
        </Modal>
    );
};
