import { Logo, ReactionsCountItem, Review, ReviewCompanyResponse } from '../../../../types/api';
import styled, { css } from 'styled-components';
import { Stars } from '../../../Stars/Stars';
import { ReactComponent as Agree } from '../../../../assets/images/CardImages/agree.svg';
import { ReactComponent as Disagree } from '../../../../assets/images/CardImages/disagree.svg';
import { ReactComponent as Useful } from '../../../../assets/images/CardImages/useful.svg';
import { postCompanyFeedbackReaction, respondToUserFeedback } from '../../../../services/reviews.services';
import { ImageView } from './ImageView';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../../../store';
import { setLoading, setLoginModalOpen } from '../../../../store/global.slice';
import { useTranslation } from 'react-i18next';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import classNames from 'classnames';
import { DeleteModal } from './DeleteFeedbackModal';
import { FeedbackModal } from '../FeedbackModal';
import { TextareaAutosize } from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';

const Container = styled.div`
    font-family: 'Albert-Regular';
    display: flex;
    flex-direction: column;
    ${(props) => css`
        border: 1px solid ${props.theme.colors.outlineLight};
        padding: 24px;
        border-radius: 10px;
        margin-top: 30px;
        ${!props.theme.screen.desktop &&
        css`
            padding: 16px;
            width: calc(100% - 32px);
        `}
    `}
`;

const Heading = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

const Picture = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            width: 40px;
            height: 40px;
        `}
    `}
`;

const DetailsWrapper = styled.div``;

const FlexBox = styled.div`
    display: flex;
`;

const Name = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 700;
        color: ${props.theme.colors.secondaryBlack};
    `}
`;

const RatingWrapper = styled.div`
    display: flex;
`;

const CreatedDate = styled.div`
    ${(props) => css`
        color: ${props.theme.colors.secondaryBlack};
        letter-spacing: 0.1em;
        font-size: 12px;
        font-weight: 700;
    `}
`;

const FeedbackText = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin: 24px 0;
    ${(props) => css`
        color: ${props.theme.colors.secondaryBlack};
        ${!props.theme.screen.desktop &&
        css`
            margin: 24px 0 9px 0;
            font-size: 14px;
            line-height: 20px;
        `}
    `}
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    ${(props) => css`
        ${props.theme.screen.desktop &&
        css`
            gap: 40px;
        `}
        ${props.theme.screen.tablet &&
        css`
            order: 0;
            width: 70%;
        `}
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
            order: 1;
            margin-top: 24px;
        `}
    `}
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
`;

const ReactionWrapper = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;
`;

const IconSize = css`
    height: 18px;
    width: 18px;
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            height: 16px;
            width: 16px;
        `}
    `}
`;

const AgreeIcon = styled(Agree)`
    ${IconSize}
`;

const DisagreeIcon = styled(Disagree)`
    ${IconSize}
`;

const UsefulIcon = styled(Useful)`
    ${IconSize}
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    flex-wrap: wrap;
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            height: 100%;
        `}
    `}
`;

const ImageWrapper = styled.div`
    border-radius: 10px;
    max-width: 200px;
    height: 130px;
    ${(props) => css`
        border: 1px solid ${props.theme.colors.outlineLight};
    `}
`;

const Image = styled.img`
    width: 140px;
    height: 100%;
    object-fit: contain;
`;

const ReactionCount = styled.div`
    font-weight: 400;
    font-size: 12px;
    ${(props) => css`
        color: ${props.theme.colors.outlineAndPlaceholder};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 9px;
        `}
    `}
    &.active {
        font-weight: 700;
    }
`;

const ActionIconStyles = css`
    font-size: 18px !important;
    color: ${(props) => props.theme.colors.secondaryBlack};
`;

const EditIcon = styled(EditOutlinedIcon)`
    ${ActionIconStyles}
`;

const DeleteIcon = styled(DeleteOutlineOutlinedIcon)`
    ${ActionIconStyles}
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    ${(props) => css`
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
        `}
    `}
`;

const ActionContainer = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        gap: 40px;
        font-size: 12px;
        color: ${(props) => props.theme.colors.outlineAndPlaceholder};
        .action {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 4px;
        }
        ${!props.theme.screen.mobile &&
        css`
            margin-bottom: 24px;
        `}
        ${!props.theme.screen.desktop &&
        css`
            order: 0;
            gap: 24px;
        `}
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
            justify-content: flex-end;
        `}
    `}
`;

const Divider = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.outlineLight};
        height: 1px;
        width: calc(100% + 48px);
        margin: 24px -24px;
        ${!props.theme.screen.desktop &&
        css`
            width: calc(100% + 32px);
            margin: 24px -16px;
        `}
    `}
`;

const ReplyContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const ReplyTextArea = styled(TextareaAutosize)`
    ${(props) => css`
        width: calc(100% - 32px);
        border: 1px solid ${props.theme.colors.outlineLight};
        border-radius: 4px;
        color: ${props.theme.colors.black};
        font-size: 16px;
        padding: 6px 16px;
        &:focus {
            outline: none;
        }
    `}
`;
const ReplyButton = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.brandRed};
        border-radius: 100px;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        padding: 12px 59px;
        margin-top: 8px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }
        &:hover {
            font-weight: 700;
        }
    `}
`;

const CompanyResponseWrapper = styled.div`
    margin-top: 24px;
    margin-left: 64px;
`;

const PlaceholderCompanyIcon = styled(ApartmentIcon)`
    font-size: 32px !important;
`;

const PlaceholderWrapper = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: ${props.theme.colors.outlineLight};
    `}
`;

interface Props {
    feedback: Review;
    companyId: number;
    companyName: string;
    logo?: Logo | null;
    boughtInteractive: boolean;
}

export const UserFeedback = (props: Props) => {
    const { screenViewPort } = useSelector((state: IState) => state.global);

    const [companyResponse, setCompanyResponse] = useState<ReviewCompanyResponse>(props.feedback.companyResponse);
    const [imageView, setImageView] = useState<string | null>(null);
    const [reactionsCount, setReactionsCount] = useState<ReactionsCountItem[]>([
        { reaction: 0, count: 0 },
        { reaction: 1, count: 0 },
        { reaction: 2, count: 0 },
    ]);
    const [currentUserReaction, setCurrentUserReaction] = useState<number | null>(null);
    const [localLoading, setLocalLoading] = useState<boolean>(false);
    const [replyText, setReplyText] = useState<string>('');

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { me } = useSelector((state: IState) => state.user);

    const [name, lastName] = props.feedback.user?.name?.split(' ') || [''];
    const [showModal, setShowModal] = useState<{ [key: string]: boolean }>({
        delete: false,
        edit: false,
    });

    useEffect(() => {
        setReactionsCount((prev) => [
            ...prev.map((reaction) => {
                const givenReaction = props.feedback.reactionsCount.find((item) => item.reaction === reaction.reaction);
                return givenReaction ? givenReaction : reaction;
            }),
        ]);
        setCurrentUserReaction(props.feedback.currentUserReaction);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const averageRating = () => {
        const { ratingExpectation, ratingQpRatio, ratingQuality, ratingSpeed } = props.feedback;
        return (ratingExpectation + ratingQpRatio + ratingQuality + ratingSpeed) / 4;
    };

    const formatDate = (feedbackDate: string) => {
        if (!feedbackDate) {
            return;
        }
        const dateString = feedbackDate;
        const date = new Date(dateString);
        const formattedDate = date.toISOString().split('T')[0].replace(/-/g, '/');
        return formattedDate;
    };

    const handleReaction = (reactionType: number) => {
        if (!me) {
            dispatch(setLoginModalOpen(true));
            return;
        }
        setLocalLoading(true);
        postCompanyFeedbackReaction(props.companyId, props.feedback.id, reactionType, me.id)
            .then((res) => {
                const reactionType = res.reaction;
                if (reactionType !== currentUserReaction) {
                    setReactionsCount((prev) => [
                        ...prev.map((reaction) => {
                            if (reaction.reaction === reactionType) {
                                return {
                                    ...reaction,
                                    count: reaction.count + 1,
                                };
                            }
                            return reaction;
                        }),
                    ]);
                }
                if (currentUserReaction !== null) {
                    setReactionsCount((prev) =>
                        prev.map((reaction) => {
                            if (reaction.reaction === currentUserReaction) {
                                return {
                                    ...reaction,
                                    count: reaction.count - 1,
                                };
                            }
                            return reaction;
                        })
                    );
                }
                setCurrentUserReaction(reactionType);
            })
            .finally(() => setLocalLoading(false));
    };

    const getReactionCount = (reactionType: number) => {
        const reaction = reactionsCount.find((reaction) => reaction.reaction === reactionType);
        return reaction?.count || 0;
    };

    const handleReply = () => {
        dispatch(setLoading(true));
        respondToUserFeedback(props.companyId, props.feedback.id, {
            feedback: replyText,
            userId: me.id,
            companyId: props.companyId,
        })
            .then((resp) => {
                setReplyText('');
                setCompanyResponse(resp);
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    };

    const handleEditModal = () => {
        setShowModal({ edit: true });
    };

    return (
        <Container>
            <Heading>
                <UserInfo>
                    {props.feedback.user && <Picture src={props.feedback.user.profilePicture} />}
                    <DetailsWrapper>
                        <FlexBox>
                            <Name>
                                {name} {lastName && `${lastName[0]}.`}
                            </Name>
                        </FlexBox>
                    </DetailsWrapper>
                </UserInfo>
            </Heading>
            <RatingWrapper>
                <Stars rating={averageRating()} starClass="smaller-stars" feedbackCount={0} noNumber={true} />
                <CreatedDate>{formatDate(props.feedback.createdAt)}</CreatedDate>
            </RatingWrapper>
            <FeedbackText>{props.feedback.feedback}</FeedbackText>
            <Wrapper>
                <IconContainer
                    className={classNames({
                        disabled: localLoading,
                    })}
                >
                    <ReactionWrapper onClick={() => handleReaction(1)}>
                        <AgreeIcon />
                        <ReactionCount
                            className={classNames({
                                active: currentUserReaction === 1,
                            })}
                        >
                            {t('agree')} ({getReactionCount(1)})
                        </ReactionCount>
                    </ReactionWrapper>
                    <ReactionWrapper onClick={() => handleReaction(0)}>
                        <DisagreeIcon />
                        <ReactionCount
                            className={classNames({
                                active: currentUserReaction === 0,
                            })}
                        >
                            {t('disagree')} ({getReactionCount(0)})
                        </ReactionCount>
                    </ReactionWrapper>
                    <ReactionWrapper onClick={() => handleReaction(2)}>
                        <UsefulIcon />
                        <ReactionCount
                            className={classNames({
                                active: currentUserReaction === 2,
                            })}
                        >
                            {t('helpful')} ({getReactionCount(2)})
                        </ReactionCount>
                    </ReactionWrapper>
                </IconContainer>
                {me?.id === props.feedback.user?.id && (
                    <ActionContainer>
                        <div className="action" onClick={() => handleEditModal()}>
                            <EditIcon />
                            {screenViewPort === 'desktop' && t('edit')}
                        </div>
                        <div className="action" onClick={() => setShowModal({ delete: true })}>
                            <DeleteIcon />
                            {screenViewPort === 'desktop' && t('delete')}
                        </div>
                    </ActionContainer>
                )}
            </Wrapper>
            {props.feedback.attachments?.length > 0 && (
                <ImageContainer>
                    {props.feedback.attachments.map((image) => (
                        <ImageWrapper key={image.id}>
                            <Image src={image.contentPath} alt="" onClick={() => setImageView(image.contentPath)} />
                        </ImageWrapper>
                    ))}
                </ImageContainer>
            )}
            {companyResponse && (
                <CompanyResponseWrapper>
                    <Heading>
                        <UserInfo>
                            {props.logo?.logoPath ? (
                                <Picture src={props.logo.logoPath} />
                            ) : (
                                <PlaceholderWrapper>
                                    <PlaceholderCompanyIcon />
                                </PlaceholderWrapper>
                            )}
                            <DetailsWrapper>
                                <FlexBox>
                                    <Name>{props.companyName}</Name>
                                </FlexBox>
                            </DetailsWrapper>
                        </UserInfo>
                    </Heading>
                    <CreatedDate>{formatDate(companyResponse.createdAt)}</CreatedDate>
                    <FeedbackText>{companyResponse.feedback}</FeedbackText>
                </CompanyResponseWrapper>
            )}
            {me?.companyId === props.companyId && !companyResponse && (
                <>
                    <Divider />
                    <ReplyTextArea
                        value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                        placeholder={`${t('leave_your_reply')}`}
                        minRows={4}
                    />
                    <ReplyContainer>
                        <ReplyButton
                            className={classNames({
                                disabled: !replyText,
                            })}
                            onClick={() => handleReply()}
                        >
                            {t('reply')}
                        </ReplyButton>
                    </ReplyContainer>
                </>
            )}
            <ImageView url={imageView} onClose={() => setImageView(null)} />
            {showModal.delete && (
                <DeleteModal
                    companyId={props.companyId}
                    feedback={props.feedback}
                    show={showModal.delete}
                    onClose={setShowModal}
                />
            )}
            {showModal.edit && (
                <FeedbackModal
                    companyId={props.companyId}
                    feedback={props.feedback}
                    companyName={props.companyName}
                    boughtInteractive={props.boughtInteractive}
                    isEdit={true}
                    show={showModal.edit}
                    onClose={() => setShowModal({ edit: false })}
                />
            )}
        </Container>
    );
};
