import { useTranslation } from 'react-i18next';

import { InfoCard } from './components/InfoCard';
import { EmployeeAmountChart } from './components/EmployeeAmountChart';

import { FinancialStats, IWorkerStatistics } from '../../../types/api';

import { ReactComponent as icon } from '../../../assets/images/InnerCompanyPage/green_chart.svg';
import { ReactComponent as icon2 } from '../../../assets/images/InnerCompanyPage/big_card_icon.svg';

import styled, { css } from 'styled-components';
import { SodraDebtChart } from './components/SodraDebtChart';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';

const Container = styled.div`
    ${(props) => css`
        border: 1px solid ${props.theme.colors.outlineLight};
        border-radius: 10px;
        background-color: ${props.theme.colors.white};
        padding: 35px 24px;
        ${!props.theme.screen.desktop &&
        css`
            padding: 16px;
        `}
    `}
`;

const HeaderWrapper = styled.div`
    font-family: 'Albert-Regular';
    display: flex;
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            flex-direction: column;
            gap: 16px;
            margin-bottom: 24px;
        `}
    `}
`;

const Heading = styled.div`
    font-weight: 700;
    font-size: 32px;
    color: ${(props) => props.theme.colors.black};
    line-height: 120%;
    flex: 1;
    ${(props) => css`
        color: ${props.theme.colors.black};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 20px;
        `}
    `}
`;

const Statistics = styled.div`
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: space-between;
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;

const Statistic = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;

const Label = styled.div`
    color: ${(props) => props.theme.colors.brandGreen};
    font-weight: 700;
    font-size: 12px;
    line-height: 120%;
    ${(props) => css`
        color: ${props.theme.colors.brandGreen};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 11px;
        `}
    `}
`;

const StatisticValue = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${(props) => props.theme.colors.secondaryBlack};
    font-size: 16px;
    line-height: 120%;
`;

const StatisticIcon = styled(icon)`
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            width: 13px;
            height: 16px;
        `}
    `}
`;

const InfoCardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: 30px 0;
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            grid-template-columns: repeat(2, 1fr);
            gap: 24px;
        `}
    `}
`;

const Separator = styled.div`
    width: calc(100% + 48px);
    margin-left: -24px;
    height: 1px;
    ${(props) => css`
        background: ${props.theme.colors.outlineLight};
        ${!props.theme.screen.desktop &&
        css`
            width: calc(100% + 32px);
            margin-left: -16px;
        `}
    `}
`;

const ChartsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    width: 100%;
    margin-top: 30px;
`;

const ChartHolder = styled.div`
    position: relative;
    height: 100%;
    width: 50%;
    border: 1px solid ${(props) => props.theme.colors.outlineLight};
    border-radius: 10px 10px 0 0;
    &.full-width {
        width: 100%;
    }
`;

const ChartHeader = styled.div`
    display: flex;
    padding: 0 12px;
    background: ${(props) => props.theme.colors.greyBg};
    align-items: center;
    border-radius: 10px 10px 0 0;
    height: 62px;
`;

const ChartTitle = styled.div`
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    ${(props) => css`
        color: ${props.theme.colors.darkNeutral};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 17px;
        `}
    `}
`;

const ChartIcon = styled(icon2)`
    ${(props) => css`
        margin-right: 12px;
        ${!props.theme.screen.desktop &&
        css`
            height: 18px;
            width: 18px;
            margin-right: 8px;
        `}
    `}
`;

const ChartDate = styled.span`
    ${(props) => css`
        color: #737373;
        font-family: 'Albert-Regular';
        font-size: 11px;
        font-weight: 400;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 8px;
        `}
    `}
`;

const ChartBottom = styled.div`
    ${(props) =>
        css`
            color: ${props.theme.colors.secondaryBlack};
            margin: 0 6px 0 0;
            &:first-child {
                margin-right: 20px;
                width: 70px;
            }
        `}
`;

interface Props {
    financialStats: FinancialStats;
    workerStats: IWorkerStatistics;
}

export const Employees = (props: Props) => {
    const { t } = useTranslation();
    const { screenViewPort } = useSelector((state: IState) => state.global);

    const { salaryMedian, workerSalaryMedian, womenAverageSalary, menAverageSalary, sodraDebt } = props.financialStats;

    const latestSalaryMedian = salaryMedian[salaryMedian.length - 1]?.amount;
    const latestSalaryMedianDate = salaryMedian[salaryMedian.length - 1]?.date;

    const latestWorkerSalaryMedian = workerSalaryMedian[workerSalaryMedian.length - 1]?.amount;
    const latestWorkerSalaryMedianDate = workerSalaryMedian[workerSalaryMedian.length - 1]?.date;

    const latestWomenAvgSalary = womenAverageSalary[womenAverageSalary.length - 1]?.amount;
    const latestWomenAvgSalaryDate = womenAverageSalary[womenAverageSalary.length - 1]?.date;

    const latestMenAvgSalary = menAverageSalary[menAverageSalary.length - 1]?.amount;
    const latestMenAvgSalaryDate = menAverageSalary[menAverageSalary.length - 1]?.date;

    const latestDebt = sodraDebt[sodraDebt.length - 1]?.amount;
    const latestDebtDate = sodraDebt[sodraDebt.length - 1]?.date;

    const latestWorkerStatstDate = props.workerStats.totalCount[props.workerStats.totalCount.length - 1]?.date;

    return (
        <Container>
            <HeaderWrapper>
                <Heading>{t('employees')}</Heading>
                <Statistics>
                    <Statistic>
                        <Label>{t('avg_salary')}</Label>
                        <StatisticValue>
                            <StatisticIcon />
                            {latestSalaryMedian ? `${latestSalaryMedian} €` : '-'}
                            {latestSalaryMedianDate && <ChartDate>{`(${latestSalaryMedianDate})`}</ChartDate>}
                        </StatisticValue>
                    </Statistic>
                    <Statistic>
                        <Label>{t('debt')}</Label>
                        <StatisticValue>
                            <StatisticIcon />
                            {latestDebt ? latestDebt : 0} €
                            {latestDebtDate && <ChartDate>{`(${latestDebtDate})`}</ChartDate>}
                        </StatisticValue>
                    </Statistic>
                </Statistics>
            </HeaderWrapper>
            {screenViewPort !== 'desktop' && <Separator />}
            <InfoCardsContainer>
                <InfoCard
                    title={t('average_salary')}
                    value={latestWorkerSalaryMedian ? `${latestWorkerSalaryMedian} €` : '-'}
                    date={latestWorkerSalaryMedianDate}
                />
                <InfoCard
                    title={t('w_average_salary')}
                    value={latestWomenAvgSalary ? `${latestWomenAvgSalary} €` : '-'}
                    date={latestWomenAvgSalaryDate}
                />
                <InfoCard
                    title={t('m_average_salary')}
                    value={latestMenAvgSalary ? `${latestMenAvgSalary} €` : '-'}
                    date={latestMenAvgSalaryDate}
                />
            </InfoCardsContainer>
            <Separator />
            <ChartsContainer>
                <ChartHolder className="full-width">
                    <ChartHeader>
                        <ChartIcon />
                        <ChartTitle>
                            {t('employee_amount')} <ChartDate>{`(${latestWorkerStatstDate})`}</ChartDate>
                        </ChartTitle>
                    </ChartHeader>
                    <EmployeeAmountChart
                        employeeStatistics={{
                            ...props.workerStats,
                            totalCount: props.workerStats.totalCount.slice(
                                props.workerStats.totalCount.length - 20,
                                props.workerStats.totalCount.length
                            ),
                        }}
                    />
                </ChartHolder>
                <ChartHolder
                    className={classNames({
                        'full-width': screenViewPort !== 'desktop',
                    })}
                >
                    <ChartHeader>
                        <ChartIcon />
                        <ChartTitle>{t('sodra_debt')}</ChartTitle>
                    </ChartHeader>
                    <SodraDebtChart data={props.financialStats.sodraDebt.slice(-20)} />
                </ChartHolder>
            </ChartsContainer>
        </Container>
    );
};
