import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import arrowForwardIcon from '@mui/icons-material/ArrowForward';
import classNames from 'classnames';

const Wrapper = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: ${props.theme.colors.secondaryBlack};
    `}
`;
const Text = styled.div`
    margin-top: 24px;
`;
const FullReviewButton = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        margin-top: 24px;
        color: ${props.theme.colors.brandRed};
    `}
`;
const ArrowForwardIcon = styled(arrowForwardIcon)`
    width: 18px !important;
    margin-left: 8px;
    &.show {
        rotate: 270deg;
    }
`;

type Props = {
    feedback: string | null;
};

export const RecentReviewText = (props: Props) => {
    const { t } = useTranslation();
    const [showFullReview, setShowFullReview] = useState(false);

    const handleClick = () => {
        setShowFullReview((prev) => !prev);
    };

    return (
        <>
            {props.feedback && (
                <Wrapper>
                    <Text>{!showFullReview ? props.feedback.substring(0, 150) : props.feedback}</Text>
                    {props.feedback.length > 150 && (
                        <FullReviewButton onClick={handleClick}>
                            {showFullReview ? t('hide_full_review') : t('show_full_review')}
                            <ArrowForwardIcon className={classNames({ show: showFullReview })} />
                        </FullReviewButton>
                    )}
                </Wrapper>
            )}
        </>
    );
};
