import { connect } from 'react-redux';
import { IState } from '../../../store';
import { Topic } from '../../../types/api';

const PureTopicBreadcrumb = (topic: Topic) => {
    // Backedn returns us an ALL CAP string
    const capitalizeWords = (str: string) => {
        const string = str.toLowerCase();
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return <span>{capitalizeWords(topic?.name)}</span>;
};

const mapStateToProps = (state: IState, props: any) => {
    const { topicId } = props.match.params;
    const topic = state.categories.categoriesList
        .flatMap((category) => category.topics)
        .find((topic) => topic.id === parseInt(topicId));

    return (
        topic || {
            name: '...',
        }
    );
};

export default connect(mapStateToProps)(PureTopicBreadcrumb);
