import { FinancialStatsItem } from '../../../../types/api';

import { BusinessGreyLogoCard } from '../../BusinessGreyLogoCard/BusinessGreyLogoCard';

import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const DataTextWrapper = styled.div`
    font-family: 'Albert-Regular';
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    &:last-child {
        font-weight: 700;
        padding: 8px 0;
    }
`;

const Text = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: ${(props) => props.theme.colors.black};
`;

interface Props {
    data: FinancialStatsItem[];
    title: string;
}

export const SummaryCard = (props: Props) => {
    const { data, title } = props;

    return (
        <Container>
            <BusinessGreyLogoCard titleInfo={title}>
                {data.map((item, index) => (
                    <DataTextWrapper key={index}>
                        <Text>{item.date}</Text>
                        <Text>{item.amount} €</Text>
                    </DataTextWrapper>
                ))}
            </BusinessGreyLogoCard>
        </Container>
    );
};
