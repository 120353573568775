import styled, { css } from 'styled-components';
import { ReactComponent as chartIcon } from '../../../assets/images/InnerCompanyPage/green_chart.svg';

const InformationContainer = styled.div`
    ${(props) => css`
        border: 1px solid ${props.theme.colors.outlineLight};
        border-radius: 10px;
        background-color: ${props.theme.colors.white};
    `}
`;
const InformationTitle = styled.div`
    ${(props) => css`
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 32px;
        padding: 35px 24px 0 24px;
        ${!props.theme.screen.desktop &&
        css`
            flex-direction: column;
            margin-bottom: 8px;
            padding: 16px;
        `}
    `}
`;
const Title = styled.h2`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 32px;
        font-weight: 700;
        line-height: 38px;
        margin: 0;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 20px;
            line-height: 24px;
        `}
    `}
`;
const Charts = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: space-between;
        ${!props.theme.screen.desktop &&
        css`
            justify-content: start;
            margin-top: 16px;
        `}
    `}
`;
const Chart = styled.div`
    ${(props) => css`
        width: 210px;
        margin-left: 16px;
        ${!props.theme.screen.desktop &&
        css`
            width: 120px;
            &.first {
                margin-right: 36px;
            }
        `}
    `}
`;
const ChartTitle = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        color: ${props.theme.colors.brandGreen};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 11px;
            line-height: 13px;
        `}
    `}
`;
const ChartData = styled.p`
    ${(props) => css`
        display: flex;
        align-items: start;
        font-family: 'Albert-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin: 4px 0 0 0;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 11px;
            line-height: 13px;
        `}
    `}
`;

const ChartDataInner = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-weight: 400;
        margin: 4px 0 0 0;
        text-wrap: balance;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 11px;
            line-height: 13px;
        `}
    `}
`;

const ChartDate = styled.span`
    ${(props) => css`
        color: #737373;
        font-family: 'Albert-Regular';
        font-size: 11px;
        font-weight: 400;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 8px;
        `}
    `}
`;

const ChartIcon = styled(chartIcon)`
    ${(props) => css`
        padding-right: 8px;
        ${!props.theme.screen.desktop &&
        css`
            height: 16px;
            width: 13px;
        `}
    `}
`;

interface Props {
    children: React.ReactNode;
    data: {
        title: string;
        firstChart: string;
        firstNumber: string;
        firstNumberDate: string;
        secondChart: string;
        secondNumber: string | null;
    };
}

export const BusinessMainCard = (props: Props) => {
    return (
        <InformationContainer>
            <InformationTitle>
                <Title>{props.data.title}</Title>
                <Charts>
                    <Chart className="first">
                        <ChartTitle>{props.data.firstChart}</ChartTitle>
                        <ChartData>
                            <ChartIcon />
                            <ChartDataInner>
                                {props.data.firstNumber}
                                <ChartDate> {props.data.firstNumberDate}</ChartDate>
                            </ChartDataInner>
                        </ChartData>
                    </Chart>
                    {props.data.secondNumber !== null && (
                        <Chart>
                            <ChartTitle>{props.data.secondChart}</ChartTitle>
                            <ChartData>
                                <ChartIcon />
                                <ChartDataInner>{props.data.secondNumber}</ChartDataInner>
                            </ChartData>
                        </Chart>
                    )}
                </Charts>
            </InformationTitle>
            {props.children}
        </InformationContainer>
    );
};
