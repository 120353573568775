import { useEffect, useState } from 'react';
import { getReviewsList } from '../../services/reviews.services';
import { BannerItem, Review } from '../../types/api';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IState } from '../../store';
import { RecentReviewCard } from './RecentReviewCard';
import { Slider } from '../CompaniesOfTheWeekSlider/Slider';
import classNames from 'classnames';

const ReviewsContainer = styled.div`
    width: 100%;
    margin-bottom: 80px;
`;
const Header = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 32px;
        font-weight: 700;
        line-height: 38px;
        margin-bottom: 24px;
        color: ${props.theme.colors.black};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
        `};
    `}
`;

const AllReviews = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px 30px;
        &.no-ads-slider {
            display: block;
        }
        ${!props.theme.screen.desktop &&
        css`
            display: block;
        `}
    `}
`;

type Props = {
    noAdsSlider?: boolean;
    twoRows?: boolean;
};

export const RecentReviews = (props: Props) => {
    const { t } = useTranslation();
    const [allReviews, setAllReviews] = useState<Review[]>([]);
    const { bannersList, screenViewPort } = useSelector((state: IState) => state.global);

    useEffect(() => {
        getReviewsList().then((res) => {
            const filteredReviews = res.filter((item: Review) => item.ratingExpectation > 0);
            setAllReviews(filteredReviews);
        });
    }, []);

    const reviewsAndBanners = (): (Review | BannerItem)[] => {
        const reviewsWithAds = [];
        let bannerIndex = 0;
        let bannerIndexWBQ = 0;
        let reviewIndex = 0;
        if (allReviews.length > 0) {
            for (let i = 0; i < 9; i++) {
                if (i === 1) {
                    if (bannersList.WBQ[bannerIndexWBQ]) {
                        reviewsWithAds.push(bannersList.WBQ[bannerIndexWBQ]);
                        bannerIndexWBQ++;
                    }
                } else if (i === 3 || i === 5 || i === 7) {
                    if (bannersList.WBM[bannerIndex]) {
                        reviewsWithAds.push(bannersList.WBM[bannerIndex]);
                        bannerIndex++;
                    }
                } else if (allReviews[reviewIndex]) {
                    reviewsWithAds.push(allReviews[reviewIndex]);
                    reviewIndex++;
                }
            }
        }
        return reviewsWithAds;
    };

    return (
        <ReviewsContainer>
            <Header>{t('talking_about_us')}</Header>
            {allReviews.length > 0 && (
                <AllReviews className={classNames({ 'no-ads-slider': props.noAdsSlider })}>
                    {!props.noAdsSlider ? (
                        <>
                            {screenViewPort === 'desktop' &&
                                reviewsAndBanners().map((review, index) => {
                                    return <RecentReviewCard data={review} index={index} key={index} />;
                                })}
                            {screenViewPort !== 'desktop' && reviewsAndBanners().length > 0 && (
                                <Slider
                                    cardsData={reviewsAndBanners()}
                                    amountPerScreen={{ desktop: 3, tablet: 2, mobile: 1 }}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {allReviews.length > 0 && (
                                <Slider
                                    cardsData={allReviews}
                                    amountPerScreen={{ desktop: props.twoRows ? 6 : 3, tablet: 2, mobile: 1 }}
                                    noAdsSlider={props.noAdsSlider}
                                    twoRows={props.twoRows}
                                />
                            )}
                        </>
                    )}
                </AllReviews>
            )}
        </ReviewsContainer>
    );
};
