import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { CategoryCard } from './CategoryCard';
import { IState } from '../../store';
import { useSelector } from 'react-redux';

const CategoriesWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 80px;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            margin-bottom: 48px;
        `}
`;

const HeaderWrapper = styled.div`
    ${(props) => css`
        display: flex;
        margin: 48px 0 24px;
        width: 100%;
        max-width: 1254px;
        color: ${props.theme.colors.darkGreen};
        ${props.theme.screen.tablet &&
        css`
            margin-top: 40px;
        `}
        ${props.theme.screen.mobile &&
        css`
            margin-top: 33px;
        `}
    `}
`;

const Header = styled.h2`
    font-family: 'Albert-Regular';
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    margin: 0;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            font-size: 20px;
            line-height: 24px;
            flex: none;
        `}
`;

const ContainerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
`;

const CardsWrap = styled.div`
    ${(props) => css`
        display: grid;
        max-width: 1254px;
        width: 100%;
        gap: 16px 30px;
        grid-template-columns: repeat(4, 1fr);
        ${props.theme.screen.mobile &&
        css`
            grid-template-columns: repeat(2, 1fr);
        `}
    `}
`;

export const Categories = () => {
    const { t } = useTranslation();
    const { categoriesList } = useSelector((state: IState) => state.categories);

    return (
        <CategoriesWrapper>
            <HeaderWrapper>
                <Header>{t('categories_of_companies')}</Header>
            </HeaderWrapper>
            <ContainerWrapper>
                <CardsWrap>
                    {categoriesList.map((category, index) => {
                        return <CategoryCard category={category} key={index} />;
                    })}
                </CardsWrap>
            </ContainerWrapper>
        </CategoriesWrapper>
    );
};
