import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CompanyData } from '../../../types/api';
import { BusinessMainCard } from '../BusinessCardWrap/BusinessMainCard';
import { InformationSmallCards } from './InformationComponents/InformationSmallCards';
import { InformationBigCards } from './InformationComponents/InformationBigCards';
import { pluralStringFormater } from '../../Formatters/PluralStringFormater';

const SmallCards = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        padding: 0 24px 34px;
        ${!props.theme.screen.desktop &&
        css`
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
            padding: 24px 16px 24px;
            border-top: 1px solid ${props.theme.colors.outlineLight};
        `}
    `}
`;
const BigCards = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        border-top: 1px solid ${props.theme.colors.outlineLight};
        padding: 32px 24px 24px;
        ${!props.theme.screen.desktop &&
        css`
            grid-template-columns: 1fr;
            gap: 24px;
            padding: 24px 16px 16px;
        `}
    `}
`;

type Props = {
    data: CompanyData;
};

export const Information = (props: Props) => {
    const { t } = useTranslation();

    const cardData = {
        title: t('information'),
        firstChart: t('employees'),
        firstNumber: `${
            props.data.workerStatistics.totalCount.length > 0
                ? props.data.workerStatistics.totalCount[props.data.workerStatistics.totalCount.length - 1].count
                : 0
        } ${
            props.data.workerStatistics.totalCount.length > 0 &&
            pluralStringFormater(
                props.data.workerStatistics.totalCount[props.data.workerStatistics.totalCount.length - 1].count,
                t(`workers.zero`),
                t(`workers.singular`),
                t(`workers.plural`)
            )
        }`,
        firstNumberDate: `(${
            props.data.workerStatistics.totalCount.length > 0
                ? props.data.workerStatistics.totalCount[props.data.workerStatistics.totalCount.length - 1].date
                : null
        })`,
        secondChart: t('registration_date'),
        secondNumber: `${props.data.registrationDate}`,
    };

    return (
        <BusinessMainCard data={cardData}>
            <SmallCards>
                <InformationSmallCards data={props.data} />
            </SmallCards>
            <BigCards>
                <InformationBigCards data={props.data} />
            </BigCards>
        </BusinessMainCard>
    );
};
