import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

const Container = styled.div`
    ${(props) => css`
        margin: 0 23px 32px;
        padding: 24px;
        border: 1px solid ${props.theme.colors.outlineLight};
        border-radius: 10px;
        display: flex;
        align-items: center;
        ${!props.theme.screen.desktop &&
        css`
            margin: 0 16px 16px;
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
        `};
    `}
`;

const Text = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 13px;
            line-height: 16px;
        `}
    `}
`;

const Header = styled(Text)`
    ${(props) => css`
        font-weight: 700;
        margin-right: 32px;
        white-space: nowrap;
        ${!props.theme.screen.desktop &&
        css`
            margin-bottom: 14px;
        `}
    `}
`;

const SearchWordsWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

type SearchProp = {
    searchWords: SearchWord[];
};

type SearchWord = {
    keywords: string;
};

export const SearchWords = (props: SearchProp) => {
    const { t } = useTranslation();

    return (
        <>
            {props.searchWords && props.searchWords.length > 0 && (
                <Container>
                    <Header>{t('search_words') + ':'}</Header>
                    <SearchWordsWrap>
                        {props.searchWords.map((word) => (
                            <Text>{word.keywords}</Text>
                        ))}
                    </SearchWordsWrap>
                </Container>
            )}
        </>
    );
};
