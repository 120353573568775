import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CompanyData } from '../../../../../types/api';
import LeafletMap from '../../../../LeafletMap/LeafletMap';
import React, { useEffect, useState } from 'react';
import { Slider } from '../../../../CompaniesOfTheWeekSlider/Slider';
import { useSelector } from 'react-redux';
import { IState } from '../../../../../store';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
    ${(props) => css`
        margin: 0 23px 32px;
        padding: 24px;
        border: 1px solid ${props.theme.colors.outlineLight};
        border-radius: 10px;
        ${!props.theme.screen.desktop &&
        css`
            margin: 0 16px 24px;
            padding: 16px;
        `}
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 32px;
        ${!props.theme.screen.desktop &&
        css`
            margin-bottom: 16px;
            font-size: 17px;
            line-height: 20px;
        `}
    `}
`;
const MapAndTime = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0 30px;
`;
const MapContainer = styled.div`
    ${(props) => css`
        width: 55%;
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;
const WeekDaysContainer = styled.div`
    ${(props) => css`
        width: calc(45% - 30px);
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 165px;
        `}
    `}
`;
const LeafletContainer = styled.div`
    position: relative;
    z-index: 1;
`;

const BoldText = styled.div`
    ${(props) => css`
        margin-top: 16px;
        font-family: 'Albert-Regular';
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 14px;
            line-height: 20px;
        `}
    `}
`;
const Button = styled.div`
    ${(props) => css`
        margin-top: 16px;
        display: flex;
        align-items: center;
        color: ${props.theme.colors.brandRed};
        font-family: 'Albert-Regular';
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        ${!props.theme.screen.desktop &&
        css`
            margin-top: 24px;
        `}
    `}
`;
const Chevron = styled(ChevronRightIcon)`
    width: 18px !important;
    height: 18px !important;
    &.arrow {
        rotate: 90deg;
    }
`;
const DayWrap = styled.div`
    display: flex;
    align-items: center;
    margin-top: 8px;
`;
const Day = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.lightNeutral};
        padding: 4px 12px;
        font-family: 'Albert-Regular';
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        border-radius: 10px;
        color: ${props.theme.colors.outlineAndPlaceholder};
        width: 14px;
        display: flex;
        justify-content: center;
        &.weekend {
            background-color: transparent;
            border: 1px solid ${props.theme.colors.outlineAndPlaceholder};
        }
        ${!props.theme.screen.desktop &&
        css`
            font-size: 13px;
            line-height: 16px;
        `}
    `}
`;
const Time = styled.div`
    ${(props) => css`
        margin-left: 24px;
        font-family: 'Albert-Regular';
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        &.not-working {
            color: ${props.theme.colors.outlineAndPlaceholder};
        }
        ${!props.theme.screen.desktop &&
        css`
            font-size: 16px;
            line-height: 19px;
            margin-left: 16px;
        `}
    `}
`;
const Branches = styled.div`
    width: 100%;
`;
const Divider = styled.div`
    ${(props) => css`
        width: 100%;
        height: 1px;
        background-color: ${props.theme.colors.outlineLight};
        margin: 32px 0;
        ${!props.theme.screen.desktop &&
        css`
            margin: 24px 0;
        `}
    `}
`;
const PropertiesContainer = styled.div`
    width: 100%;
`;

const BonusesContainer = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px 0;
        ${props.theme.screen.mobile &&
        css`
            justify-content: space-between;
        `}
    `}
`;
const Bonus = styled.div`
    ${(props) => css`
        margin: 0;
        display: flex;
        align-items: center;
        ${props.theme.screen.tablet &&
        css`
            font-size: 13px;
            line-height: 16px;
        `}
        ${props.theme.screen.mobile &&
        css`
            width: 48%;
            font-size: 14px;
            line-height: 17px;
            overflow-wrap: anywhere;
        `}
    `}
`;
const Image = styled.img`
    ${(props) => css`
        margin-right: 8px;
        height: 32px;
        width: 32px;
        font-family: 'Albert-Regular';
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 13px;
            line-height: 16px;
            height: 24px;
            width: 24px;
        `}
    `}
`;

type Props = {
    data: CompanyData;
};

export const PlaceAndWorkingHours = (props: Props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [allBranches, setAllBranches] = useState(true);
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const weekDay = [t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday'), t('sunday')];

    const formatTime = (time: string) => {
        if (!time) {
            return t('not_working');
        }
        const date = time.split(':');
        const hours = +date[0];
        const minutes = date[1];
        const formatedTime = `${hours}.${minutes}`;
        return formatedTime;
    };

    useEffect(() => {
        // This is used to reset the branch list state to be closed after clicking a branch link in a different company inner page. Without it the slider element stays open.
        setAllBranches(true);
    }, [location]);

    return (
        <Container>
            <Header>{t('location_and_working_hours')}</Header>
            <MapAndTime>
                {props.data.address && (
                    <MapContainer>
                        <LeafletContainer>
                            <LeafletMap
                                coordinates={props.data.address}
                                companies={props.data.childCompanies}
                                class={'inner-company-card'}
                            />
                        </LeafletContainer>
                        <BoldText>
                            {props.data.address.street} {props.data.address.buildingNum}, {props.data.address.zip},{' '}
                            {props.data.address.city}
                        </BoldText>
                        {props.data.childCompanies.length > 0 && (
                            <Button onClick={() => setAllBranches((prev) => !prev)}>
                                {t('all_branches')}
                                <Chevron className={classNames({ arrow: allBranches })} />
                            </Button>
                        )}
                    </MapContainer>
                )}
                {/* This part shouldn't be a slider in mobile/tablet screens, but in my opinion leaving the slider is a more logical solution to maintain integrity throughout the project*/}
                {allBranches && screenViewPort !== 'desktop' && (
                    <Branches>
                        <Divider />
                        <Slider
                            cardsData={props.data.childCompanies}
                            amountPerScreen={{ desktop: 3, tablet: 2, mobile: 2 }}
                            class="branches-mobile"
                        />
                    </Branches>
                )}
                {screenViewPort !== 'desktop' && <Divider />}
                {props.data.workingTime && (
                    <WeekDaysContainer>
                        {props.data.workingTime.map((day, i) => {
                            if (!day.openTime) {
                                return (
                                    <React.Fragment key={i}>
                                        <DayWrap>
                                            <Day className={classNames({ weekend: i > 4 })}>{weekDay[i]}</Day>
                                            <Time className="not-working">{t('not_working')}</Time>
                                        </DayWrap>
                                    </React.Fragment>
                                );
                            }
                            return (
                                <DayWrap key={i}>
                                    <Day className={classNames({ weekend: i > 4 })}>{weekDay[i]}</Day>
                                    <Time>{`${formatTime(day.openTime)} - ${formatTime(day.closeTime)}`}</Time>
                                </DayWrap>
                            );
                        })}
                    </WeekDaysContainer>
                )}
                {allBranches && screenViewPort === 'desktop' && (
                    <>
                        <Divider />
                        <Branches>
                            <Slider
                                cardsData={props.data.childCompanies}
                                amountPerScreen={{ desktop: 3, tablet: 2, mobile: 1 }}
                            />
                        </Branches>
                    </>
                )}
                {props.data.properties.length > 0 && (
                    <>
                        <Divider />
                        <PropertiesContainer>
                            <BonusesContainer>
                                {props.data.properties.map((property, i) => {
                                    return (
                                        <Bonus key={i}>
                                            <Image src={property.image} />
                                            {property.name}
                                        </Bonus>
                                    );
                                })}
                            </BonusesContainer>
                        </PropertiesContainer>
                    </>
                )}
            </MapAndTime>
        </Container>
    );
};
