import { useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import arrowForwardIcon from '@mui/icons-material/ArrowForward';

import arrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Topic, TopicHead } from '../../types/api';
import React from 'react';

const TopicItem = styled.div`
    font-family: 'Albert-Regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: ${(props) => props.theme.colors.black};
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    &:hover {
        cursor: pointer;
        font-weight: 700;
    }
`;

const ArrowForwardIcon = styled(arrowForwardIcon)`
    height: 18px !important;
    width: 18px !important;
`;

const InnerButtonWrap = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: ${props.theme.colors.brandRed};
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        &.branches-button {
            margin-top: 8px;
            ${!props.theme.screen.desktop &&
            css`
                margin-top: 16px;
            `}
        }
        ${!props.theme.screen.desktop &&
        css`
            font-size: 14px;
            line-height: 20px;
        `}
    `}
`;
const InnerButton = styled.div`
    &:hover {
        font-weight: 700;
    }
`;
const ArrowForwardIosIcon = styled(arrowForwardIosIcon)`
    height: 18px !important;
    width: 18px !important;
    &.show-more {
        rotate: 270deg;
    }
`;
type Props = {
    topic: Topic;
    topicId: number;
};

export const TopicHeads = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const urlParams = useParams();
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore((current) => !current);
    };

    const headsSlice = (heads: TopicHead[]) => {
        if (heads && heads.length > 3 && !showMore) {
            return heads.slice(0, 8);
        }
        return heads;
    };

    const showButton = () => {
        return props.topic.heads.length > 8;
    };

    const navigateToBusinessList = (categoryId: number, headId: number, name: string) => {
        if (headId) {
            const state = { topicOrHeadName: name };
            if (urlParams.categoryId) {
                navigate(`${props.topicId}/${headId}`, { state });
                return;
            }
            navigate(`${categoryId}/${props.topicId}/${headId}`, { state });
        }
    };

    return (
        <>
            {props.topic.heads.length > 0 &&
                headsSlice(props.topic.heads).map((head, i) => {
                    return (
                        <React.Fragment key={i}>
                            <TopicItem
                                onClick={() => navigateToBusinessList(props.topic.categoryId, head.id, head.name)}
                                key={head.id}
                            >
                                {head.name.charAt(0).toUpperCase() + head.name.substring(1)}
                                <ArrowForwardIcon fontSize="small" />
                            </TopicItem>
                        </React.Fragment>
                    );
                })}
            {showButton() && (
                <InnerButtonWrap onClick={toggleShowMore} className="branches-button">
                    <InnerButton className="inner-button">
                        {!showMore ? t('more_categories') : t('less_categories')}
                    </InnerButton>
                    <ArrowForwardIosIcon className={showMore ? 'show-more' : ''} fontSize="small" />
                </InnerButtonWrap>
            )}
        </>
    );
};
