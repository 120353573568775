import styled, { css } from 'styled-components';
import { CompanyData } from '../../../../types/api';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SmallLogo } from '../../../../assets/images/InnerCompanyPage/information_small_card_img.svg';

const Card = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.informationSmallCardBackground};
        display: flex;
        align-items: center;
        padding: 16px;
        border-radius: 10px;
        ${!props.theme.screen.desktop &&
        css`
            padding: 10px 16px;
        `}
    `}
`;
const Icon = styled(SmallLogo)`
    ${(props) => css`
        padding-right: 16px;
        &.tablet-icon {
            display: none;
        }
        ${!props.theme.screen.desktop &&
        css`
            height: 18px;
            width: 18px;
            padding-right: 8px;
            &.desktop-icon {
                display: none;
            }
            &.tablet-icon {
                display: block;
            }
        `}
    `}
`;
const Text = styled.p`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: ${props.theme.colors.outlineAndPlaceholder};
        margin: 0 0 8px 0;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 11px;
            line-height: 13px;
            display: flex;
            align-items: center;
        `}
    `}
`;
const Number = styled.p`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        color: ${props.theme.colors.black};
        margin: 0;
        overflow-wrap: anywhere;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 16px;
            line-height: 19px;
            width: 100%;
        `}
    `}
`;

type Props = {
    data: CompanyData;
};

export const InformationSmallCards = (props: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <Card>
                <Icon className="desktop-icon" />
                <div>
                    <Text>
                        <Icon className="tablet-icon" />
                        {t('company_code')}
                    </Text>
                    {props.data.companyCode ? (
                        <Number>{props.data.companyCode}</Number>
                    ) : (
                        <Number>{t('no_information_provided')}</Number>
                    )}
                </div>
            </Card>
            <Card>
                <Icon className="desktop-icon" />
                <div>
                    <Text>
                        <Icon className="tablet-icon" />
                        {t('vat_code')}
                    </Text>
                    {props.data.vatCode ? (
                        <Number>{props.data.vatCode}</Number>
                    ) : (
                        <Number>{t('no_information_provided')}</Number>
                    )}
                </div>
            </Card>
            <Card>
                <Icon className="desktop-icon" />
                <div>
                    <Text>
                        <Icon className="tablet-icon" />
                        {t('health_insurer_code')}
                    </Text>
                    {props.data.insurerCode ? (
                        <Number>{props.data.insurerCode}</Number>
                    ) : (
                        <Number>{t('no_information_provided')}</Number>
                    )}
                </div>
            </Card>
        </>
    );
};
