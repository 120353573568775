import { CompanyListItem, ICategory, QueryParams, SearchFilters } from '../../types/api';

export interface ICompaniesState {
    companiesList: CompanyListItem[];
    categoriesList: ICategory[];
    searchFilters: SearchFilters;
    pagination: {
        currentPage: number;
        perPage: number;
        total: number;
        query: QueryParams;
    };
}

export const initialCompaniesState: ICompaniesState = {
    companiesList: [],
    categoriesList: [],
    searchFilters: {
        city: [],
        topics: [],
        workingTime: [],
        legalType: {},
    },
    pagination: {
        currentPage: 1,
        perPage: 20,
        total: 0,
        query: {
            name: '',
            manager: '',
            legal_type: '',
            company_code: '',
            vat_code: '',
            days: [],
            head: '',
            topic: 0,
            category: 0,
            address: '',
            city: 0,
            query: '',
        },
    },
};
