import styled, { css } from 'styled-components';
import {
    CompanyData,
    FilteredManagementPersonsOrBank,
    FilteredPersonsCardOpen,
    PersonOrBank,
    Transports,
} from '../../../../types/api';
import { BusinessGreyLogoCard } from '../../BusinessGreyLogoCard/BusinessGreyLogoCard';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import arrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IState } from '../../../../store';
import React from 'react';
import { pluralStringFormater } from '../../../Formatters/PluralStringFormater';
import classNames from 'classnames';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

const Title = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        margin: 0 0 6px 0;
        color: ${props.theme.colors.outlineAndPlaceholder};
        &.margin-top {
            margin-top: 10px;
        }
        ${!props.theme.screen.desktop &&
        css`
            font-size: 11px;
            line-height: 13px;
        `}
    `}
`;
const NameAndIcons = styled.div`
    ${(props) => css`
        display: flex;
        align-items: start;
        justify-content: space-between;
        margin: 0 0 10px 0;
        &.flex-direction-column {
            flex-direction: column;
        }
        ${props.theme.screen.mobile &&
        css`
            display: block;
        `}
    `}
`;
const About = styled.p`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        margin: 0;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 13px;
            line-height: 16px;
        `}
    `}
`;
const Button = styled.button`
    ${(props) => css`
        background-color: transparent;
        border: none;
        color: ${props.theme.colors.brandRed};
        font-family: 'Albert-Regular';
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: 10px;
        ${!props.theme.screen.desktop &&
        css`
            margin-top: 24px;
        `}
    `}
`;
const Name = styled.p`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        margin: 0;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 16px;
            line-height: 19px;
        `}
    `}
`;
const Icons = styled.div`
    ${(props) => css`
        display: flex;
        padding-left: 6px;
        ${props.theme.screen.mobile &&
        css`
            padding-left: 0;
            margin-top: 16px;
        `}
    `}
`;
const IconDivider = styled.div`
    ${(props) => css`
        height: 24px;
        width: 1px;
        margin: 0 10px;
        background-color: ${props.theme.colors.outlineLight};
        ${props.theme.screen.mobile &&
        css`
            margin: 0 16px;
        `}
    `}
`;

const Arrow = styled(arrowForwardIcon)`
    height: 18px !important;
    width: 18px !important;
    margin-left: 8px;
    &.up {
        rotate: 270deg;
    }
`;

const WebLinkContainer = styled.a`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        display: flex;
        text-decoration: none;
        color: ${props.theme.colors.black};
        justify-content: space-between;
        align-items: center;
        margin: 0;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 14px;
            line-height: 20px;
        `}
    `}
`;

const RubricWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Rubric = styled.div`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
    cursor: pointer;
`;

const Icon = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        color: ${props.theme.colors.brandRed};
        margin-right: 4px;
        .icon {
            width: 16px !important;
            height: 16px !important;
        }
    `}
`;

type Props = {
    data: CompanyData;
};

export const InformationBigCards = (props: Props) => {
    const { t } = useTranslation();
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const { categoriesList } = useSelector((state: IState) => state.categories);
    const navigate = useNavigate();
    const [filteredManagementPersonsOrBank, setFilteredManagementPersonsOrBank] =
        useState<FilteredManagementPersonsOrBank>({
            councillor: [],
            board: [],
            bank: [],
        });
    const [cardOpen, setCardOpen] = useState<FilteredPersonsCardOpen>({
        councillor: false,
        board: false,
        bank: false,
        carPark: false,
    });

    useEffect(() => {
        const data: FilteredManagementPersonsOrBank = {};
        props.data.managementPersons.forEach((person) => {
            if (!data[person.type]) {
                data[person.type] = [];
            }
            data[person.type] = [...data[person.type], person];
        });
        setFilteredManagementPersonsOrBank((prevData) => {
            return { ...prevData, ...data };
        });
    }, []);

    const informationSlice = (cardInfo: PersonOrBank[], cardName: string) => {
        if (cardInfo.length > 1 && !cardOpen[cardName as keyof typeof cardOpen]) {
            return cardInfo.slice(0, 1);
        }
        return cardInfo;
    };

    const navigateToTopic = (topicId: number, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const category = categoriesList.find((category) => category.topics.find((topic) => topic.id === topicId));
        if (category) {
            navigate(`/imoniu-sarasas/${category.id}/${topicId}`);
        }
    };

    const transportSlice = (data: Transports[]) => {
        const allCarsArray: Transports = {
            carBelongs: 0,
            carLeasing: 0,
            totalCars: 0,
        };
        data.forEach((type: Transports) => {
            allCarsArray.carBelongs += type.carBelongs;
            allCarsArray.carLeasing += type.carLeasing;
            allCarsArray.totalCars += type.totalCars;
        });

        if (data.length > 0 && !cardOpen.carPark) {
            return (
                <>
                    <Title>{t('for_sale_or_lease')}</Title>
                    <Name>
                        {allCarsArray.totalCars}{' '}
                        {pluralStringFormater(
                            allCarsArray.totalCars,
                            t(`car.zero`),
                            t(`car.singular`),
                            t(`car.plural`)
                        )}
                    </Name>
                </>
            );
        }
        return (
            <>
                <Title>{t('all_cars')}</Title>
                <Name>
                    {allCarsArray.totalCars}{' '}
                    {pluralStringFormater(allCarsArray.totalCars, t(`car.zero`), t(`car.singular`), t(`car.plural`))}
                </Name>
                <Title className="margin-top">{t('property_rights')}</Title>
                <Name>
                    {allCarsArray.carBelongs}{' '}
                    {pluralStringFormater(allCarsArray.carBelongs, t(`car.zero`), t(`car.singular`), t(`car.plural`))}
                </Name>
                <Title className="margin-top">{t('lease')}</Title>
                <Name>
                    {allCarsArray.carLeasing}{' '}
                    {pluralStringFormater(allCarsArray.carLeasing, t(`car.zero`), t(`car.singular`), t(`car.plural`))}
                </Name>
            </>
        );
    };

    return (
        <>
            {props.data.manager?.name && (
                <BusinessGreyLogoCard titleInfo={t('manager')}>
                    <Title>{props.data.manager.position}</Title>
                    {props.data.name !== '' && (
                        <NameAndIcons>
                            <Name>{props.data.manager.name}</Name>
                            <Icons>
                                {props.data.manager.email && (
                                    <WebLinkContainer href={`mailto:${props.data.manager.email}`}>
                                        <MailOutlineIcon />
                                    </WebLinkContainer>
                                )}
                                {props.data.manager.email && props.data.manager.phone && <IconDivider />}
                                {props.data.manager.phone && (
                                    <WebLinkContainer href={`tel:${props.data.manager.phone}`}>
                                        <LocalPhoneOutlinedIcon />
                                    </WebLinkContainer>
                                )}
                                {((props.data.manager.email && props.data.manager.linkedin) ||
                                    (props.data.manager.linkedin && props.data.manager.phone)) && <IconDivider />}
                                {props.data.manager.linkedin && (
                                    <WebLinkContainer href={props.data.manager.linkedin} target="_blank">
                                        <LinkedInIcon />
                                    </WebLinkContainer>
                                )}
                            </Icons>
                        </NameAndIcons>
                    )}
                    {props.data.manager.description !== '' && <About>{props.data.manager.description}</About>}
                </BusinessGreyLogoCard>
            )}
            {filteredManagementPersonsOrBank.board.length > 0 && (
                <BusinessGreyLogoCard titleInfo={t('boards')}>
                    {informationSlice(filteredManagementPersonsOrBank.board, 'board')?.map((person, index) => {
                        return (
                            <React.Fragment key={index}>
                                {person.type && <Title>{t(person.type)}</Title>}
                                {props.data.name !== '' && (
                                    <NameAndIcons>
                                        <Name>
                                            {person.firstName} {person.lastName}
                                        </Name>
                                        <Icons>
                                            {person.email && (
                                                <WebLinkContainer href={`mailto:${person.email}`}>
                                                    <MailOutlineIcon />
                                                </WebLinkContainer>
                                            )}
                                            {person.email && person.phone && <IconDivider />}
                                            {person.phone && (
                                                <WebLinkContainer href={`tel:${person.phone}`}>
                                                    <LocalPhoneOutlinedIcon />
                                                </WebLinkContainer>
                                            )}
                                        </Icons>
                                    </NameAndIcons>
                                )}
                            </React.Fragment>
                        );
                    })}
                    {filteredManagementPersonsOrBank.board.length > 1 && (
                        <Button onClick={() => setCardOpen((prev) => ({ ...prev, board: !prev.board }))}>
                            {!cardOpen.board
                                ? screenViewPort !== 'mobile'
                                    ? t('show_all')
                                    : t('show')
                                : t('show_less')}
                            <Arrow className={classNames({ up: cardOpen.board })} />
                        </Button>
                    )}
                </BusinessGreyLogoCard>
            )}
            {filteredManagementPersonsOrBank.councillor.length > 0 && (
                <BusinessGreyLogoCard titleInfo={t('councillors')}>
                    {informationSlice(filteredManagementPersonsOrBank.councillor, 'councillor')?.map(
                        (person, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {person.type && <Title>{t(person.type)}</Title>}
                                    {props.data.name !== '' && (
                                        <NameAndIcons>
                                            <Name>
                                                {person.firstName} {person.lastName}
                                            </Name>
                                            <Icons>
                                                {person.email && (
                                                    <WebLinkContainer href={`mailto:${person.email}`}>
                                                        <MailOutlineIcon />
                                                    </WebLinkContainer>
                                                )}
                                                {person.email && person.phone && <IconDivider />}
                                                {person.phone && (
                                                    <WebLinkContainer href={`tel:${person.phone}`}>
                                                        <LocalPhoneOutlinedIcon />
                                                    </WebLinkContainer>
                                                )}
                                            </Icons>
                                        </NameAndIcons>
                                    )}
                                </React.Fragment>
                            );
                        }
                    )}
                    {filteredManagementPersonsOrBank.councillor.length > 1 && (
                        <Button onClick={() => setCardOpen((prev) => ({ ...prev, councillor: !prev.councillor }))}>
                            {!cardOpen.councillor
                                ? screenViewPort !== 'mobile'
                                    ? t('show_all')
                                    : t('show')
                                : t('show_less')}
                            <Arrow className={classNames({ up: cardOpen.councillor })} />
                        </Button>
                    )}
                </BusinessGreyLogoCard>
            )}
            {props.data.bankAccounts.length > 0 && (
                <BusinessGreyLogoCard titleInfo={t('bank_accounts')}>
                    {informationSlice(props.data.bankAccounts, 'bank').map((bank, index) => (
                        <React.Fragment key={index}>
                            <Title>{bank.bankName}</Title>
                            {props.data.name !== '' && (
                                <NameAndIcons>
                                    <Name>{bank.accountNumber}</Name>
                                </NameAndIcons>
                            )}
                        </React.Fragment>
                    ))}
                    {props.data.bankAccounts.length > 1 && (
                        <Button onClick={() => setCardOpen((prev) => ({ ...prev, bank: !prev.bank }))}>
                            {!cardOpen.bank
                                ? screenViewPort !== 'mobile'
                                    ? t('show_all')
                                    : t('show')
                                : t('show_less')}
                            <Arrow className={classNames({ up: cardOpen.bank })} />
                        </Button>
                    )}
                </BusinessGreyLogoCard>
            )}
            {props.data.topics && (
                <BusinessGreyLogoCard titleInfo={t('scope')}>
                    <Title>{t('rubrics')}</Title>
                    <RubricWrap>
                        {props.data.topics.map((rubric) => (
                            <Rubric onClick={(e) => navigateToTopic(rubric.id, e)} key={rubric.id}>
                                <Icon>
                                    <CheckCircleIcon className="icon" />
                                </Icon>
                                {rubric.name}
                            </Rubric>
                        ))}
                    </RubricWrap>
                </BusinessGreyLogoCard>
            )}
            {props.data.transports.length > 0 && (
                <BusinessGreyLogoCard titleInfo={t('car_park')}>
                    <NameAndIcons className="flex-direction-column">
                        {transportSlice(props.data.transports)}
                    </NameAndIcons>
                    <Button onClick={() => setCardOpen((prev) => ({ ...prev, carPark: !prev.carPark }))}>
                        {!cardOpen.carPark ? (screenViewPort !== 'mobile' ? t('show_all') : t('show')) : t('show_less')}
                        <Arrow className={classNames({ up: cardOpen.carPark })} />
                    </Button>
                </BusinessGreyLogoCard>
            )}
        </>
    );
};
