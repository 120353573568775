import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { TrademarkImage } from '../../../../types/api';

const Container = styled.div`
    ${(props) => css`
        margin: 0 23px 32px;
        padding: 24px;
        border: 1px solid ${props.theme.colors.outlineLight};
        border-radius: 10px;
        ${!props.theme.screen.desktop &&
        css`
            margin: 0 16px 24px;
            padding: 16px;
        `}
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 32px;
        ${!props.theme.screen.desktop &&
        css`
            margin-bottom: 16px;
            font-size: 17px;
            line-height: 20px;
        `}
    `}
`;
const ImgWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px 32px;
`;
const Img = styled.img`
    max-height: 40px;
    filter: grayscale(1);
    opacity: 0.7;
`;
const Text = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        white-space: nowrap;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 16px;
            line-height: 19px;
        `}
    `}
`;

type TrademarkImages = {
    trademarkImages: TrademarkImage[];
};

export const BrandsRepresented = (props: TrademarkImages) => {
    const { t } = useTranslation();

    return (
        <Container>
            <Header>{t('brands_represented')}</Header>
            <ImgWrap>
                <>
                    {props.trademarkImages.map((img, i) => (
                        <Img key={i} src={img.logoPath} />
                    ))}
                </>
            </ImgWrap>
        </Container>
    );
};
