import styled, { css } from 'styled-components';
import { Address } from '../../../../../types/api';
import { useTranslation } from 'react-i18next';
import arrow from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

const BranchesContainer = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        width: 100%;
        min-height: 102px;
        ${props.theme.screen.tablet &&
        css`
            min-height: 110px;
        `}
        ${props.theme.screen.mobile &&
        css`
            min-height: unset;
        `}
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 14px;
            line-height: 20px;
        `}
    `}
`;
const Text = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-top: 8px;
        ${!props.theme.screen.desktop &&
        css`
            margin-top: 4px;
            font-size: 14px;
            line-height: 20px;
        `}
    `}
`;
const Button = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        color: ${props.theme.colors.brandRed};
        margin-top: 8px;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        ${!props.theme.screen.desktop &&
        css`
            margin-top: 24px;
        `}
    `}
`;
const Arrow = styled(arrow)`
    width: 18px !important;
    height: 18px !important;
    margin-left: 8px;
`;

type Props = {
    data: {
        id: number;
        name: string;
        address: Address;
    };
};

export const BranchesCard = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const cardInnerPage = () => {
        navigate(`../imones-kortele/${props.data.id}`);
    };

    return (
        <BranchesContainer>
            <Header>{props.data.name}</Header>
            <Text>
                {`${props.data.address.street} `}
                {props.data.address.buildingNum}
            </Text>
            <Text>
                {props.data.address.zip}
                {props.data.address.zip && props.data.address.street && ', '}
                {props.data.address.city}
            </Text>
            <Button onClick={cardInnerPage}>
                {t('go_to_company_page')}
                <Arrow />
            </Button>
        </BranchesContainer>
    );
};
