import styled, { css } from 'styled-components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Background from '../../assets/images/page_not_found.png';
import { useTranslation } from 'react-i18next';
import { ROUTE_NAMES } from '../../constants/routes';
import { useEffect, useState } from 'react';
import { getSearchCompaniesList } from '../../services/company.service';

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Header = styled.h1`
    margin: 129px 0px 16px;
    font-family: 'Albert-Bold';
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
`;

const Text = styled.p`
    margin: 0 0 48px 0;
    font-family: 'Albert-Regular';
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
`;

const Button = styled(Link)`
    ${(props) => css`
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.brandRed};
        padding: 12px 24px;
        border-radius: 100px;
        cursor: pointer;
        border: none;
        font-family: 'Albert-Regular';
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        text-decoration: none;
        position: relative;
        z-index: 2;
    `}
`;

const BackgroundImg = styled.div`
    background-image: url(${Background});
    position: absolute;
    transform: translateY(-75px);
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 1;
    padding: 1px 0 16px;
`;

export const PageNotFound = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        getSearchCompaniesList(1, { query: location.pathname }).then((res) => {
            if (res.data.length > 0) {
                navigate(`/imones-kortele/${res.data[0].id}`);
            }

            setNotFound(true);
        });
    });

    return (
        <>
            {notFound && (
                <PageContainer>
                    <Header>{t('page_not_found')}</Header>
                    <Text>{t('sorry_but_there_is_nothing_here')}. </Text>
                    <Button to={ROUTE_NAMES.HOME}>{t('return_to_main_page')}</Button>
                    <BackgroundImg />
                </PageContainer>
            )}
        </>
    );
};
