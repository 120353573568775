import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FeedbackSummary } from './components/FeedbackSummary';

import { getCompanyFeedback } from '../../../services/reviews.services';
import { Logo, Rating, Review } from '../../../types/api';

import snakecaseKeys from 'snakecase-keys';
import { UserFeedback } from './components/UserFeedback';

import { ReactComponent as icon } from '../../../assets/images/InnerCompanyPage/green_chart.svg';

import styled, { css } from 'styled-components';

const Container = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        background-color: ${props.theme.colors.white};
        border-radius: 10px;
        padding: 35px 24px;
    `}
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            flex-direction: column;
            align-items: flex-start;
        `}
    `}
`;

const Heading = styled.div`
    ${(props) => css`
        color: ${props.theme.colors.black};
        font-size: 32px;
        font-weight: 700;
        line-height: 120%;
        flex: 1;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 20px;
        `}
    `}
`;

const Statistics = styled.div`
    display: flex;
    align-items: center;
    width: 50%;
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
            margin-top: 16px;
        `}
    `}
`;

const Statistic = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;

const Label = styled.div`
    ${(props) => css`
        color: ${props.theme.colors.brandGreen};
        font-size: 12px;
        font-weight: 700;
        line-height: 120%;
    `}
`;

const StatisticValue = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        gap: 8px;
        color: ${props.theme.colors.secondaryBlack};
        font-size: 16px;
        line-height: 120%;
    `}
`;

const StatisticIcon = styled(icon)`
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            width: 13px;
            height: 16px;
        `}
    `}
`;

interface Props {
    companyId: number;
    separateRatings: Rating;
    rating: number;
    feedbackCount: number;
    companyName: string;
    logo?: Logo | null;
    boughtInteractive: boolean;
}

export const Feedback = (props: Props) => {
    const { t } = useTranslation();
    const [feedbackData, setFeedbackData] = useState<Review[]>([]);
    const convertedCaseRatings = snakecaseKeys(props.separateRatings);

    useEffect(() => {
        getCompanyFeedback(props.companyId).then((response) => {
            const reviewWithUser = response.data.filter((review) => review.user != null);
            setFeedbackData(reviewWithUser);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Container id="feedback">
            <HeaderWrapper>
                <Heading>{t('feedback')}</Heading>
                <Statistics>
                    <Statistic>
                        <Label>{t('feedback_number')}</Label>
                        <StatisticValue>
                            <StatisticIcon />
                            {props.feedbackCount}
                        </StatisticValue>
                    </Statistic>
                    <Statistic>
                        <Label>{t('general_rating')}</Label>
                        <StatisticValue>
                            <StatisticIcon />
                            {props.rating.toString().replace('.', ',')}
                        </StatisticValue>
                    </Statistic>
                </Statistics>
            </HeaderWrapper>
            <FeedbackSummary
                separateRatings={convertedCaseRatings}
                rating={props.rating}
                feedbackCount={props.feedbackCount}
            />
            {feedbackData.length > 0 &&
                feedbackData
                    .slice(-3)
                    .reverse()
                    .map((feedback) => (
                        <UserFeedback
                            key={feedback.id}
                            feedback={feedback}
                            companyId={props.companyId}
                            companyName={props.companyName}
                            boughtInteractive={props.boughtInteractive}
                            logo={props.logo}
                        />
                    ))}
        </Container>
    );
};
