import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IState } from '../../store';
import { useParams } from 'react-router-dom';

const BannerContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 48px 0;
    &.gate-active {
        padding: 19px 0;
    }
    &.inside-element {
        padding: 32px 0 48px;
        border-bottom: none;
    }
`;
const BannerWrap = styled.div`
    max-width: 1254px;
    margin: 0 16px;
    min-height: 116px;
    max-height: 156px;
    width: 100%;
    &.inside-element {
        margin: 0;
    }
`;
const Banner = styled.img`
    object-fit: fill;
    border-radius: 10px;
    height: 100%;
    width: 100%;
`;

type Props = {
    imgUrl?: string;
    imgHref?: string;
    inElement?: boolean;
    isGateAllowed: boolean;
};

export const HorizontalBanner = (props: Props) => {
    const { bannersList, bannersFilteredByTopic, bannersFilteredByKeyword, gateIsActive } = useSelector(
        (state: IState) => state.global
    );
    const [banner, setBanner] = useState({
        imgUrl: props.imgUrl || '',
        imgHref: props.imgHref || '',
    });

    const urlParams = useParams();

    useEffect(() => {
        if (urlParams?.topicId && bannersFilteredByTopic.WBR.length > 0) {
            setBanner({
                imgUrl: bannersFilteredByTopic.WBR[0].image,
                imgHref: bannersFilteredByTopic.WBR[0].url,
            });
            return;
        }

        if (bannersFilteredByKeyword.WBZ.length > 0) {
            setBanner({
                imgUrl: bannersFilteredByKeyword.WBZ[0].image,
                imgHref: bannersFilteredByKeyword.WBZ[0].url,
            });

            return;
        }

        if (bannersList.WBP.length > 0) {
            setBanner({
                imgUrl: bannersList.WBP[0].image,
                imgHref: bannersList.WBP[0].url,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersList.WBP, bannersFilteredByTopic, bannersFilteredByKeyword]);

    return (
        <>
            {banner.imgUrl && (
                <BannerContainer
                    className={classNames({
                        'inside-element': props.inElement,
                        'gate-active': gateIsActive,
                    })}
                >
                    <BannerWrap
                        className={classNames({
                            'inside-element': props.inElement,
                        })}
                    >
                        <a href={banner.imgHref} target="_blank" rel="noreferrer">
                            <Banner src={banner.imgUrl} />
                        </a>
                    </BannerWrap>
                </BannerContainer>
            )}
        </>
    );
};
