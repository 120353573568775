import { ReviewListResponse, FeedbackReactionResponse, FullFeedback, FeedbackRespond } from '../types/api';
import http from './client/http';

const baseUrl = process.env.REACT_APP_BASE_API_URL || 'https://imonesapi.tandemumstage.site';

export const getReviewsList = () =>
    http.get<ReviewListResponse>(`/companies/365/feedback/newest`).then((res) => res.data.data);

export const getCompanyFeedback = (companyId: number) =>
    http
        .get<{ data: ReviewListResponse }>(`/companies/${companyId}/feedback`, { withCredentials: true })
        .then((res) => res.data.data);

export const postCompanyFeedbackReaction = (companyId: number, feedbackId: number, reaction: number, userId: number) =>
    http
        .post<FeedbackReactionResponse>(
            `/companies/${companyId}/feedback/${feedbackId}/reaction`,
            {
                reaction,
                user_id: userId,
            },
            { withCredentials: true }
        )
        .then((response) => response.data.data);

export const editUserFeedback = (companyId: number, feedbackId: number, data: FullFeedback) => {
    return http
        .put<FeedbackReactionResponse>(`/companies/${companyId}/feedback/${feedbackId}`, data, {
            withCredentials: true,
        })
        .then((response) => response.data.data);
};

export const deleteUserFeedback = (companyId: number, feedbackId: number) => {
    return http.delete<FeedbackReactionResponse>(`/companies/${companyId}/feedback/${feedbackId}`, {
        withCredentials: true,
    });
};

export const respondToUserFeedback = (companyId: number, feedbackId: number, data: FeedbackRespond) => {
    return http
        .post(`/companies/${companyId}/feedback/${feedbackId}/respond`, data, {
            withCredentials: true,
        })
        .then((response) => response.data.data);
};
