import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DevicesIcon from '@mui/icons-material/Devices';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import LanguageIcon from '@mui/icons-material/Language';
import HouseIcon from '@mui/icons-material/House';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import BrushIcon from '@mui/icons-material/Brush';
import CarpenterIcon from '@mui/icons-material/Carpenter';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import BuildIcon from '@mui/icons-material/Build';
import ChairIcon from '@mui/icons-material/Chair';

import styled, { css } from 'styled-components';

const IconWrapper = styled.div<CustomStyles>`
    ${(props) => css`
        padding: 5px;
        margin-right: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        .MuiSvgIcon-root {
            width: ${props.width || '50px'};
            height: ${props.height || '50px'};
            color: ${props.color || props.theme.colors.brandRed};
        }
        ${!props.theme.screen.desktop &&
        css`
            width: ${props.width || '36px'};
            height: ${props.height || '36px'};
            .MuiSvgIcon-root {
                width: ${props.width || '36px'};
                height: ${props.height || '36px'};
                color: ${props.color || props.theme.colors.brandRed};
            }
        `}
    `}
`;

interface Props {
    id: number;
    customStyles?: CustomStyles;
}

interface CustomStyles {
    color?: string;
    width?: string;
    height?: string;
}

const icons: { [key: number]: JSX.Element } = {
    1: <DirectionsCarIcon />,
    2: <ChairIcon />,
    3: <AccountBalanceIcon />,
    4: <BusinessCenterIcon />,
    5: <DevicesIcon />,
    6: <ShoppingCartIcon />,
    7: <RestaurantIcon />,
    8: <MedicalServicesIcon />,
    9: <HouseIcon />,
    10: <LanguageIcon />,
    11: <BuildIcon />,
    12: <CarpenterIcon />,
    13: <BrushIcon />,
    14: <LocalShippingIcon />,
    15: <EmojiTransportationIcon />,
    16: <ElectricBoltIcon />,
    17: <AgricultureIcon />,
    18: <NewspaperIcon />,
};

export const IconById = ({ id, customStyles }: Props) => {
    return <IconWrapper {...customStyles}>{icons[id]}</IconWrapper>;
};
