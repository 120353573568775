import styled, { css } from 'styled-components';
import { IState } from '../../store';
import { BannerItem, Review } from '../../types/api';
import { useSelector } from 'react-redux';
import React from 'react';
import { AdCard } from '../AdCard/AdCard';
import { Stars } from '../Stars/Stars';
import { RecentReviewText } from './RecentReviewText';
import classNames from 'classnames';
import arrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const PersonInfo = styled.div`
    display: flex;
    align-items: center;
`;
const ReviewImg = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 50px;
    margin-right: 16px;
    margin-bottom: 24px;
`;
const Name = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 14px;
            line-height: 20px;
        `}
    `}
`;
const Company = styled.div`
    margin-bottom: 16px;
    font-family: 'Albert-Regular';
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
`;

const ReviewWrap = styled.div`
    ${(props) => css`
        border: 1px solid ${props.theme.colors.outlineLight};
        border-radius: 10px;
        padding: 24px 32px;
        display: flex;
        flex-direction: column;
        &.no-ads-slider {
            width: 100%;
        }
        &:hover {
            cursor: pointer;
            .button {
                font-weight: 700;
            }
        }
        ${props.theme.screen.tablet &&
        css`
            width: calc(50% - 64px);
        `}
        ${props.theme.screen.mobile &&
        css`
            width: calc(100% - 64px);
        `}
    `}
`;

const Button = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        color: ${props.theme.colors.brandRed};
        font-family: 'Albert-Regular';
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        margin-top: 24px;
    `}
`;

const Arrow = styled(arrowForwardIcon)`
    width: 18px !important;
    margin-left: 8px;
`;

const InfoWrap = styled.div`
    flex-grow: 1;
`;

type Props = {
    data: Review | BannerItem;
    index: number;
    noAdsSlider?: boolean;
    twoRows?: boolean;
};

export const RecentReviewCard = (props: Props) => {
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [name, lastName] = ('user' in props.data && (props.data.user?.name?.split(' ') || [])) || [];

    const reviewRating = (review: Review) => {
        const average =
            (review.ratingExpectation + review.ratingQpRatio + review.ratingQuality + review.ratingSpeed) / 4;
        return average;
    };

    const goToReview = (id: number) => {
        const state = { scrollTo: 'feedback' };
        navigate(`imones-kortele/${id}`, { state });
    };

    const adDimensions = () => {
        if (screenViewPort === 'desktop') {
            return {
                width: '100%',
                height: '246px',
                maxWidth: '396px',
            };
        }
        if (screenViewPort === 'tablet') {
            return {
                width: '100%',
                height: '187px',
                maxWidth: '360px',
            };
        }
        return {
            width: '100%',
            height: '260px',
            maxWidth: '328px',
        };
    };

    return (
        <React.Fragment key={props.index}>
            <React.Fragment>
                {'image' in props.data ? (
                    <AdCard
                        header={props.data.header}
                        adUrl={props.data.image}
                        adHref={props.data.url}
                        customDimensions={adDimensions()}
                        reviewAd={true}
                    />
                ) : (
                    <ReviewWrap
                        className={classNames({ 'no-ads-slider': props.noAdsSlider && !props.twoRows })}
                        onClick={() => goToReview((props.data as Review).company.id)}
                    >
                        <InfoWrap>
                            <PersonInfo>
                                {props.data.user?.profilePicture && <ReviewImg src={props.data.user.profilePicture} />}
                                {props.data.user?.name && (
                                    <Name>
                                        {name} {lastName && `${lastName[0]}.`}
                                    </Name>
                                )}
                            </PersonInfo>
                            <Company>{props.data.company.name}</Company>
                            {props.data.ratingSpeed && (
                                <Stars rating={reviewRating(props.data)} feedbackCount={0} noNumber={true} />
                            )}
                            <RecentReviewText feedback={props.data.feedback} />
                        </InfoWrap>
                        <Button className="button">
                            {t('show_full_review')}
                            <Arrow />
                        </Button>
                    </ReviewWrap>
                )}
            </React.Fragment>
        </React.Fragment>
    );
};
