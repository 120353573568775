import styled, { css } from 'styled-components';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { IState } from '../../store';
import classNames from 'classnames';

const InputField = styled(TextField)`
    ${(props) => css`
        width: 100%;
        .input-styles {
            font-family: 'Albert-Regular';
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            background-color: ${props.theme.colors.white};
            color: ${props.theme.colors.black};
            max-height: 48px;
            border-radius: 4px;
            padding: 26px 12px 4px;
            &.border {
                border: 1px solid ${props.theme.colors.outlineLight};
            }
            ${props.theme.screen.mobile &&
            css`
                padding: 22px 12px 4px;
                font-size: 14px;
            `}
        }
        #article_search {
            background-color: ${props.theme.colors.white};
            border-radius: 8px 0 0 8px;
            border: none;
            height: 22px;
            border: 1px solid ${props.theme.colors.green};
            border-right: none;
        }
        .label-styles {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: ${props.theme.colors.outlineAndPlaceholder} !important;
            ${props.theme.screen.mobile &&
            css`
                font-size: 14px;
            `}
        }
    `}
`;

interface Props {
    id: string;
    placeholder: string;
    class?: string;
    onChange: (value: any) => void;
    value?: any;
    useBorder?: boolean;
    type?: 'text' | 'number';
}

export const InputItem = (props: Props) => {
    const { screenViewPort } = useSelector((state: IState) => state.global);

    return (
        <>
            <InputField
                type={props.type || 'text'}
                autoComplete="off"
                id={props.id}
                label={props.placeholder}
                className={props.class}
                value={props.value}
                variant="filled"
                onChange={(e) => props.onChange(e.target.value)}
                inputProps={{
                    className: `${classNames('input-styles', {
                        border: props.useBorder,
                    })}`,
                }}
                InputLabelProps={{
                    className: `${classNames('label-styles', {
                        'mobile-class': screenViewPort === 'mobile',
                    })}`,
                }}
                size="small"
                sx={{
                    '& :after': { display: 'none' },
                    '& :before': { display: 'none' },
                    '& .MuiInputBase-root': { borderRadius: '8px', paddingLeft: '0px' },
                    '& .MuiInputLabel-root': { top: '5px' },
                    '& .MuiInputLabel-root.mobile-class': { top: '0px' },
                    '.desktop & .MuiInputLabel-root': { top: '4px' },
                }}
            />
        </>
    );
};
