import styled, { css } from 'styled-components';
import { ReactComponent as RedLogo } from '../../assets/images/Footer/red_logo.svg';
import FacebookIcon from '@mui/icons-material/FacebookOutlined';
import LinkedinIcon from '@mui/icons-material/LinkedIn';
import PhoneIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import visaLietuvaLogo from '../../assets/images/Footer/visa_lietuva.webp';
import lextLogo from '../../assets/images/Footer/lext.webp';
import numbersLogo from '../../assets/images/Footer/number_logo.webp';
import weDeliverLogo from '../../assets/images/Footer/we_deliver.webp';
import { useTranslation } from 'react-i18next';
import { ROUTE_NAMES } from '../../constants/routes';

const FooterContainer = styled.div`
    ${(props) => css`
        z-index: 9;
        position: relative;
        display: flex;
        justify-content: center;
        background-color: ${props.theme.colors.lightNeutral};
        font-family: 'Albert-Regular';
        font-size: 16px;
        line-height: 19px;
        color: ${props.theme.colors.black};
    `}
`;
const FooterContainerInner = styled.div`
    ${(props) => css`
        width: 1254px;
        padding: 40px 16px 16px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 14px;
            line-height: 20px;
            padding: 32px 16px 16px;
        `}
    `}
`;
const FooterTop = styled.div`
    ${(props) => css`
        display: flex;
        ${props.theme.screen.mobile &&
        css`
            flex-wrap: wrap;
        `}
    `}
`;
const MainInfo = styled.div`
    ${(props) => css`
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 30px;
        width: 68%;
        ${!props.theme.screen.desktop &&
        css`
            width: 90%;
            gap: 16px;
        `}
        ${props.theme.screen.mobile &&
        css`
            flex-wrap: wrap;
        `}
    `}
`;
const RedIcon = styled(RedLogo)`
    ${(props) => css`
        padding-right: 34px;
        min-width: 40px;
        ${!props.theme.screen.desktop &&
        css`
            padding-right: 28px;
        `}
    `}
`;
const MainInfoCol = styled.div`
    ${(props) => css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        margin-right: auto;
        .info-icon {
            padding-right: 16px;
        }
        ${!props.theme.screen.desktop &&
        css`
            margin-right: 0px;
            &.email-phone {
                gap: 16px;
            }
        `}
        ${props.theme.screen.mobile &&
        css`
            &.first-col {
                padding-top: 8px;
            }
        `}
    `}
`;
const AnchorTag = styled.a`
    ${(props) => css`
        display: flex;
        align-items: center;
        text-decoration: none;
        color: ${props.theme.colors.black};
    `}
`;
const SocialIcons = styled.div`
    ${(props) => css`
        display: flex;
        padding-top: 13px;
        .social-icon {
            padding-right: 16px;
            width: 36px;
            height: 36px;
            color: ${props.theme.colors.darkNeutral};
        }
        ${!props.theme.screen.desktop &&
        css`
            padding-top: 0px;
        `}
        ${props.theme.screen.tablet &&
        css`
            .social-icon {
                width: 24px;
                height: 24px;
            }
        `}
    `}
`;
const LogosContainer = styled.div`
    ${(props) => css`
        width: 32%
            ${!props.theme.screen.desktop &&
            css`
                width: 10%;
            `}
            ${props.theme.screen.mobile &&
            css`
                width: 100%;
            `};
    `}
`;
const Logos = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        ${props.theme.screen.tablet &&
        css`
            justify-content: space-between;
        `}
        .lext-logo {
            padding: 0 16px;
        }
        ${props.theme.screen.tablet &&
        css`
            flex-direction: column;
            align-items: flex-start;
            height: 92px;
            .lext-logo {
                padding: 0;
                width: 44px;
                height: 21px;
            }
            .visa-lietuva-logo {
                width: 75px;
                height: 14px;
            }
        `}
        ${props.theme.screen.mobile &&
        css`
            .lext-logo {
                width: 68px;
                height: 44px;
            }
            .visa-lietuva-logo {
                width: 132px;
                height: 26px;
            }
        `}
    `}
`;
const Divider = styled.div`
    ${(props) => css`
        width: 100%;
        height: 1px;
        background-color: ${props.theme.colors.lightNeutral};
        margin: 24px 0;
        ${props.theme.screen.tablet &&
        css`
            background-color: transparent;
            margin: 36px 0;
        `}
        &.mobile-divider {
            display: none;
            ${props.theme.screen.mobile &&
            css`
                display: block;
            `}
        }
    `}
`;
const LogoText = styled.div`
    ${(props) => css`
        display: flex;
        .we-deliver-logo {
            width: 100px;
            height: 16px;
            padding-right: 16px;
        }
        ${props.theme.screen.tablet &&
        css`
            left: 16px;
            position: absolute;
            .we-deliver-logo {
                width: 63px;
                height: 10px;
            }
        `}
        ${props.theme.screen.mobile &&
        css`
            .we-deliver-logo {
                width: 90px;
                height: 14px;
            }
        `}
    `}
`;
const FooterDivider = styled.div`
    ${(props) => css`
        width: 100%;
        height: 1px;
        background-color: ${props.theme.colors.neutral};
        margin: 24px 0 8px 0;
        ${!props.theme.screen.desktop &&
        css`
            margin: 20px 0 8px 0;
        `}
    `}
`;
const Paragraph = styled.p`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 120%;
        color: ${props.theme.colors.outlineAndPlaceholder};
        margin: 0;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 10px;
            line-height: 12px;
        `}
    `}
`;
const FirstThreeCol = styled.div`
    ${(props) => css`
        width: 90%;
        margin-right: 10%;
        display: flex;
        justify-content: space-between;
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
            width: 100%;
            margin-right: 0;
        `}
    `}
`;
const FirstTwoCol = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: space-between;
        width: 100%;
        ${!props.theme.screen.desktop &&
        css`
            width: 55%;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
        `}
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column-reverse;
        `}
    `}
`;

export const Footer = () => {
    const { t } = useTranslation();
    return (
        <FooterContainer>
            <FooterContainerInner>
                <FooterTop>
                    <MainInfo>
                        <RedIcon />
                        <FirstThreeCol>
                            <FirstTwoCol>
                                <MainInfoCol className="first-col">
                                    <AnchorTag href="https://wedeliver.lt/lt/privatumo-politika/" target="_blank">
                                        {t('privacy_policy')}
                                    </AnchorTag>
                                    <AnchorTag href={'https://klientams.imones.lt/en/legal'} target="_blank">
                                        {t('rules')}
                                    </AnchorTag>
                                    <AnchorTag>{t('FAQ')}</AnchorTag>
                                    <AnchorTag href={ROUTE_NAMES.SEARCH}>{t('company_catalogue')}</AnchorTag>
                                    <AnchorTag href={ROUTE_NAMES.BUSINESS_LIST}>{t('company_search')}</AnchorTag>
                                </MainInfoCol>
                                <MainInfoCol>
                                    <AnchorTag href={'https://wedeliver.lt/lt/'} target="_blank">
                                        {t('about_us')}
                                    </AnchorTag>
                                    <AnchorTag href={'https://wedeliver.lt/lt/kontaktai/'} target="_blank">
                                        {t('contacts')}
                                    </AnchorTag>
                                    <AnchorTag>{t('articles')}</AnchorTag>
                                    <AnchorTag href={ROUTE_NAMES.PRODUCTS} target="_blank">
                                        {t('for_business')}
                                    </AnchorTag>
                                </MainInfoCol>
                            </FirstTwoCol>
                            <Divider className="mobile-divider" />
                            <MainInfoCol className="email-phone">
                                <AnchorTag href="tel:+37061387583">
                                    <PhoneIcon className="info-icon" />
                                    +370 613 87583
                                </AnchorTag>
                                <AnchorTag href="mailto:info@wedeliver.lt">
                                    <EmailIcon className="info-icon" />
                                    info@wedeliver.lt
                                </AnchorTag>
                                <SocialIcons>
                                    <AnchorTag href="https://www.facebook.com/Wedeliveragency" target="_blank">
                                        <FacebookIcon className="social-icon" />
                                    </AnchorTag>
                                    <AnchorTag
                                        href="https://www.linkedin.com/company/we-deliver-agency/?originalSubdomain=lt"
                                        target="_blank"
                                    >
                                        <LinkedinIcon className="social-icon" />
                                    </AnchorTag>
                                </SocialIcons>
                            </MainInfoCol>
                            <Divider className="mobile-divider" />
                        </FirstThreeCol>
                    </MainInfo>
                    <LogosContainer>
                        <Logos>
                            <img src={visaLietuvaLogo} alt="visa-lietuva-logo" className="visa-lietuva-logo" />
                            <img src={lextLogo} alt="lext-logo" className="lext-logo" />
                        </Logos>
                        <Divider />
                        <LogoText>
                            <img src={weDeliverLogo} alt="we-deliver" className="we-deliver-logo" />
                            <Paragraph>{t('not_responsible_footer_text')}</Paragraph>
                        </LogoText>
                    </LogosContainer>
                </FooterTop>
                <FooterDivider />
                <Paragraph>{t('all_rights_secure')}</Paragraph>
            </FooterContainerInner>
        </FooterContainer>
    );
};
