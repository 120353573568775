import http from './client/http';

const baseUrl = process.env.REACT_APP_BASE_API_URL || 'https://imonesapi.tandemumstage.site';

export const socialsProviderLogin = (token: string, provider: string) =>
    http.get(`${baseUrl}/api/oauth/${provider}/token?token=${token}`).then((response) => response.data);

export const getUserInformation = () =>
    http.get(`${baseUrl}/api/users`, { withCredentials: true }).then((response) => response.data);

export const refreshToken = () =>
    http.get(`${baseUrl}/api/users/refresh_token`, { withCredentials: true }).then((response) => response.data);

export const logoutUser = () =>
    http.get(`${baseUrl}/api/users/logout`, { withCredentials: true }).then((response) => response.data);
