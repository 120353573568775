import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CategoryTopics } from '../../components/CategoryTopics/CategoryTopics';

import { IState } from '../../store';

import styled, { css } from 'styled-components';
import { ICategory } from '../../store/categories/types';
import { useParams, matchRoutes, useLocation } from 'react-router-dom';
import { AutocompleteInput } from '../../components/SearchBar/AutocompleteInput';
import { HorizontalBanner } from '../../components/HorizontalBanner/HorizontalBanner';

import SearchIcon from '@mui/icons-material/Search';
import { Topic } from '../../types/api';
import { RecentReviews } from '../../components/RecentReviews/RecentReview';
import { routes } from '../../constants/routes';

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    max-width: 1254px;
    margin: 0 16px 89px;
`;

const CategoryTopicsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const Heading = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 32px;
        font-weight: 700;
        line-height: 38px;
        color: ${props.theme.colors.black};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 20px;
            line-height: 24px;
        `}
    `}
`;

const HeaderWrapper = styled.div`
    ${(props) => css`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 30px;
        margin-top: 20px;
        ${!props.theme.screen.desktop &&
        css`
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
        `}
    `}
`;

const InputWrapper = styled.form`
    ${(props) => css`
        display: flex;
        width: 100%;
        border-radius: 100px;
        background-color: ${props.theme.colors.white};
        border: 1px solid ${props.theme.colors.outlineLight};
        color: ${props.theme.colors.white};
        font-family: 'Albert-Regular';
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
    `}
`;
const SearchIconWrapper = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${props.theme.colors.brandRed};
        padding: 12px 24px;
        margin: 6px;
        border-radius: 100px;
        ${!props.theme.screen.desktop &&
        css`
            padding: 12px;
        `}
    `}
`;
const SearchButton = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.brandRed};
    `}
`;

const BusinessListPage = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        &.reviews {
            border-top: 1px solid ${props.theme.colors.outlineLight};
            width: 100%;
            padding-top: 40px;
            ${!props.theme.screen.desktop &&
            css`
                padding-top: 32px;
            `}
        }
    `}
`;
const CardContainer = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 1254px;
        padding: 0 16px;
        ${!props.theme.screen.desktop &&
        css`
            grid-template-columns: 64.5% auto;
            gap: 16px;
        `}
        ${props.theme.screen.mobile &&
        css`
            grid-template-columns: 1fr;
        `}
    `}
`;

export const AllCategories = () => {
    const { t } = useTranslation();
    const urlParams = useParams();
    const location = useLocation();

    const { categoriesList } = useSelector((state: IState) => state.categories);
    const { screenViewPort, gateIsActive, bannersList } = useSelector((state: IState) => state.global);

    const [searchValue, setSearchValue] = useState<string>('');
    const [filteredCategoriesList, setFilteredCategoriesList] = useState<ICategory[]>([]);

    useEffect(() => {
        if (urlParams?.categoryId) {
            const categories = categoriesList.filter((category) => category.id === Number(urlParams.categoryId));
            setFilteredCategoriesList(categories);
        } else {
            setFilteredCategoriesList(categoriesList);
        }
    }, [categoriesList, urlParams?.categoryId]);

    const filterCategoriesList = () => {
        if (searchValue && searchValue.length >= 3) {
            const filteredCategories: ICategory[] = [];
            const categories = urlParams?.categoryId
                ? categoriesList.filter((category) => category.id === Number(urlParams.categoryId))
                : categoriesList;
            categories.forEach((category) => {
                const filteredTopics = category.topics.filter((topic) => {
                    const hasTopic = topic.name.toLowerCase().includes(searchValue.toLowerCase());
                    if (!hasTopic) {
                        const heads = topic.heads.filter((head) =>
                            head.name.toLowerCase().includes(searchValue.toLowerCase())
                        );
                        if (heads.length > 0) {
                            return true;
                        }
                    }
                    return hasTopic;
                });
                if (filteredTopics.length > 0) {
                    filteredCategories.push({ ...category, topics: filteredTopics });
                }
            });
            setFilteredCategoriesList(filteredCategories);
            return;
        }
        setFilteredCategoriesList(categoriesList.filter((category) => category.id === Number(urlParams.categoryId)));
    };

    const topicsAndHeadsList = () => {
        const topicsAndHeads: string[] = [];
        categoriesList
            .filter((category) => category.id === Number(urlParams.categoryId))
            .forEach((category) => {
                category.topics.forEach((topic) => {
                    topicsAndHeads.push(topic.name);
                    topicsAndHeads.push(...topic.heads.map((head) => head.name));
                });
            });
        return topicsAndHeads;
    };

    const matchRoute = () => {
        const match = matchRoutes(allowedGateRoutes, location);
        return !!match;
    };

    const allowedGateRoutes = routes.filter((route) => !route.disableGate);

    const isGateAllowed = gateIsActive && screenViewPort === 'desktop' && matchRoute();

    const topicsArray = () => {
        const arrayWithTopics: Topic[] = [];
        filteredCategoriesList.forEach((category) => {
            let topics = category.topics.map((topic) => ({
                ...topic,
                categoryId: category.id,
            }));
            arrayWithTopics.push(...topics);
        });
        return arrayWithTopics;
    };

    return (
        <Content>
            <MainWrapper>
                <HeaderWrapper>
                    <Heading>{t('companies_catalogue')}</Heading>
                    {screenViewPort === 'desktop' && (
                        <HorizontalBanner inElement={true} isGateAllowed={isGateAllowed} />
                    )}
                    <InputWrapper
                        onSubmit={(e) => {
                            e.preventDefault();
                            filterCategoriesList();
                        }}
                    >
                        <AutocompleteInput
                            class={'category-search'}
                            onChange={(value) => setSearchValue(value)}
                            name={t('category_search_placeholder')}
                            options={topicsAndHeadsList()}
                            id="topic_search"
                        />
                        <SearchIconWrapper onClick={() => filterCategoriesList()}>
                            {screenViewPort === 'desktop' && <SearchButton>{t('search')}</SearchButton>}
                            {screenViewPort !== 'desktop' && <SearchIcon />}
                        </SearchIconWrapper>
                    </InputWrapper>
                </HeaderWrapper>
                <Wrapper>
                    <CategoryTopicsWrapper>
                        {filteredCategoriesList.length > 0 && (
                            <React.Fragment key={urlParams.categoryId}>
                                <CategoryTopics
                                    key={urlParams.categoryId}
                                    categoryId={Number(urlParams.categoryId)}
                                    topics={topicsArray()}
                                />
                            </React.Fragment>
                        )}
                    </CategoryTopicsWrapper>
                </Wrapper>
            </MainWrapper>
            <BusinessListPage className="reviews">
                <CardContainer>
                    <RecentReviews noAdsSlider={true} twoRows={true} />
                </CardContainer>
            </BusinessListPage>
        </Content>
    );
};
