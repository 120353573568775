import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { TopicHeads } from './TopicHeads';
import { AdCard } from '../AdCard/AdCard';

import { Topic } from '../../types/api';
import { IState } from '../../store';

import styled, { css } from 'styled-components';
import { BannerCategorySupporter } from '../BannerCategorySupporter/BannerCategorySupporter';

const Content = styled.div`
    ${(props) =>
        css`
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 32px 24px;
            ${props.theme.screen.tablet &&
            css`
                grid-template-columns: 1fr 1fr;
            `}
            ${props.theme.screen.mobile &&
            css`
                grid-template-columns: 1fr;
            `}
        `}
`;

const CardWithHeads = styled.div`
    ${(props) =>
        css`
            display: flex;
            flex-direction: column;
            border: 1px solid ${props.theme.colors.outlineLight};
            gap: 8px;
            padding: 24px 24px 16px;
            ${!props.theme.screen.desktop &&
            css`
                gap: 20px;
            `}
        `}
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

const Heading = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
        color: ${(props) => props.theme.colors.black};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 18px;
            line-height: 22px;
        `}
    `}
`;

const Divider = styled.div`
    ${(props) => css`
        height: 1px;
        background-color: ${props.theme.colors.outlineLight};
        margin: 24px 0;
        ${!props.theme.screen.desktop &&
        css`
            margin: 16px 0 22px;
        `}
    `}
`;

interface Props {
    topics: Topic[];
    categoryId: number;
}

export const CategoryTopics = (props: Props) => {
    const { bannersList, screenViewPort, bannersFilteredByCategory } = useSelector((state: IState) => state.global);
    const navigate = useNavigate();
    const urlParams = useParams();
    const [bannerPositions, setBannerPositions] = useState<number[]>([]);

    const navigateToBusinessList = (categoryId?: number, topicId?: number, name?: string) => {
        const state = { topicOrHeadName: name };
        if (urlParams.categoryId) {
            navigate(`${topicId}`, { state });
            return;
        }
        navigate(`${categoryId}/${topicId}`, { state });
        return;
    };

    const capitalizeWords = (str: string) => {
        const string = str.toLowerCase();
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    useEffect(() => {
        setBannerPositions(bannerPosition());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersList]);

    const bannerPosition = () => {
        const topicsLength = props.topics.length;
        let position = [];

        if (topicsLength >= 32) {
            position = [4, 7, 13, 18, 25, 32];
        } else {
            const stepSize = 3;
            position = [stepSize, stepSize * 2, stepSize * 3, stepSize * 4, stepSize * 5, stepSize * 6];
        }

        if (bannersFilteredByCategory.WLR.length > 0) {
            position.unshift(0);
        }

        return position;
    };

    const getBannerIndex = (index: number) => {
        return bannerPositions.findIndex((position) => position === index);
    };

    const adDimensions = () => {
        if (screenViewPort === 'desktop') {
            return {
                width: '400px',
                height: '389px',
                maxWidth: '400px',
            };
        }
        if (screenViewPort === 'tablet') {
            return {
                width: '100%',
                height: '367px',
                maxWidth: '360px',
            };
        }
        return {
            width: '100%',
            height: '260px',
            maxWidth: '328px',
        };
    };

    const formattedBanners = () => {
        const list = [];

        if (bannersFilteredByCategory.WLR.length > 0) {
            list.push(bannersFilteredByCategory.WLR[0]);
        }

        if (bannersList.WBQ.length > 0) {
            list.push(bannersList.WBQ[0]);
        }
        if (bannersList.WBM.length > 0) {
            list.push(...bannersList.WBM);
        }
        return list;
    };

    return (
        <Content>
            {props.topics.length > 0 &&
                props.topics.map((topic, index) => {
                    const bannerIndex = getBannerIndex(index);
                    const banners = formattedBanners();
                    const banner = banners[bannerIndex];

                    return (
                        <React.Fragment key={index}>
                            {bannersFilteredByCategory.WLR.length > 0 &&
                                bannerPositions.includes(index) &&
                                banner &&
                                index === 0 && (
                                    <BannerCategorySupporter
                                        banner={banner}
                                        header={banner.header}
                                        adHref={banner.url}
                                        adUrl={banner.image}
                                        customDimensions={adDimensions()}
                                    />
                                )}
                            {bannerPositions.includes(index) && banner && banner.product !== 'WLR' && index !== 0 && (
                                <AdCard
                                    header={banner.header}
                                    adHref={banner.url}
                                    adUrl={banner.image}
                                    customDimensions={adDimensions()}
                                />
                            )}
                            <CardWithHeads key={topic.id}>
                                <HeaderWrapper
                                    onClick={() => navigateToBusinessList(topic.categoryId, topic.id, topic.name)}
                                >
                                    <Heading>{capitalizeWords(topic.name)}</Heading>
                                </HeaderWrapper>
                                <Divider />
                                <TopicHeads topic={topic} topicId={topic.id} />
                            </CardWithHeads>
                        </React.Fragment>
                    );
                })}
        </Content>
    );
};
