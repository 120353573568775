import { useCallback, useState } from 'react';
import { LoginSocialFacebook, IResolveParams } from 'reactjs-social-login';
import { FacebookLoginButton, GoogleLoginButton, LinkedInLoginButton } from 'react-social-login-buttons';
import { socialsProviderLogin } from '../../services/auth.service';
import { Backdrop, Box, Fade, Modal } from '@mui/material';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/user/user.slice';
import { AUTH_TOKEN_KEY } from '../../commons/constants';
import localStorage from '../../commons/localStorage';
import { setLoginModalOpen } from '../../store/global.slice';
import { useGoogleLogin } from '@react-oauth/google';
import { LinkedIn } from 'react-linkedin-login-oauth2';

const ModalBody = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: 'Albert-Regular';
`;

const Heading = styled.div`
    font-size: 18px;
    ${(props) => props.theme.colors.secondaryBlack};
    padding: 32px 0 0 24px;
`;

const Dividers = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-end;
`;

const LoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
`;

const Divider = styled.div`
    width: 100%;
    height: 2px;
    ${(props) => css`
        background-color: ${props.theme.colors.brandRed};
        &.right {
            height: 1px;
            background-color: ${props.theme.colors.outlineLight};
        }
    `}
`;
const SelfService = styled.div`
    ${(props) => css`
        display: flex;
        text-decoration: none;
        align-items: center;
        justify-content: center;
        margin: 5px;
        background-color: ${props.theme.colors.brandRed};
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px;
        color: rgb(255, 255, 255);
        cursor: pointer;
        font-size: 19px;
        margin: 5px;
        padding: 0px 10px;
        height: 50px;
    `}
`;
const ServiceButton = styled.div`
    font-size: 19px;
`;

const modalBoxStyles = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 458,
    width: '100%',
    border: '1px solid #e1e2e1',
    borderRadius: '10px',
    background: '#fff',
};

interface Props {
    isLoginOpen: boolean;
    selectedAction: string;
}
const SSODetails = {
    client_id: process.env.REACT_APP_SSO_CLIENT_ID || '',
    grant_type: 'authorization_code',
    scope: 'profile',
    redirect_uri: `${window.location.origin}/odoo`,
    response_type: 'json',
    code_challenge: 'a',
    code_challenge_method: 'b',
};

export const LoginModal = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    const selfService = () => {
        const query = Object.keys(SSODetails)
            .map((key) => key + '=' + SSODetails[key as keyof typeof SSODetails])
            .join('&');
        window.location.replace(`https://klientams.imones.lt/oauth/authorize?${query}`);
    };

    const onLoginStart = useCallback(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onLoginSuccess = (accessToken: string, provider: string) => {
        if (loading) {
            return;
        }
        setLoading(true);
        socialsProviderLogin(accessToken, provider)
            .then((resp) => {
                localStorage.put(AUTH_TOKEN_KEY, resp.data.token);
                dispatch(setUser(resp.data.user));
                dispatch(setLoginModalOpen(false));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const googleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            onLoginSuccess(tokenResponse.access_token, 'google');
        },
    });

    return (
        <Modal
            open={props.isLoginOpen}
            onClose={() => dispatch(setLoginModalOpen(false))}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={props.isLoginOpen}>
                <ModalBody sx={modalBoxStyles}>
                    <Heading>{t(props.selectedAction)}</Heading>
                    <Dividers>
                        <Divider />
                        <Divider className="right" />
                    </Dividers>
                    <LoginWrapper>
                        <GoogleLoginButton onClick={() => googleLogin()}>
                            {t(`${props.selectedAction}_with`, { provider: 'Google' })}
                        </GoogleLoginButton>
                        <LoginSocialFacebook
                            isOnlyGetToken
                            appId={process.env.REACT_APP_FB_APP_ID || ''}
                            onLoginStart={onLoginStart}
                            onResolve={({ provider, data }: IResolveParams) => {
                                onLoginSuccess(data?.accessToken, provider);
                            }}
                            onReject={(err) => {
                                console.log('error: ', err);
                            }}
                        >
                            <FacebookLoginButton>
                                {t(`${props.selectedAction}_with`, { provider: 'Facebook' })}
                            </FacebookLoginButton>
                        </LoginSocialFacebook>
                        <LinkedIn
                            clientId={process.env.REACT_APP_LINKEDIN_APP_ID || ''}
                            onError={(err) => console.log('error: ', err)}
                            onSuccess={(code) => onLoginSuccess(code, 'linkedin')}
                            redirectUri={`${window.location.origin}/linkedin`}
                            scope="r_liteprofile r_emailaddress"
                        >
                            {({ linkedInLogin }) => (
                                <LinkedInLoginButton onClick={linkedInLogin}>
                                    {t(`${props.selectedAction}_with`, { provider: 'LinkedIn' })}
                                </LinkedInLoginButton>
                            )}
                        </LinkedIn>
                        <SelfService onClick={() => selfService()}>
                            <ServiceButton>{t('self_service')}</ServiceButton>
                        </SelfService>
                    </LoginWrapper>
                </ModalBody>
            </Fade>
        </Modal>
    );
};
