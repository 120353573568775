import { useTranslation } from 'react-i18next';
import { Review } from '../../../../types/api';
import { Backdrop, Box, Fade, Modal } from '@mui/material';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../store/global.slice';
import { deleteUserFeedback } from '../../../../services/reviews.services';

const ModalBody = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 40px;
    font-family: 'Albert-Regular';
    padding: 24px;
`;

const Heading = styled.div`
    font-size: 18px;
    ${(props) => props.theme.colors.secondaryBlack};
    text-align: center;
    font-weight: 700;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
`;

const ActionButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    border-radius: 10px;
    cursor: pointer;
    ${(props) => css`
        border: 1px solid ${props.theme.colors.outlineAndPlaceholder};
        &:hover {
            background: ${props.theme.colors.outlineAndPlaceholder};
            color: ${props.theme.colors.white};
        }
        &.delete {
            color: ${props.theme.colors.brandRed};
            background: ${props.theme.colors.white};
            border: 1px solid ${props.theme.colors.brandRed};
            &:hover {
                background: ${props.theme.colors.brandRed};
                color: ${props.theme.colors.white};
            }
        }
    `}
`;

const modalBoxStyles = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 458,
    width: 'calc(90% - 48px)',
    border: '1px solid #e1e2e1',
    borderRadius: '10px',
    background: '#fff',
};

interface Props {
    companyId: number;
    feedback: Review;
    show: boolean;
    onClose: (value: { [key: string]: boolean }) => void;
}

export const DeleteModal = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const deleteFeedback = () => {
        dispatch(setLoading(true));
        props.onClose({ delete: false });
        deleteUserFeedback(props.companyId, props.feedback.id)
            .then(() => {
                window.location.reload();
            })
            .finally(() => {
                dispatch(setLoading(false));
            });
    };

    return (
        <Modal
            open={props.show}
            onClose={() => props.onClose({ delete: false })}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={props.show}>
                <ModalBody sx={modalBoxStyles}>
                    <Heading>{t('delete_confirmation_text')}</Heading>
                    <ButtonWrapper>
                        <ActionButton className="delete" onClick={() => deleteFeedback()}>
                            {t('yes')}
                        </ActionButton>
                        <ActionButton onClick={() => props.onClose({ delete: false })}>{t('no')}</ActionButton>
                    </ButtonWrapper>
                </ModalBody>
            </Fade>
        </Modal>
    );
};
