import { Box, Modal } from '@mui/material';

import styled from 'styled-components';

const PreviewImage = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
`;

interface Props {
    url: string | null;
    onClose: () => void;
}

export const ImageView = (props: Props) => {
    return (
        <Modal open={!!props.url} onClose={() => props.onClose()}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '10px',
                }}
            >
                {props.url && <PreviewImage alt="view" src={props.url} onClick={() => props.onClose()} />}
            </Box>
        </Modal>
    );
};
