import { slide as Menu } from 'react-burger-menu';
import styled, { css } from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { LoginModal } from '../LoginModal/LoginModal';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../store';
import { setLoginModalOpen } from '../../store/global.slice';
import { UserProfile } from './UserProfile';
import { LanguageSwitcher } from './LanguageSwitcher';
import { ReactComponent as LogoRed } from '../../assets/images/header_logo_red.svg';

const BurgerMenuContainer = styled.div<{ isHome: boolean }>`
    ${(props) => css`
        margin-left: auto;
        .bm-menu-wrap {
            top: 0px;
        }
        .bm-burger-button {
            position: fixed;
            width: 18px;
            height: 12px;
            right: 16px;
            top: 31px;
        }
        .bm-overlay {
            top: 0px;
            left: 0px;
            background-color: transparent !important;
        }
        .bm-menu {
            background: ${props.theme.colors.white};
            font-size: 1.15em;
            border: 1px solid ${props.theme.colors.outlineLight};
        }
        .bm-item {
            padding: 16px 16px 0 16px;
            color: ${props.theme.colors.black};
            .languages {
                height: 20px;
                font-family: 'Albert-Regular';
                font-size: 15px;
                font-weight: 500;
                line-height: 18px;
            }
            ${props.theme.screen.desktop &&
            css`
                &:hover {
                    background-color: ${props.theme.colors.greyBg};
                }
            `}
        }
        .bm-item:after {
            content: '';
            display: block;
            width: 100%;
            padding-top: 16px;
            border-bottom: 1px solid ${props.theme.colors.outlineLight};
        }
        .bm-cross,
        .bm-burger-bars {
            background-color: ${props.isHome ? `${props.theme.colors.white}` : `${props.theme.colors.black}`};
        }
        .bm-cross.cross-line {
            background-color: ${props.theme.colors.black};
            width: 2px !important;
            height: 17px !important;
        }
    `}
`;
const Languages = styled.div`
    ${(props) =>
        css`
            color: ${props.theme.colors.outlineAndPlaceholder};
        `}
`;

const buttons = css`
    font-family: 'Albert-Regular';
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-decoration: none;
`;
const ButtonNav = styled(NavLink)`
    ${buttons}
`;

const ActionButton = styled.div`
    ${(props) => css`
        padding: 16px 16px 0px;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: ${props.theme.colors.black};
        &:after {
            content: '';
            display: block;
            width: 100%;
            padding-top: 16px;
            border-bottom: 1px solid ${props.theme.colors.outlineLight};
        }
    `}
`;

const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const ProfileWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const HeaderLogoRed = styled(LogoRed)``;

export const BurgerMenu = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loginModalOpen } = useSelector((state: IState) => state.global);
    const { me } = useSelector((state: IState) => state.user);

    const [isOpen, setOpen] = useState(false);
    const [userAction, setUserAction] = useState<string>('');

    const closeSideBar = () => {
        setOpen(false);
    };

    const handleAction = (action: string) => {
        setUserAction(action);
        dispatch(setLoginModalOpen(true));
        closeSideBar();
    };

    return (
        <BurgerMenuContainer isHome={props.isHome}>
            <Menu
                right
                width={'100%'}
                crossClassName={'cross-line'}
                menuClassName={'burger-wrapper'}
                isOpen={isOpen}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
            >
                <div>
                    <HeaderLogoRed />
                </div>
                <Languages>
                    <ItemWrapper className="languages">
                        <LanguageSwitcher />
                    </ItemWrapper>
                </Languages>
                {/* <ButtonNav id="products" to={ROUTE_NAMES.PRODUCTS} onClick={closeSideBar}>
                    {t('products')}
                </ButtonNav> */}
                <ButtonNav id="useful-info" to="/about" onClick={closeSideBar}>
                    {t('useful_information')}
                </ButtonNav>
                {!me ? (
                    <>
                        <ActionButton onClick={() => handleAction('login')}>{t('login')}</ActionButton>
                        <ActionButton onClick={() => handleAction('register')}>{t('register')}</ActionButton>
                    </>
                ) : (
                    <ProfileWrapper>
                        <UserProfile closeMenu={() => closeSideBar()} />
                    </ProfileWrapper>
                )}
            </Menu>
            {loginModalOpen && <LoginModal isLoginOpen={loginModalOpen} selectedAction={userAction || 'login'} />}
        </BurgerMenuContainer>
    );
};
