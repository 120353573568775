import styled, { css } from 'styled-components';
import globe from '../../../assets/images/SlidingCardIcons/globe.svg';
import letter from '../../../assets/images/SlidingCardIcons/letter_icon.svg';
import phone from '../../../assets/images/SlidingCardIcons/phone_icon.svg';
import pin from '../../../assets/images/SlidingCardIcons/pin_icon.svg';
import { ReactComponent as Facebook } from '../../../assets/images/SlidingCardIcons/facebook_icon.svg';
import { ReactComponent as Linkedin } from '../../../assets/images/SlidingCardIcons/linkedIn_icon.svg';
import { ReactComponent as Google } from '../../../assets/images/SlidingCardIcons/social_icon.svg';
import { ReactComponent as Twitter } from '../../../assets/images/SlidingCardIcons/twitter_icon.svg';
import { ReactComponent as Instagram } from '../../../assets/images/SlidingCardIcons/instagram_icon.svg';
import { ReactComponent as People } from '../../../assets/images/SlidingCardIcons/people_icon.svg';
import { ReactComponent as Euro } from '../../../assets/images/SlidingCardIcons/euro_icon.svg';
import { ReactComponent as Calendar } from '../../../assets/images/SlidingCardIcons/calendar_icon.svg';
import { ReactComponent as Hammer } from '../../../assets/images/SlidingCardIcons/hammer_icon.svg';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';
import { CompanyData, SocialNetworks } from '../../../types/api';
import { NumberFormatter } from '../../Formatters/NumberFormatter';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const Card = styled.div`
    ${(props) => css`
        height: fit-content;
        position: sticky;
        top: 75px;
        &.gateIsActive {
            ${props.theme.screen.desktop &&
            css`
                top: 229px;
            `}
        }
        &.mobile-off {
            position: relative;
        }
    `}
`;
const SlidingCard = styled.div`
    ${(props) => css`
        padding: 24px;
        background-color: ${props.theme.colors.white};
        border: 1px solid ${props.theme.colors.greyBg};
        box-shadow: 0px 4px 10px 0px ${props.theme.colors.shadow};
        height: fit-content;
        border-radius: 10px;
        ${!props.theme.screen.desktop &&
        css`
            padding: 18px 16px;
        `}
    `}
`;
const WebLinkContainer = styled.a`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        display: flex;
        text-decoration: none;
        color: ${props.theme.colors.black};
        justify-content: space-between;
        align-items: center;
        margin: 0;
        overflow-wrap: anywhere;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 14px;
            line-height: 20px;
        `}
    `}
`;
const ContainerWithIcon = styled(WebLinkContainer)`
    ${(props) => css`
        font-weight: 400;
        overflow-wrap: anywhere;
        text-decoration: none;
        color: ${props.theme.colors.black};
        cursor: pointer;
    `}
`;
const Icons = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        .social-icon {
            width: 36px;
            height: 36px;
            ${!props.theme.screen.desktop &&
            css`
                width: 24px;
                height: 24px;
            `}
        }
        &.space-out {
            display: flex;
            justify-content: space-between;
        }
    `}
`;
const Divider = styled.div`
    ${(props) => css`
        height: 1px;
        width: 100%;
        background-color: ${props.theme.colors.outlineLight};
        margin: 24px 0;
        ${!props.theme.screen.desktop &&
        css`
            margin: 18px 0;
            &.mobile-top {
                margin: 32px 0 16px 0;
            }
            &.mobile-bot {
                margin: 16px 0 32px 0;
            }
        `}
    `}
`;

const AditionalContainer = styled.div`
    ${(props) => css`
        border: 1px solid ${props.theme.colors.outlineLight};
        background-color: ${props.theme.colors.white};
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        margin-top: 16px;
        ${!props.theme.screen.desktop &&
        css`
            padding: 16px 24px;
        `}
    `}
`;
const LogoText = styled.p`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 14px;
            line-height: 20px;
        `}
    `}
`;
const SmallText = styled.p`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        margin: 0 0 7px 0;
        color: ${props.theme.colors.outlineAndPlaceholder};
        &.desktop-margin {
            margin: 0 0 8px 0;
        }
    `}
`;
const CenterContainer = styled.div`
    ${(props) => css`
        border: 1px solid ${props.theme.colors.outlineLight};
        border-radius: 10px;
        padding: 16px;
        margin-top: 16px;
        display: block;
        background-color: ${props.theme.colors.white};
    `}
`;
const TextUnderSmall = styled.div`
    font-family: 'Albert-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    .icon {
        min-width: 24px;
        margin-right: 4px;
    }
`;
const Row = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Logo = styled.img`
    max-width: 66px;
    max-height: 66px;
`;

const Icon = styled.img`
    ${(props) => css`
        margin-left: 8px;
        ${props.theme.screen.mobile &&
        css`
            height: 24px;
            min-width: 24px;
        `}
    `}
`;

const OneOfFour = styled.div`
    width: 48%;
`;

const SocialIcon = styled.a``;

type Props = {
    data: CompanyData;
};

export const RightSideSlidingCard = (props: Props) => {
    const { screenViewPort, gateIsActive } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();

    const showInMapClicked = (lat: number, long: number) => {
        if (lat && long) {
            window.open('https://maps.google.com?q=' + lat + ',' + long);
        }
    };

    const socialIconsFilter = () => {
        const socials: { [key: string]: JSX.Element } = {
            facebook: <Facebook className="social-icon" />,
            linkedin: <Linkedin className="social-icon" />,
            google: <Google className="social-icon" />,
            twitter: <Twitter className="social-icon" />,
            instagram: <Instagram className="social-icon" />,
        };
        const filterIcons = props.data.socialNetworks.map((network: SocialNetworks, id) => {
            const socialName = network.socialNetwork;
            return (
                <SocialIcon href={network.url} target="_blank" key={id}>
                    {socials[socialName]}
                </SocialIcon>
            );
        });
        return filterIcons;
    };

    const zipFormatter = () => {
        const zipNumbers = props.data.address.zip.replace(/\D/g, '');
        return zipNumbers;
    };

    return (
        <Card className={classNames({ gateIsActive })}>
            {screenViewPort !== 'mobile' && (
                <SlidingCard className="mobile-off">
                    {props.data.website && (
                        <>
                            <WebLinkContainer href={props.data.website} target="_blank">
                                {props.data.website}
                                <Icon src={globe} />
                            </WebLinkContainer>
                            <Divider />
                        </>
                    )}
                    {props.data.phone && (
                        <>
                            <ContainerWithIcon href={`tel:${props.data.phone}`}>
                                <NumberFormatter number={props.data.phone} />
                                <Icon src={phone} />
                            </ContainerWithIcon>
                            <Divider />
                        </>
                    )}
                    {props.data.email && (
                        <>
                            <ContainerWithIcon href={`mailto:${props.data.email}`}>
                                {props.data.email}
                                <Icon src={letter} />
                            </ContainerWithIcon>
                            <Divider />
                        </>
                    )}
                    {/* Atm there is no email for bills */}
                    {/* {screenViewPort === 'desktop' && (
                        <>
                            <ContainerWithIcon href={`mailto:${props.data.email}`}>
                                <div>
                                    <SmallText className="desktop-margin">{t('email_for_bills')}</SmallText>
                                    {props.data.email ? props.data.email : t('no_information_provided')}
                                </div>
                                <Icon src={letter} />
                            </ContainerWithIcon>
                            <Divider />
                        </>
                    )} */}
                    <ContainerWithIcon
                        onClick={() => {
                            showInMapClicked(props.data.address.latitude, props.data.address.longitude);
                        }}
                    >
                        {props.data.address.zip
                            ? `${props.data.address.street ? `${props.data.address.street},` : ''} ${
                                  props.data.address.buildingNum
                              }${props.data.address.roomNum ? `-${props.data.address.roomNum}` : ''}${
                                  props.data.address.buildingNum.trim() ? ',' : ''
                              } ${props.data.address.zip ? `LT-${zipFormatter()},` : ''} ${props.data.address.city}`
                            : t('no_information_provided')}
                        <Icon src={pin} />
                    </ContainerWithIcon>
                    {socialIconsFilter().length > 0 && (
                        <>
                            <Divider />
                            <Icons className={classNames({ 'space-out': props.data.socialNetworks.length !== 2 })}>
                                {socialIconsFilter()}
                            </Icons>
                        </>
                    )}
                </SlidingCard>
            )}
            {screenViewPort !== 'desktop' && (
                <CenterContainer>
                    <Row>
                        <OneOfFour>
                            <SmallText>{t('employees')}</SmallText>
                            <TextUnderSmall>
                                <>
                                    <People className="icon" />
                                    {props.data.workerStatistics.totalCount.length > 0
                                        ? props.data.workerStatistics.totalCount[
                                              props.data.workerStatistics.totalCount.length - 1
                                          ].count
                                        : t('no_information_provided')}
                                </>
                            </TextUnderSmall>
                        </OneOfFour>
                        <OneOfFour>
                            <SmallText>{t('authorized_capital')}</SmallText>
                            <TextUnderSmall>
                                <Euro className="icon" />
                                {props.data.startingCapital}
                            </TextUnderSmall>
                        </OneOfFour>
                    </Row>
                    <Divider />
                    <Row>
                        <OneOfFour>
                            <SmallText>{t('registration_date')}</SmallText>
                            <TextUnderSmall>
                                <Calendar className="icon" />
                                {props.data.registrationDate}
                            </TextUnderSmall>
                        </OneOfFour>
                        <OneOfFour>
                            <SmallText>{t('legal_status')}</SmallText>
                            <TextUnderSmall>
                                <Hammer className="icon" />
                                {props.data.legalStatus
                                    ? t(`legal_statuses.${props.data.legalStatus}`)
                                    : t('no_information_provided')}
                            </TextUnderSmall>
                        </OneOfFour>
                    </Row>
                </CenterContainer>
            )}
            {props.data.logo?.logoPath && props.data.name && (
                <AditionalContainer>
                    <LogoText>{props.data.name}</LogoText>
                    {props.data.logo && <Logo src={props.data.logo.logoPath} />}
                </AditionalContainer>
            )}
        </Card>
    );
};
