import styled, { css } from 'styled-components';
import Phone from '@mui/icons-material/LocalPhoneOutlined';
import Letter from '@mui/icons-material/EmailOutlined';
import Globe from '@mui/icons-material/LanguageOutlined';
import Pin from '@mui/icons-material/FmdGoodOutlined';
import { ReactComponent as redArrow } from '../../../assets/images/BusinessListCard/red_arrow.svg';
import { Stars } from '../../Stars/Stars';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';
import { useTranslation } from 'react-i18next';
import { CompanyListItem, SeperateRatingItem } from '../../../types/api';
import { useNavigate } from 'react-router-dom';
import { NumberFormatter } from '../../Formatters/NumberFormatter';

const albertRegular = css`
    font-family: 'Albert-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
`;

const Button = styled.div`
    ${(props) => css`
        cursor: pointer;
        display: flex;
        align-items: center;
        ${props.theme.screen.mobile &&
        css`
            &.margin-top {
                margin-top: 8px;
            }
        `}
    `}
`;
const ReviewText = styled.p`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        color: ${props.theme.colors.brandRed};
        margin: 16px 0 0 0;
        &.no-margin {
            margin-top: 0;
        }
        ${!props.theme.screen.desktop &&
        css`
            display: block;
            margin-top: 24px;
        `}
        ${props.theme.screen.mobile &&
        css`
            margin-top: 16px;
        `}
    `}
`;
const RedArrow = styled(redArrow)`
    ${(props) => css`
        margin: 16px 0 0 8px;
        &.no-margin {
            margin-top: 0;
        }
        ${props.theme.screen.tablet &&
        css`
            margin-top: 24px;
        `}
        ${props.theme.screen.mobile &&
        css`
            margin-top: 16px;
        `}
    `}
`;
const Divider = styled.div`
    ${(props) => css`
        height: 1px;
        background-color: ${props.theme.colors.outlineLight};
        margin: 24px 0;
        width: 100%;
    `}
`;
const Text = styled.a`
    ${(props) => css`
        ${albertRegular}
        display: flex;
        justify-content: space-between;
        margin: 0 0 17px 0;
        text-decoration: none;
        color: ${props.theme.colors.black};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 13px;
            line-height: 16px;
        `}
        &.no-margin {
            margin: 0;
            display: flex;
            align-items: center;
        }
        &.contacts {
            ${props.theme.screen.mobile &&
            css`
                flex-direction: column;
                margin-bottom: 0;
            `}
        }
        &.first {
            cursor: pointer;
            ${props.theme.screen.mobile &&
            css`
                order: 4;
            `};
        }
        &.second {
            ${props.theme.screen.mobile &&
            css`
                order: 2;
            `}
        }
        &.third {
            ${props.theme.screen.mobile &&
            css`
                order: 3;
            `}
        }
        &.fourth {
            margin-bottom: 0;
            ${props.theme.screen.mobile &&
            css`
                order: 1;
            `}
        }
    `}
`;
const TextWithContactInfo = styled.div`
    ${(props) => css`
        display: flex;
        gap: 36px;
        width: 100%;
        ${props.theme.screen.mobile &&
        css`
            flex-wrap: wrap-reverse;
            gap: 0;
        `}
    `}
`;
const ContactInfo = styled.div`
    ${(props) => css`
        width: 100%;
        ${props.theme.screen.mobile &&
        css`
            display: flex;
            justify-content: space-around;
        `}
    `}
`;
const Icon = styled.div`
    ${(props) => css`
        padding-left: 8px;
        color: ${props.theme.colors.darkNeutral};
        ${props.theme.screen.mobile &&
        css`
            align-self: center;
            height: 24px;
            padding-left: 0px;
            &.img {
                width: 100%;
            }
        `}
    `}
`;
const TextContainerB = styled.div`
    ${(props) => css`
        width: 100%;
        &.grey-background {
            background-color: ${props.theme.colors.greyBg};
            border-radius: 10px;
            padding: 16px;
        }
    `}
`;
const TextContainerC = styled.div`
    ${(props) => css`
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
        `}
    `}
`;
const MobileText = styled.p`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        color: ${props.theme.colors.outlineAndPlaceholder};
        margin-bottom: 0;
    `}
`;
const Review = styled.p`
    ${(props) => css`
        ${albertRegular}
        margin: 16px 0;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 13px;
            line-height: 16px;
        `}
    `}
`;
const ImgContainer = styled.div``;
const Image = styled.img`
    margin-right: 8px;
    height: 56px;
    width: 56px;
    border-radius: 5px;
    aspect-ratio: 1;
`;
const TextContainerA = styled.div`
    width: 100%;
`;
const PhotoContainerC = styled.div`
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(3, 1fr);
`;

const PhotoWrapper = styled.div`
    position: relative;
    owerflow: hidden;
`;

const Photo = styled.img`
    width: 100%;
    height: 100%;
    max-height: 114px;
    object-fit: cover;
    border-radius: 10px;
`;

type PropsAB = {
    data: CompanyListItem;
};

const CardA = (props: PropsAB) => {
    const { screenViewPort } = useSelector((state: IState) => state.global);
    return (
        <TextContainerA>
            {screenViewPort === 'mobile' && <Divider />}
            <Text className="last">{props.data.description}</Text>
        </TextContainerA>
    );
};

const CardB = (props: PropsAB) => {
    const navigate = useNavigate();
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();
    const rating = (object: SeperateRatingItem) =>
        (object.ratingExpectation + object.ratingQpRatio + object.ratingQuality + object.ratingSpeed) / 4;

    const stopPropogation = (e: any) => {
        e.stopPropagation();
    };

    const cardInnerPage = () => {
        const state = { scrollTo: 'feedback' };
        navigate(`/imones-kortele/${props.data.id}`, { state });
    };

    return (
        <>
            {props.data.newestFeedback && (
                <TextContainerB className="grey-background">
                    <Stars
                        noNumber={true}
                        starClass={'smaller-stars'}
                        rating={rating(props.data.newestFeedback)}
                        feedbackCount={props.data.feedbackCount}
                    />
                    <Review>{props.data.newestFeedback.feedback}</Review>
                    <ImgContainer>
                        {props.data.newestFeedback.attachments.slice(0, 4).map((img) => (
                            <Image src={img.thumbnailPath} />
                        ))}
                    </ImgContainer>
                    <Button
                        onClick={(e) => {
                            stopPropogation(e);
                            cardInnerPage();
                        }}
                    >
                        <ReviewText>{t('show_full_review')}</ReviewText>
                        <RedArrow />
                    </Button>
                </TextContainerB>
            )}
            {screenViewPort === 'mobile' && <Divider />}
        </>
    );
};

type PropsC = {
    data: CompanyListItem;
};

const CardC = (props: PropsC) => {
    const navigate = useNavigate();
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();

    const stopPropogation = (e: any) => {
        e.stopPropagation();
    };

    const cardInnerPage = () => {
        const state = { scrollTo: 'image-gallery' };
        navigate(`/imones-kortele/${props.data.id}`, { state });
    };

    return (
        <>
            <TextWithContactInfo>
                <TextContainerC>
                    <>
                        <PhotoContainerC>
                            {props.data.galleries.slice(0, 3).map((img, i) => (
                                <PhotoWrapper>
                                    <Photo src={img.logoPath} key={i} />
                                </PhotoWrapper>
                            ))}
                        </PhotoContainerC>
                        <Button
                            onClick={(e) => {
                                stopPropogation(e);
                                cardInnerPage();
                            }}
                        >
                            <ReviewText>{t('go_to_image_gallery')}</ReviewText>
                            <RedArrow />
                        </Button>
                    </>
                </TextContainerC>
            </TextWithContactInfo>
            {screenViewPort === 'mobile' && <Divider />}
        </>
    );
};

type Props = {
    data: CompanyListItem;
    cardType: string;
};

export const CardMid = (props: Props) => {
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();

    const stopPropogation = (e: any) => {
        e.stopPropagation();
    };

    const showInMapClicked = (lat: number, long: number) => {
        if (lat && long) {
            window.open('https://maps.google.com?q=' + lat + ',' + long);
        }
    };

    const zipFormatter = () => {
        const zipNumbers = props.data.address.zip.replace(/\D/g, '');
        return zipNumbers;
    };

    return (
        <TextWithContactInfo>
            {props.cardType === 'C' && props.data.logos.length !== 0 && <CardC data={props.data} />}
            {props.cardType === 'B' && props.data.description != null && <CardB data={props.data} />}
            {props.cardType === 'A' && props.data.description && <CardA data={props.data} />}
            {props.data.address && (
                <ContactInfo>
                    <Text
                        className="contacts first"
                        onClick={(e) => {
                            stopPropogation(e);
                            showInMapClicked(props.data.address.latitude, props.data.address.longitude);
                        }}
                    >
                        {screenViewPort !== 'mobile' &&
                            `${props.data.address.street ? `${props.data.address.street},` : ''} ${
                                props.data.address.buildingNum
                            }${props.data.address.roomNum ? `-${props.data.address.roomNum}` : ''}${
                                props.data.address.buildingNum.trim() ? ',' : ''
                            } ${props.data.address.zip ? `LT-${zipFormatter()},` : ''} ${props.data.address.city}`}
                        <Icon>
                            <Pin className="img" />
                        </Icon>
                        {screenViewPort === 'mobile' && <MobileText>{t('address')}</MobileText>}
                    </Text>
                    {props.data.phone && (
                        <Text
                            className="contacts second"
                            onClick={(e) => stopPropogation(e)}
                            href={`tel:${props.data.phone}`}
                        >
                            {screenViewPort !== 'mobile' && <NumberFormatter number={props.data.phone} />}
                            <Icon>
                                <Phone className="img" />
                            </Icon>
                            {screenViewPort === 'mobile' && <MobileText>{t('telephone')}</MobileText>}
                        </Text>
                    )}
                    {props.data.email && (
                        <Text
                            className="contacts third"
                            onClick={(e) => stopPropogation(e)}
                            href={`mailto:${props.data.email}`}
                        >
                            {screenViewPort !== 'mobile' && props.data.email}
                            <Icon>
                                <Letter className="img" />
                            </Icon>
                            {screenViewPort === 'mobile' && <MobileText>{t('email')}</MobileText>}
                        </Text>
                    )}
                    {props.data.website && (
                        <Text
                            className="contacts fourth"
                            onClick={(e) => stopPropogation(e)}
                            target="_blank"
                            href={props.data.website}
                        >
                            {screenViewPort !== 'mobile' && props.data.website}
                            <Icon>
                                <Globe className="img" />
                            </Icon>
                            {screenViewPort === 'mobile' && <MobileText>{t('website')}</MobileText>}
                        </Text>
                    )}
                </ContactInfo>
            )}
        </TextWithContactInfo>
    );
};
