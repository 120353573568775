import styled, { css } from 'styled-components';
import { CompanyListItem, WorkingTime } from '../../../types/api';
import checkCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';
import { useNavigate } from 'react-router-dom';

const albertRegular = css`
    font-family: 'Albert-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
`;

const Divider = styled.div`
    ${(props) => css`
        height: 1px;
        background-color: ${props.theme.colors.outlineLight};
        margin: 24px 0;
        width: 100%;
    `}
`;
const CategoriesContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Categories = styled.div`
    display: flex;
    height: fit-content;
    width: 100%;
`;
const LogoContainer = styled.div`
    ${(props) => css`
        display: flex;
        max-height: 56px;
        justify-content: flex-end;
        ${!props.theme.screen.desktop &&
        css`
            max-height: 48px;
        `}
    `}
`;
const CertificateLogo = styled.img`
    ${(props) => css`
        max-height: 56px;
        max-width: 75px;
        margin-right: 8px;
        ${!props.theme.screen.desktop &&
        css`
            height: 48px;
        `}
    `}
`;
const WorkingHoursContainer = styled.div`
    ${(props) => css`
        display: flex;
        gap: 30px;
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
            gap: 16px;
        `}
    `}
`;
const WorkingText = styled.p`
    font-family: 'Albert-Bold';
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    width: 25%;
    min-width: 100px;
    margin: 0;
`;
const WorkingHours = styled.div`
    display: flex;
`;
const Week = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;
const Day = styled.div`
    ${(props) => css`
        ${albertRegular}
        color: ${props.theme.colors.outlineAndPlaceholder};
        display: flex;
        align-items: center;
        min-width: 96px;
        margin: 0;
        ${!props.theme.colors.desktop &&
        css`
            font-size: 13px;
            line-height: 16px;
        `}
    `}
`;
const Time = styled.p`
    ${(props) => css`
        color: ${props.theme.colors.black};
        margin: 0;
        padding-left: 4px;
        &.day-off {
            color: ${props.theme.colors.outlineAndPlaceholder};
        }
    `}
`;
const Break = styled.div`
    ${(props) => css`
        display: none;
        ${props.theme.colors.mobile &&
        css`
            display: block;
            margin: 0;
            width: 100%;
        `}
    `}
`;
const ReservationContainer = styled.div``;

const BonusesContainer = styled.div`
    ${(props) => css`
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 16px 0;
        ${props.theme.screen.mobile &&
        css`
            justify-content: space-between;
        `}
    `}
`;
const Bonus = styled.div`
    ${(props) => css`
        ${albertRegular}
        margin: 0;
        display: flex;
        align-items: center;
        width: 33.3%;
        ${props.theme.screen.tablet &&
        css`
            font-size: 13px;
            line-height: 16px;
        `}
        ${props.theme.screen.mobile &&
        css`
            width: 48%;
            font-size: 14px;
            line-height: 17px;
            overflow-wrap: anywhere;
        `}
    `}
`;
const Image = styled.img`
    margin-right: 8px;
    height: 24px;
    width: 24px;
`;
const DayContainer = styled.div`
    margin-right: 8px;
`;
const Category = styled.div`
    display: flex;
    align-items: center;
    margin-right: 8px;
    cursor: pointer;
`;
const LogosAndCategories = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;
const CheckCircleIcon = styled(checkCircleIcon)`
    ${(props) =>
        css`
            color: ${props.theme.colors.brandRed};
            width: 16px !important;
            margin-right: 8px;
        `}
`;

type Props = {
    data: CompanyListItem;
    cardType?: string;
};

const CardBottomA = (props: Props) => {
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const { categoriesList } = useSelector((state: IState) => state.categories);
    const navigate = useNavigate();

    const certificateAmount = () => {
        return screenViewPort === 'mobile' ? 1 : 3;
    };

    const navigateToHead = (activityId: number, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const category = categoriesList.find((category: any) =>
            category.topics.find((topic: any) => topic.heads.find((head: any) => head.id === activityId))
        );

        if (category) {
            const topic = category.topics.find((topic: any) => topic.heads.find((head: any) => head.id === activityId));

            if (topic) {
                navigate(`/imoniu-sarasas/${category.id}/${topic.id}/${activityId}`);
            }
        }
    };

    return (
        <>
            {props.data.topics.length > 0 && <Divider />}
            <CategoriesContainer>
                <Categories>
                    <LogosAndCategories>
                        {/* TODO change topics to activities when backend gives back them with ids. */}
                        {props.data.activities.map((item, i) => (
                            <Category key={i} onClick={(e) => navigateToHead(item.id, e)}>
                                <CheckCircleIcon />
                                {item.name}
                            </Category>
                        ))}
                    </LogosAndCategories>
                    <LogoContainer>
                        {props.data.certificates.slice(0, certificateAmount()).map((logo, i) => {
                            return <CertificateLogo key={i} src={logo.image ? logo.image : ''} />;
                        })}
                    </LogoContainer>
                </Categories>
            </CategoriesContainer>
        </>
    );
};
const CardBottomB = (props: Props) => {
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();
    const formatTime = (time: string) => {
        if (!time) {
            return 'Nedirbam.';
        }
        const date = new Date(`2022.12.29 ${time}`);
        const hours = date.getHours();
        const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
        const formatedTime = `${hours}.${minutes}`;
        return formatedTime;
    };
    const breakPoint = (i: number) => {
        if (i === 1 || i === 3 || i === 4 || i === 6) {
            return <Break />;
        }
    };
    const weekDay = ['Pr', 'A', 'T', 'K', 'P', 'Š', 'S'];
    return (
        <>
            {screenViewPort !== 'mobile' && <Divider />}
            <WorkingHoursContainer>
                <WorkingText>{t('working_hours')}</WorkingText>
                <WorkingHours>
                    <Week>
                        {props.data.workingTime.map((day: WorkingTime, i: number) => (
                            <DayContainer key={i}>
                                <Day>
                                    {weekDay[i]}
                                    <Time className={day.openTime ? '' : 'day-off'}>
                                        {!day.openTime
                                            ? formatTime(day.openTime)
                                            : `${formatTime(day.openTime)} - ${formatTime(day.closeTime)};`}
                                    </Time>
                                </Day>
                                {breakPoint(i)}
                            </DayContainer>
                        ))}
                    </Week>
                </WorkingHours>
            </WorkingHoursContainer>
        </>
    );
};
const CardBottomC = (props: Props) => {
    return (
        <>
            {props.data.properties.length > 0 && (
                <ReservationContainer>
                    <Divider />
                    <BonusesContainer>
                        {props.data.properties.map((property) => {
                            return (
                                <Bonus>
                                    <Image src={property.image} />
                                    {property.name}
                                </Bonus>
                            );
                        })}
                    </BonusesContainer>
                </ReservationContainer>
            )}
        </>
    );
};
export const CardBottom = (props: Props) => {
    return (
        <>
            {props.cardType === 'a' && <CardBottomA data={props.data} />}
            {props.cardType === 'b' && <CardBottomB data={props.data} />}
            {props.cardType === 'c' && <CardBottomC data={props.data} />}
        </>
    );
};
