import { ReactComponent as StarImg } from '../../assets/images/CardImages/star_icon.svg';
import styled, { css } from 'styled-components';

const ReviewContainer = styled.div`
    display: flex;
    align-items: center;
`;
const Star = styled(StarImg)`
    ${(props) => css`
        width: 24px;
        height: 24px;
        margin-right: 12px;
        &.company-inner-page {
            width: 32px;
            height: 32px;
            ${!props.theme.screen.desktop &&
            css`
                width: 24px;
                height: 24px;
            `}
        }
        &.smaller-stars {
            width: 14px;
            height: 14px;
            margin-right: 8px;
        }
        &.grey {
            filter: grayscale(100);
            opacity: 20%;
        }
    `}
`;
const NumberOfReviews = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        ${!props.theme.screen.desktop &&
        css`
            &.bigger-stars {
                font-size: 12px;
                font-weight: 700;
                line-height: 14px;
                letter-spacing: 0.1em;
            }
        `}
    `}
`;
interface Props {
    noNumber?: boolean;
    starClass?: string;
    rating: number;
    feedbackCount: number;
}

export const Stars = (props: Props) => {
    const rating: number = props.rating;
    const allRatings: number = props.feedbackCount;

    return (
        <ReviewContainer>
            {Array.from(Array(5), (e, i) => (
                <Star key={i} className={`${Math.round(rating) <= i && 'grey'} ${props.starClass}`} />
            ))}
            {!props.noNumber && <NumberOfReviews className={props.starClass}>({allRatings})</NumberOfReviews>}
        </ReviewContainer>
    );
};
