import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ResponsiveBar } from '@nivo/bar';
import SouthIcon from '@mui/icons-material/South';

import classnames from 'classnames';
import styled, { css } from 'styled-components';
import { IState } from '../../../../store';
import { FinancialStatsItem } from '../../../../types/api';

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 16px 0;
`;

const ChartWrapper = styled.div`
    width: 100%;
    height: 272px;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            height: 332px;
        `}
`;

const BottomWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 16px;
`;

const ShowMoreButton = styled.div`
    color: ${(props) => props.theme.colors.brandRed};
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    margin: 0 16px;
`;

const ShowMoreText = styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 120%;
`;

const DataTextWrapper = styled.div`
    font-family: 'Albert-Regular';
    display: flex;
    width: 60%;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    letter-spacing: 0.005em;
    margin: 0 16px;
    color: ${(props) => props.theme.colors.secondaryBlack};
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            font-size: 13px;
            width: auto;
        `}
`;

const ProfitText = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 200px;
    width: 100%;
    ${(props) =>
        props.theme.screen.mobile &&
        css`
            gap: 20px;
            max-width: auto;
            width: auto;
        `}
`;

const ArrowIcon = styled(SouthIcon)`
    transition: transform 0.2s ease-in-out;
    font-size: 14px !important;
    &.up {
        transform: rotate(-180deg);
    }
`;

interface Props {
    data: FinancialStatsItem[];
}

export const FinanceChart = (props: Props) => {
    const { t } = useTranslation();
    const { screenViewPort } = useSelector((state: IState) => state.global);

    const [showMore, setShowMore] = useState(false);

    const colors = ['#67B7DC', '#6794DC', '#6771DC', '#8067DC', '#A367DC', '#C767DC'];

    const getColor = (bar: any) => colors[bar.index];

    return (
        <Container>
            <ChartWrapper>
                <ResponsiveBar
                    data={props.data}
                    keys={['amount']}
                    indexBy="date"
                    margin={{ top: 10, right: 30, bottom: screenViewPort !== 'desktop' ? 70 : 20, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={getColor}
                    enableLabel={false}
                    groupMode="grouped"
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#38bcb2',
                            size: 4,
                            padding: 1,
                            stagger: true,
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#eed312',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10,
                        },
                    ]}
                    fill={[
                        {
                            match: {
                                id: 'fries',
                            },
                            id: 'dots',
                        },
                        {
                            match: {
                                id: 'sandwich',
                            },
                            id: 'lines',
                        },
                    ]}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: 'middle',
                        legendOffset: 32,
                        truncateTickAt: 0,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: 'middle',
                        legendOffset: -40,
                        truncateTickAt: 0,
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                        from: 'color',
                        modifiers: [['darker', 1.6]],
                    }}
                    role="application"
                    ariaLabel="Nivo bar chart demo"
                    barAriaLabel={(e) => e.id + ':·' + e.formattedValue + ' in·country: '}
                />
            </ChartWrapper>
            <BottomWrapper>
                <ShowMoreButton onClick={() => setShowMore((prev) => !prev)}>
                    <ShowMoreText>{!showMore ? t('expand_data') : t('collapse_data')}</ShowMoreText>
                    <ArrowIcon
                        className={classnames({
                            up: showMore,
                        })}
                    />
                </ShowMoreButton>
                {showMore && (
                    <div>
                        {props.data
                            .slice(-8)
                            .reverse()
                            .map((item, index) => {
                                return (
                                    <DataTextWrapper key={index}>
                                        <div>{item.date}</div>
                                        <ProfitText>
                                            <div>
                                                {item.amount} {t('currency')}
                                            </div>
                                        </ProfitText>
                                    </DataTextWrapper>
                                );
                            })}
                    </div>
                )}
            </BottomWrapper>
        </Container>
    );
};
