export const pluralStringFormater = (number: number, zero: string, singular: string, plural: string) => {
    const numbersLastDigit = Number(number.toString().slice(-1));
    if ((number > 10 && number < 20) || numbersLastDigit === 0) {
        return zero;
    }
    if (numbersLastDigit === 1) {
        return singular;
    }
    if (numbersLastDigit > 1) {
        return plural;
    }
};
