import styled, { css } from 'styled-components';
import { CompanyData } from '../../../../types/api';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { GalleryToModal } from '../../../GalleryToModal/GalleryToModal';
import { useState } from 'react';
import classNames from 'classnames';
import arrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { IState } from '../../../../store';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const ActivitiesContainer = styled.div`
    ${(props) =>
        css`
            padding: 32px 23px;
            border-top: 1px solid ${props.theme.colors.outlineLight};
            ${!props.theme.screen.desktop &&
            css`
                padding: 24px 16px;
            `}
        `}
`;
const ActivitiesWrap = styled.div`
    ${(props) =>
        css`
            border-radius: 10px;
            padding: 24px;
            border: 1px solid ${props.theme.colors.outlineLight};
            ${!props.theme.screen.desktop &&
            css`
                padding: 16px;
            `}
        `}
`;
const TextContainer = styled.div``;
const PhotoContainer = styled.div`
    ${(props) => css`
        display: grid;
        margin-top: 32px;
        border-top: 1px solid ${props.theme.colors.outlineLight};
        ${!props.theme.screen.desktop &&
        css`
            margin-top: 24px;
        `}
        &.gate-active {
            scroll-margin-top: 270px;
        }
        ${!props.theme.screen.desktop &&
        css`
            scroll-margin-top: 80px;
        `}
        ${props.theme.screen.mobile &&
        css`
            font-size: 18px;
            line-height: 22px;
            margin-top: 24px;
        `}
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 32px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 17px;
            line-height: 20px;
        `}
    `}
`;
const Activities = styled.div``;
const Activity = styled.div`
    display: flex;
    margin-bottom: 16px;
    cursor: pointer;
`;
const Icon = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        color: ${props.theme.colors.brandRed};
        .icon {
            width: 16px !important;
            height: 16px !important;
        }
        .arrow {
            width: 18px !important;
            height: 18px !important;
            margin-left: 8px;
        }
    `}
`;
const Text = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        margin-left: 8px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 16px;
            line-height: 19px;
        `}
    `}
`;
const Button = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        font-family: 'Albert-Regular';
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        color: ${props.theme.colors.brandRed};
    `}
`;

const Photos = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 32px;
        ${!props.theme.screen.desktop &&
        css`
            margin-top: 24px;
        `}
    `}
`;
const PhotoWrapper = styled.div`
    position: relative;
    owerflow: hidden;
`;
const Photo = styled.img`
    width: 100%;
    height: 100%;
    max-height: 238px;
    object-fit: cover;
`;
const ButtonSeeMorePhotos = styled.div`
    ${(props) => css`
        display: flex;
        font-family: 'Albert-Regular';
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        margin-top: 24px;
        justify-self: flex-end;
        padding: 12px 24px;
        border: 1px solid ${props.theme.colors.brandRed};
        border-radius: 50px;
        color: ${props.theme.colors.brandRed};
        ${!props.theme.screen.desktop &&
        css`
            width: calc(100% - 48px);
            justify-content: center;
        `}
    `}
`;

const Arrow = styled(arrowForwardIcon)`
    height: 18px !important;
    width: 18px !important;
    margin-left: 8px;
    &.up {
        rotate: 270deg;
    }
`;

type Props = {
    data: CompanyData;
};

export const OngoingActivities = (props: Props) => {
    const { gateIsActive } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();
    const [imageView, setImageView] = useState<string[]>([]);
    const [activitiesOpen, setActivitiesOpen] = useState(false);
    const { categoriesList } = useSelector((state: IState) => state.categories);
    const navigate = useNavigate();

    const { state } = useLocation();

    useEffect(() => {
        if (state?.scrollTo) {
            scrollToElement(state.scrollTo);
        }
    }, []);

    const scrollToElement = (elementName: string) => {
        const targetElement = document.getElementById(elementName);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const setImagesState = () => {
        const allImgUrls = props.data.galleries.flatMap((item) => item.logoPath);
        setImageView(allImgUrls);
    };

    const toggle = () => {
        setActivitiesOpen((prev) => !prev);
    };

    const navigateToHead = (activityId: number) => {
        const category = categoriesList.find((category: any) =>
            category.topics.find((topic: any) => topic.heads.find((head: any) => head.id === activityId))
        );

        if (category) {
            const topic = category.topics.find((topic: any) => topic.heads.find((head: any) => head.id === activityId));

            if (topic) {
                navigate(`/imoniu-sarasas/${category.id}/${topic.id}/${activityId}`);
            }
        }
    };

    return (
        <ActivitiesContainer>
            <ActivitiesWrap>
                <TextContainer>
                    <Header>{t('ongoing_activities')}</Header>
                    <Activities>
                        {/* TODO add navigation links when we have ids */}
                        {(activitiesOpen ? props.data.activities : props.data.activities.slice(0, 2)).map(
                            (activity) => (
                                <Activity>
                                    <Icon>
                                        <CheckCircleIcon className="icon" />
                                    </Icon>
                                    <Text onClick={() => navigateToHead(activity.id)}>{activity.name}</Text>
                                </Activity>
                            )
                        )}
                    </Activities>
                    {props.data.activities && (
                        <Button onClick={toggle}>
                            {activitiesOpen ? <>{t('hide_activities')}</> : <>{t('all_activities')}</>}
                            <Arrow className={classNames({ up: activitiesOpen })} />
                        </Button>
                    )}
                </TextContainer>
                {props.data.galleries.length > 0 && (
                    <PhotoContainer id="image-gallery" className={classNames({ 'gate-active': gateIsActive })}>
                        <Photos>
                            {props.data.galleries.map((img) => (
                                <PhotoWrapper>
                                    <Photo src={img.logoPath} />
                                </PhotoWrapper>
                            ))}
                        </Photos>
                        <ButtonSeeMorePhotos onClick={() => setImagesState()}>
                            {t('see_photo_gallery')}
                            <Icon>
                                <ArrowForwardIcon className="arrow" />
                            </Icon>
                        </ButtonSeeMorePhotos>
                        <GalleryToModal imagePaths={imageView} onClose={() => setImageView([])} />
                    </PhotoContainer>
                )}
            </ActivitiesWrap>
        </ActivitiesContainer>
    );
};
