import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { socialsProviderLogin } from '../../services/auth.service';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/global.slice';
import localStorage from '../../commons/localStorage';
import { AUTH_TOKEN_KEY } from '../../commons/constants';
import { setUser } from '../../store/user/user.slice';

export const SSOLogin = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const code = query.get('code');
        if (code) {
            dispatch(setLoading(true));
            socialsProviderLogin(code, 'odoo')
                .then((resp) => {
                    localStorage.put(AUTH_TOKEN_KEY, resp.data.token);
                    dispatch(setUser(resp.data.user));
                    if (resp.data.user.companyId) {
                        navigate(`/imones-kortele/${resp.data.user.companyId}`);
                        return;
                    }
                    navigate('/');
                })
                .finally(() => {
                    dispatch(setLoading(false));
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <></>;
};
