import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import arrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CompanyData } from '../../types/api';
import { useNavigate } from 'react-router-dom';

const CompaniesContainer = styled.div`
    ${(props) => css`
        width: 100%;
        max-width: 398px;
        display: flex;
        border-radius: 10px;
        background-color: ${props.theme.colors.white};
        ${!props.theme.screen.desktop &&
        css`
            max-width: unset;
        `}
    `}
`;
const Card = styled.div`
    ${(props) => css`
        flex: 1;
        display: flex;
        flex-direction: column;
        border: 1px solid ${props.theme.colors.outlineLight};
        border-radius: 10px;
        padding: 24px;
        :hover {
            cursor: pointer;
            .button {
                font-weight: 700;
            }
        }
    `}
`;
const ImgLogo = styled.img`
    height: 43px;
    width: 139px;
    display: flex;
    object-fit: none;
    justify-content: flex-start;
`;
const Divider = styled.div`
    ${(props) => css`
        height: 1px;
        background-color: ${props.theme.colors.outlineLight};
        margin: 24px 0;
    `}
`;
const CardHeader = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        flex-grow: 1; /* Grow to take up available space */
        ${!props.theme.screen.desktop &&
        css`
            font-size: 16px;
            line-height: 19px;
        `}
    `}
`;
const Description = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-top: 16px;
        color: ${props.theme.colors.secondaryBlack};
        flex-grow: 1; /* Grow to take up available space */
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 14px;
            line-height: 20px;
        `};
    `}
`;
const Button = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        color: ${props.theme.colors.brandRed};
        font-family: 'Albert-Regular';
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        margin-top: 24px;
    `}
`;
const Arrow = styled(arrowForwardIcon)`
    width: 18px !important;
    margin-left: 8px;
`;

const Wrap = styled.div`
    width: 100%;
`;

type Props = {
    data: CompanyData;
};

export const SliderCard = (props: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goToInnerCompanyPage = () => {
        navigate(`/paieska/imones-kortele/${props.data.id}`);
    };

    return (
        <CompaniesContainer onClick={() => goToInnerCompanyPage()}>
            <Card>
                {props.data.logo && (
                    <Wrap>
                        <ImgLogo src={props.data.logo.logoPath} />
                    </Wrap>
                )}
                {props.data.logo?.logoPath && <Divider />}
                <CardHeader>{props.data.name}</CardHeader>
                {props.data.description && <Description>{props.data.description}</Description>}
                <Button className="button">
                    {t('more_about_the_company')}
                    <Arrow />
                </Button>
            </Card>
        </CompaniesContainer>
    );
};
