import styled, { css } from 'styled-components';
import { useState, useEffect, useRef } from 'react';
import { CardTop } from './components/CardTop';
import { CardMid } from './components/CardMid';
import { CardBottom } from './components/CardBottom';
import { CompanyListItem } from '../../types/api';
import { useSelector } from 'react-redux';
import { IState } from '../../store';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

const Card = styled.div`
    ${(props) => css`
        width: 100%;
        margin-bottom: 32px;
        &:hover {
            box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
        }
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;
const CardContainer = styled.div`
    ${(props) => css`
        border: 1px solid ${props.theme.colors.outlineLight};
        border-radius: 10px;
        padding: 32px 30px;
        ${!props.theme.screen.desktop &&
        css`
            padding: 24px;
        `}
        ${props.theme.screen.mobile &&
        css`
            padding: 16px;
        `}
        &.boosted-position {
            border: 1px solid ${props.theme.colors.brandRed};
        }
    `}
`;

interface Props {
    company: CompanyListItem;
    setCardInView: (company: CompanyListItem) => void;
}

export const BusinessListCard = (props: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const [businessCardType, setBusinessCardType] = useState('A');

    useEffect(() => {
        if (props.company.customOptions.length > 0) {
            setBusinessCardType(props.company.customOptions[0].option);
        }

        calculateShownCard();
        if (screenViewPort !== 'mobile') {
            window.addEventListener('scroll', debouncedCalculateShownCard);
        }
        return () => {
            window.removeEventListener('scroll', debouncedCalculateShownCard);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const calculateShownCard = () => {
        if (!ref.current) {
            return;
        }
        const refTop = ref.current.getBoundingClientRect().top;
        const refBot = ref.current.getBoundingClientRect().bottom;

        if ((refBot > 150 && refBot < 600) || (refTop > 150 && refTop < 600)) {
            props.setCardInView(props.company);
        }
    };

    const debouncedCalculateShownCard = debounce(calculateShownCard, 250);

    const cardInnerPage = () => {
        navigate(`/imones-kortele/${props.company.id}`);
    };

    return (
        <Card ref={ref}>
            <CardContainer
                className={classNames({ 'boosted-position': props.company.boostedPosition })}
                onClick={cardInnerPage}
            >
                <CardTop data={props.company} cardType={businessCardType} />
                <CardMid data={props.company} cardType={businessCardType} />
                <CardBottom data={props.company} cardType={businessCardType} />
            </CardContainer>
        </Card>
    );
};
