import { useTranslation } from 'react-i18next';
import { CompanyData } from '../../../types/api';
import { BusinessMainCard } from '../BusinessCardWrap/BusinessMainCard';
import { OngoingActivities } from './AboutCompanyComponents/OngoingActivities';
import { Description } from './AboutCompanyComponents/Description';
import { SearchWords } from './AboutCompanyComponents/SearchWords';
import { BrandsRepresented } from './AboutCompanyComponents/BrandsRepresented';
import { PlaceAndWorkingHours } from './AboutCompanyComponents/PlaceAndWorkingHours/PlaceAndWorkingHours';
import { pluralStringFormater } from '../../Formatters/PluralStringFormater';

type Props = {
    data: CompanyData;
};

export const AboutCompany = (props: Props) => {
    const { t } = useTranslation();

    const Data = {
        title: t('about_the_company'),
        firstChart: t('employees'),
        firstNumber: `${
            props.data.workerStatistics.totalCount.length > 0
                ? props.data.workerStatistics.totalCount[props.data.workerStatistics.totalCount.length - 1].count
                : 0
        } ${
            props.data.workerStatistics.totalCount.length > 0 &&
            pluralStringFormater(
                props.data.workerStatistics.totalCount[props.data.workerStatistics.totalCount.length - 1].count,
                t(`workers.zero`),
                t(`workers.singular`),
                t(`workers.plural`)
            )
        }`,
        firstNumberDate: `(${
            props.data.workerStatistics.totalCount.length > 0
                ? props.data.workerStatistics.totalCount[props.data.workerStatistics.totalCount.length - 1].date
                : null
        })`,
        secondChart: t('vaccination_level'),
        secondNumber: props.data.vaccinationLevel > 0 ? `${props.data.vaccinationLevel}` : null,
    };

    return (
        <BusinessMainCard data={Data}>
            {props.data.activities.length > 0 && <OngoingActivities data={props.data} />}
            <Description description={props.data.description} descriptionLong={props.data.descriptionLong} />
            <SearchWords searchWords={props.data.keywords} />
            {props.data.trademarkImages.length > 0 && (
                <BrandsRepresented trademarkImages={props.data.trademarkImages} />
            )}
            <PlaceAndWorkingHours data={props.data} />
        </BusinessMainCard>
    );
};
