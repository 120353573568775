import styled, { css } from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../store';
import classNames from 'classnames';
import { InputItem } from './InputItem';
import { getSearchCompaniesList, getSearchFilters } from '../../services/company.service';
import { setSearchFilters } from '../../store/companies/companies.slice';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import closeIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { AutocompleteSelect } from './AutocompleteSelect';
import { QueryParams } from '../../types/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { AutocompleteInput } from './AutocompleteInput';
import { Box, CircularProgress } from '@mui/material';
import { setShowAdvancedMobileSearch } from '../../store/global.slice';

const Container = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        height: auto;
        margin-bottom: 0;
        margin-top: 91px;
        ${props.theme.screen.mobile &&
        css`
            margin-top: 84px;
        `}
        &.sticky-search {
            width: 100%;
            position: sticky;
            top: 97px;
            background: ${props.theme.colors.darkGreen};
            z-index: 1302;
            padding-top: 0;
            ${!props.theme.screen.desktop &&
            css`
                top: 73px;
            `}
            .wrapper {
                margin: 16px 0 0 0;
                padding: 0;
                ${!props.theme.screen.desktop &&
                css`
                    margin: 0;
                `};
            }
            &.advanced-active {
                padding-bottom: 23px;
            }
            &:not(.advanced-active) {
                height: 106px;
                ${props.theme.screen.tablet &&
                css`
                    height: 86px;
                `}
                ${props.theme.screen.mobile &&
                css`
                    height: 76px;
                `}
            }
        }
        &.hide-search {
            display: none;
        }
    `}
`;
const SearchBarContainer = styled.div`
    ${(props) => css`
        width: 1254px;
        height: fit-content;
        margin: 0 16px;
        z-index: 2;
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
            padding: 16px;
            margin: 0;
            background-color: ${props.theme.colors.darkGreen};
        `};
    `}
`;
const SearchForm = styled.form``;
const SearchFieldset = styled.fieldset`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border: none;
    padding: 0;
`;

const SearchWrap = styled.div`
    display: flex;
    border-radius: 8px;
    width: 100%;
    max-width: 1040px;
`;
const Button = styled.button`
    ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.darkGreen};
        border-radius: 0px 8px 8px 0px;
        border: none;
        min-width: 48px;
        font-weight: 400;
        height: 48px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        &:hover {
            font-weight: 700;
        }
        &.additional-search-button {
            width: 100%;
            border-radius: 100px;
            color: ${props.theme.colors.white};
            background-color: ${props.theme.colors.brandRed};
            margin-top: 16px;
            height: 48px;
            font-family: 'Albert-Regular';
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0em;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.2s ease-in-out;
            &:hover {
                background-color: ${props.theme.colors.hoverRed};
            }
            &.reset {
                background-color: ${props.theme.colors.hoverRed};
            }
        }
    `}
`;
const FilterButton = styled.button`
    ${(props) => css`
        max-width: 1040px;
        width: 100%;
        display: flex;
        background-color: transparent;
        border: none;
        color: ${props.theme.colors.white};
        margin: 8px 0 0 20px;
        font-family: 'Albert-Regular';
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        ${!props.theme.screen.desktop &&
        css`
            margin: 8px 0 0 0;
        `}
        &.not-home {
            color: ${props.theme.colors.brandRed};
        }
    `}
`;
const FilterButtonText = styled.div`
    ${(props) => css`
        display: flex;
        background-color: ${props.theme.colors.brandRed};
        color: ${props.theme.colors.white};
        padding: 12px 59px;
        border-radius: 100px;
        font-family: 'Albert-Regular';
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        transition: 0.2s ease-in-out;
        &:hover {
            background-color: ${props.theme.colors.hoverRed};
        }
        ${!props.theme.screen.desktop &&
        css`
            padding: 0;
            height: 48px;
            width: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
        `}
    `}
`;
const SelectsInputsBottom = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.neutral};
        margin-top: 16px;
        border-radius: 16px;
        padding: 16px;
        .form {
            ${!props.theme.screen.mobile &&
            css`
                height: 53px;
            `}
        }
        ${props.theme.screen.mobile &&
        css`
            margin-top: 8px;
        `}
    `}
`;
const OnlySelectsInputsWrap = styled.div`
    ${(props) => css`
        border-radius: 8px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
        ${props.theme.screen.tablet &&
        css`
            grid-template-columns: 1fr 1fr;
        `}
        ${props.theme.screen.mobile &&
        css`
            grid-template-columns: 1fr;
        `}
    `}
`;
const InputWrap = styled.div`
    ${(props) => css`
        display: flex;
        height: 58px;
        width: 100%;
    `}
`;
const MobileModule = styled.div`
    ${(props) => css`
        opacity: 1;
        transition: all linear 0.15s;
        &.desktop {
            width: 100%;
        }
        &.hide {
            opacity: 0;
            height: 0px;
            pointer-events: none;
            .hide {
                height: 0px;
            }
        }
        ${props.theme.screen.mobile &&
        css`
            position: absolute;
            top: 0;
            left: 0;
            /* We remove the height and width of the container paddings */
            width: calc(100% - 32px);
            height: calc(100% - 41px);
            margin-top: 0;
            border-radius: 0;
            /* Mobile burger menu z-index is 1301 */
            z-index: 1302;
            overflow: auto;
            &.mobile {
                background-color: ${props.theme.colors.black};
                padding: 25px 16px 16px 16px;
                height: 100vh;
            }
        `}
    `}
`;
const MobileFilterHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const MobileHeader = styled.div`
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 25px;
`;
const MobileFilterText = styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
`;
const MobileFilterWrap = styled.div`
    color: ${(props) => props.theme.colors.white};
    height: auto;
`;
const ShowOnInput = styled.div``;

const ArrowButton = styled.button`
    ${(props) => css`
        width: 100%;
        height: 100%;
        background-color: ${props.theme.colors.white};
        cursor: pointer;
        border: none;
        border-radius: 0 100px 100px 0;
        display: flex;
        align-items: center;
        justify-content: end;
        .hovered-button {
            display: none;
        }
        &:hover {
            .hovered-button {
                display: block;
            }
            .main-button {
                display: none;
            }
        }
    `}
`;

const SearchInput = styled.div`
    width: 100%;
    position: relative;
`;
const Wrapper = styled.div`
    display: flex;
    width: 100%;
`;

const CloseIcon = styled(closeIcon)`
    &.smaller {
        height: 18px;
        width: 18px;
        background-repeat: no-repeat;
        margin-right: 8px;
    }
`;

const Arrow = styled(KeyboardArrowDownIcon)`
    margin-left: 4px;
    font-size: 16px !important;
    &.rotate-arrow {
        transform: rotate(180deg);
    }
`;

const Divider = styled.div`
    ${(props) => css`
        position: absolute;
        height: 24px;
        width: 1px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: ${props.theme.colors.outlineGrey};
    `}
`;
const Wrap = styled.div`
    position: relative;
    width: 100%;
`;

const AdvancedSearch = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const initialFilters = {
    name: '',
    manager: '',
    legal_type: '',
    company_code: '',
    vat_code: '',
    days: [],
    head: '',
    topic: 0,
    category: 0,
    address: '',
    city: 0,
    query: '',
};

interface DisabledFilters {
    advancedTopic: boolean;
}

export const SearchBar = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { searchFilters } = useSelector((state: IState) => state.company);
    const { categoriesList } = useSelector((state: IState) => state.categories);
    const { screenViewPort, loading, isSearchOpen, showAdvancedMobileSearch } = useSelector(
        (state: IState) => state.global
    );

    const [formKey, setFormKey] = useState(0);
    const [filters, setFilters] = useState<QueryParams>({ ...initialFilters });
    const [disabledFilters, setDisabledFilters] = useState<DisabledFilters>({
        advancedTopic: false,
    });
    const [mobileInput, setMobileInput] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    const isLandingPage = location.pathname === '/';

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSet = (boolean: boolean) => {
        setMobileInput(boolean);
    };

    const setFilterValue = (filter: { [key: string]: string | number }) => {
        setFilters((prev) => ({ ...prev, ...filter }));
    };

    const searchForCompanies = () => {
        if (loading) {
            return;
        }
        getSearchCompaniesList(1, filters).then((res) => {
            dispatch(setShowAdvancedMobileSearch(false));
            resetFilters();
            const state = { companies: res.data, filters: filters };
            if (typeof filters.topic === 'number') {
                const category = categoriesList.find((category) =>
                    category.topics.find((topic) => topic.id === filters.topic)
                );

                if (category) {
                    navigate(`imoniu-sarasas/${category.id}/${filters.topic}`, { state });
                    return;
                }
            }
            navigate('paieska', { state });
        });
    };

    const resetFilters = () => {
        setFormKey((prev) => prev + 1);
        setFilters({ ...initialFilters });
    };

    const handleQueryInput = (value: string | number) => {
        if (typeof value === 'number') {
            setFilterValue({ topic: value, query: '' });
            setDisabledFilters({ advancedTopic: true });
            return;
        }
        setDisabledFilters({ advancedTopic: false });
        setFilterValue({ query: value });
    };

    useEffect(() => {
        getSearchFilters().then((res) => {
            dispatch(setSearchFilters(res));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (showAdvancedMobileSearch && screenViewPort === 'mobile') {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [showAdvancedMobileSearch, screenViewPort]);

    return (
        <Container
            ref={ref}
            className={classNames({
                'sticky-search': !isLandingPage && isSearchOpen,
                'advanced-active': showAdvancedMobileSearch,
            })}
        >
            <SearchBarContainer className="wrapper">
                <SearchForm
                    onSubmit={(e) => {
                        e.preventDefault();
                        searchForCompanies();
                    }}
                >
                    <SearchFieldset>
                        <SearchWrap>
                            <InputWrap>
                                <Wrapper>
                                    <SearchInput onClick={() => handleSet(true)}>
                                        <Wrap>
                                            <AutocompleteInput
                                                fieldKey={`query-${formKey}`}
                                                id="query_input"
                                                onChange={(value) => handleQueryInput(value)}
                                                options={searchFilters.topics}
                                                name={t('enter_company_name')!}
                                                optionLabel="name"
                                                class={'main-select'}
                                                allowFreeText={true}
                                                disabled={loading}
                                            />
                                        </Wrap>
                                        <Divider />
                                    </SearchInput>
                                </Wrapper>
                            </InputWrap>
                            <ShowOnInput>
                                <ArrowButton type="submit" className="input">
                                    <FilterButtonText>
                                        {screenViewPort === 'desktop' ? t('search') : <SearchIcon />}
                                    </FilterButtonText>
                                </ArrowButton>
                            </ShowOnInput>
                        </SearchWrap>
                        <FilterButton
                            type="button"
                            onClick={() => dispatch(setShowAdvancedMobileSearch(!showAdvancedMobileSearch))}
                            className={classNames({ 'not-home': location.pathname !== '/' })}
                        >
                            <AdvancedSearch>
                                {t('advanced_search')}
                                <Arrow
                                    className={classNames({
                                        'rotate-arrow': showAdvancedMobileSearch,
                                    })}
                                />
                            </AdvancedSearch>
                        </FilterButton>
                        <MobileModule
                            className={classNames({
                                mobile: screenViewPort === 'mobile',
                                desktop: screenViewPort !== 'mobile',
                                hide: !showAdvancedMobileSearch,
                            })}
                        >
                            {screenViewPort === 'mobile' && (
                                <MobileFilterWrap className="hide">
                                    <MobileFilterHeaderContainer>
                                        <MobileHeader>{t('advanced_search')}</MobileHeader>
                                        <CloseIcon
                                            type="button"
                                            onClick={() =>
                                                dispatch(setShowAdvancedMobileSearch(!showAdvancedMobileSearch))
                                            }
                                        />
                                    </MobileFilterHeaderContainer>
                                    <MobileFilterText>{t('company_search_by')}</MobileFilterText>
                                </MobileFilterWrap>
                            )}
                            <SelectsInputsBottom>
                                <OnlySelectsInputsWrap>
                                    <InputItem
                                        class={classNames({ 'mobile-input-styles': screenViewPort === 'mobile' })}
                                        id="search_address_input"
                                        placeholder={t('address')!}
                                        value={filters.address}
                                        onChange={(value) => setFilterValue({ address: value })}
                                    />
                                    {/* TODO: implement worker_number filter when backend adds support for it */}
                                    {/* <SelectItem name={t('worker_number')} /> */}
                                    <AutocompleteSelect
                                        fieldKey={`area-${formKey}`}
                                        name={t('area_of_activity')}
                                        options={searchFilters?.topics}
                                        onChange={(e) => setFilterValue({ topic: e?.id || 0 })}
                                        optionLabel="name"
                                        id="area_of_activity"
                                        disabled={loading || disabledFilters.advancedTopic}
                                    />
                                    {/* TODO: Find out which filter specifically corresponds to 'brand' */}
                                    {/* <SelectItem name={t('brand')} /> */}
                                    <InputItem
                                        id="company_code_input"
                                        placeholder={t('company_code')!}
                                        value={filters.company_code}
                                        onChange={(value) => setFilterValue({ company_code: value || '' })}
                                    />
                                    <InputItem
                                        id="vat_code_input"
                                        placeholder={t('vat_code')!}
                                        value={filters.vat_code}
                                        onChange={(value) => setFilterValue({ vat_code: value || '' })}
                                    />
                                    <AutocompleteSelect
                                        fieldKey={`working-${formKey}`}
                                        name={t('working_hours')}
                                        options={searchFilters?.workingTime}
                                        onChange={(e) => setFilterValue({ days: e || [] })}
                                        multiple={true}
                                        optionLabel="name"
                                        id="choose_working_hours"
                                        disabled={loading}
                                    />
                                    <InputItem
                                        id="manager_input"
                                        placeholder={t('manager')!}
                                        value={filters.manager}
                                        onChange={(value) => setFilterValue({ manager: value || '' })}
                                    />
                                    <AutocompleteSelect
                                        fieldKey={`business-${formKey}`}
                                        name={t('company_type')}
                                        customTranslationKey="legal_types"
                                        options={Object.values(searchFilters?.legalType).filter((item) => item)}
                                        onChange={(e) => setFilterValue({ legal_type: e || '' })}
                                        id="choose_business_type"
                                        disabled={loading}
                                    />
                                </OnlySelectsInputsWrap>
                                <Box sx={{ position: 'relative' }}>
                                    <Button
                                        className="additional-search-button"
                                        onClick={() => {
                                            searchForCompanies();
                                        }}
                                    >
                                        {t('search')}
                                    </Button>
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: 'white',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </SelectsInputsBottom>
                        </MobileModule>
                    </SearchFieldset>
                </SearchForm>
            </SearchBarContainer>
        </Container>
    );
};
