import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { IState } from '../../../../store';
import { useTranslation } from 'react-i18next';
import { Stars } from '../../../Stars/Stars';
import { FeedbackRatings } from '../../../../types/api';
import { ReactComponent as arrowIcon } from '../../../../assets/images/CardImages/arrow_forward.svg';
import { setFeedbackModalOpen, setLoginModalOpen } from '../../../../store/global.slice';

const Container = styled.div`
    ${(props) => css`
        border: 1px solid ${props.theme.colors.outlineLight};
        border-radius: 10px;
        padding: 24px;
        ${!props.theme.screen.desktop &&
        css`
            padding: 16px;
        `}
    `}
`;

const Header = styled.div`
    font-family: 'Albert-Bold';
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 32px;
`;

const RatingsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    ${(props) => css`
        ${!props.theme.screen.desktop &&
        css`
            flex-direction: column;
        `}
    `}
`;

const OverallRating = styled.div`
    ${(props) => css`
        width: 45%;
        ${Label} {
            margin-bottom: 16px;
        }
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;

const Label = styled.div`
    font-family: 'Albert-Regular';
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
`;

const SpecificRatings = styled.div`
    ${(props) => css`
        width: 55%;
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
            margin-top: 32px;
        `}
    `}
`;

const SpecificRating = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    align-items: flex-start;
    ${(props) => css`
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
        `}
    `}
`;

const ReviewButton = styled.button`
    font-family: 'Albert-Regular';
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    background-color: transparent;
    padding: 12px 24px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    height: fit-content;
    cursor: pointer;
    ${(props) => css`
        color: ${props.theme.colors.brandRed};
        border: 1px solid ${props.theme.colors.brandRed};
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
            margin-bottom: 32px;
            justify-content: center;
        `}
    `}
`;

const Arrow = styled(arrowIcon)`
    padding-left: 8px;
`;

interface Props {
    separateRatings: FeedbackRatings;
    rating: number;
    feedbackCount: number;
}

export const FeedbackSummary = (props: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { me } = useSelector((state: IState) => state.user);
    const { screenViewPort } = useSelector((state: IState) => state.global);

    const handleModalOpen = () => {
        if (me) {
            dispatch(setFeedbackModalOpen(true));
            return;
        }
        dispatch(setLoginModalOpen(true));
    };

    return (
        <Container>
            {screenViewPort === 'desktop' && <Header>{t('company_feedback')}</Header>}
            <RatingsWrapper>
                <OverallRating>
                    <Label>{t('overall_company_rating')}</Label>
                    <Stars rating={props.rating} feedbackCount={props.feedbackCount} />
                </OverallRating>
                <SpecificRatings>
                    {Object.keys(props.separateRatings).map((key) => {
                        return (
                            <SpecificRating key={key}>
                                <Label>{t(`ratings.${key}`)}</Label>
                                <Stars
                                    rating={Number(props.separateRatings[key as keyof FeedbackRatings])}
                                    feedbackCount={props.feedbackCount}
                                    starClass="smaller-stars"
                                />
                            </SpecificRating>
                        );
                    })}
                </SpecificRatings>
            </RatingsWrapper>
            <ReviewButton onClick={() => handleModalOpen()}>
                {t('write_a_review')}
                <Arrow />
            </ReviewButton>
        </Container>
    );
};
