import styled, { css } from 'styled-components';
import bigImg from '../../assets/images/ProductPage/big_img.svg';
import computerIcon from '../../assets/images/ProductPage/computer_icon.svg';
import chatIcon from '../../assets/images/ProductPage/chat_icon.svg';
import settingsIcon from '../../assets/images/ProductPage/settings_icon.svg';
import checkCircleIcon from '@mui/icons-material/CheckCircle';
import arrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Trans, useTranslation } from 'react-i18next';
// @ts-ignore
import { Helmet } from 'react-helmet';

const ProductPageContainer = styled.div``;
const Container = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 16px;
`;
const Centering = styled.div`
    width: 1254px;
`;
const Header = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 32px;
        font-weight: 700;
        line-height: 38px;
        text-align: center;
        margin-top: 16px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 20px;
            line-height: 24px;
        `}
    `}
`;
const TextUnderHeader = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 32px 0 64px;
        &.align-start {
            text-align: start;
        }
        ${props.theme.screen.mobile &&
        css`
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin: 16px 0;
        `}
    `}
`;
const TextWrap = styled.div`
    max-width: 820px;
    &.no-max-width {
        max-width: none;
    }
`;
const PhotoAndBulletPoints = styled.div`
    ${(props) => css`
        display: flex;
        gap: 30px;
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
            gap: 16px;
        `}
    `}
`;
const BigImg = styled.img`
    ${(props) => css`
        width: 50%;
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
        `}
    `}
`;
const BulletPoints = styled.div``;
const MainText = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 16px;
            line-height: 19px;
        `}
    `}
`;
const Bullet = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        margin-top: 16px;
        font-family: 'Albert-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        ${props.theme.screen.mobile &&
        css`
            margin-top: 8px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        `}
    `}
`;
const BulletIcon = styled(checkCircleIcon)`
    ${(props) => css`
        color: ${props.theme.colors.brandRed};
        margin-right: 16px;
        width: 16px !important;
    `}
`;
const Divider = styled.div`
    ${(props) => css`
        height: 1px;
        background-color: ${props.theme.colors.outlineLight};
        width: 100vw;
        margin: 64px 0;
        ${props.theme.screen.mobile &&
        css`
            margin: 32px 0;
        `}
    `}
`;
const DividerSmall = styled.div`
    ${(props) => css`
        height: 1px;
        background-color: ${props.theme.colors.outlineLight};
        width: 100%;
        margin: 24px 0;
    `}
`;
const Info = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: space-between;
        gap: 30px;
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
        `}
    `}
`;
const InfoBlock = styled.a`
    ${(props) => css`
        max-width: 291px;
        ${props.theme.screen.mobile &&
        css`
            max-width: unset;
        `}
    `}
`;
const ComputerIcon = styled.img`
    width: 100%;
    height: 145px;
`;
const TextBold = styled(MainText)`
    margin: 48px 0 16px;
`;
const Text = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-align: left;
        ${props.theme.screen.mobile &&
        css`
            font-size: 14px;
            line-height: 20px;
        `}
    `}
`;

const Anchor = styled.a`
    ${(props) => css`
        &.red-text {
            color: ${props.theme.colors.brandRed};
            text-decoration: none;
        }
    `}
`;
const PlanHeader = styled(Header)`
    text-align: left;
    margin: 0 0 32px;
`;
const PlanBlock = styled.a`
    ${(props) => css`
        text-decoration: none;
        color: ${props.theme.colors.black};
        border: 1px solid ${props.theme.colors.outlineLight};
        padding: 24px;
        border-radius: 10px;
        position: relative;
        &.smaller-block {
            margin: 16px 0;
        }
        &:hover {
            border: 1px solid ${props.theme.colors.brandGreen};
            cursor: pointer;
        }
        ${props.theme.screen.mobile &&
        css`
            &.smaller-block {
                margin: 0;
            }
        `}
    `}
`;
const PlanBlocks = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        margin-bottom: 131px;
        ${props.theme.screen.mobile &&
        css`
            grid-template-columns: 1fr;
            margin-bottom: 32px;
        `}
    `}
`;
const PlanName = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        margin-bottom: 16px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 18px;
            line-height: 22px;
        `}
    `}
`;
const Price = styled(Header)`
    display: flex;
    align-items: center;
    margin: 0 0 16px;
`;
const SmallerPrice = styled(MainText)`
    font-weight: 400;
`;

const Button = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        padding: 12px 24px;
        color: ${props.theme.colors.brandRed};
        border: 1px solid ${props.theme.colors.brandRed};
        border-radius: 100px;
        width: fit-content;
        font-family: 'Albert-Regular';
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        ${props.theme.screen.mobile &&
        css`
            display: flex;
            justify-content: center;
            width: 100%;
        `}
    `}
`;
const ButtonWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;
const ArrowForwardIcon = styled(arrowForwardIcon)`
    width: 18px !important;
    margin-left: 8px;
`;
const BlockBottom = styled.div`
    ${(props) => css`
        position: absolute;
        bottom: 24px;
        width: calc(100% - 48px);
        ${props.theme.screen.mobile &&
        css`
            position: unset;
            width: 100%;
        `}
    `}
`;

const Popular = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.brandGreen};
        padding: 8px 12px;
        font-family: 'Albert-Regular';
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        border-radius: 10px;
        width: fit-content;
        color: ${props.theme.colors.white};
        margin-bottom: 16px;
    `}
`;

export const ProductPage = () => {
    const { t } = useTranslation();

    return (
        <ProductPageContainer>
            <Helmet>
                <title>Produktai</title>
            </Helmet>
            <Container>
                <Centering>
                    <Header>{t('product_page.help_people_get_to_know_you')}</Header>
                    <TextUnderHeader>
                        <TextWrap>{t('product_page.intro')}</TextWrap>
                    </TextUnderHeader>
                    <PhotoAndBulletPoints>
                        <BigImg src={bigImg} />
                        <BulletPoints>
                            <Bullet>
                                <BulletIcon />
                                {t('product_page.first_intro_check')}
                            </Bullet>
                            <Bullet>
                                <BulletIcon />
                                {t('product_page.second_intro_check')}
                            </Bullet>
                        </BulletPoints>
                    </PhotoAndBulletPoints>
                </Centering>
            </Container>
            <Divider />
            <Container>
                <Centering>
                    <Info>
                        <InfoBlock>
                            <ComputerIcon src={computerIcon} />
                            <TextBold>{t('products')}</TextBold>
                            <Text>
                                <Trans i18nKey="product_page.products_text.first" />
                                <Anchor className="red-text" href={'https://klientams.imones.lt/'} target="_blank">
                                    {t('product_page.here')}
                                </Anchor>
                            </Text>
                            <Text>
                                <p>{t('product_page.products_text.second')}</p>
                            </Text>
                        </InfoBlock>
                        <InfoBlock>
                            <ComputerIcon src={chatIcon} />
                            <TextBold>{t('product_page.company_priority.head')}</TextBold>
                            <Text>
                                <Trans i18nKey="product_page.company_priority.text" />
                                <Anchor className="red-text" href={'https://klientams.imones.lt/'} target="_blank">
                                    {t('product_page.here')}
                                </Anchor>
                            </Text>
                        </InfoBlock>
                        <InfoBlock>
                            <ComputerIcon src={settingsIcon} />
                            <TextBold>{t('product_page.visual_ad.head')}</TextBold>{' '}
                            <Text>
                                <Trans i18nKey="product_page.visual_ad.text" />
                                <Anchor className="red-text" href={'https://klientams.imones.lt/'} target="_blank">
                                    {t('product_page.here')}
                                </Anchor>
                            </Text>
                        </InfoBlock>
                    </Info>
                </Centering>
            </Container>
            <Divider />
            <Container>
                <Centering>
                    <PlanHeader>{t('product_page.choose_a_plan.head')}</PlanHeader>
                    <TextUnderHeader className="align-start">
                        <TextWrap className="no-max-width">
                            <Trans i18nKey="product_page.choose_a_plan.text" />
                        </TextWrap>
                    </TextUnderHeader>
                    <PlanBlocks>
                        <PlanBlock className="smaller-block" href={'https://klientams.imones.lt/'} target="_blank">
                            <PlanName>{t('product_page.card.free')}</PlanName>
                            <Text>{t('product_page.card.free_plan_text')}</Text>
                            <DividerSmall />
                            <BulletPoints>
                                <Bullet>
                                    <BulletIcon />
                                    {t('product_page.card.props')}
                                </Bullet>
                            </BulletPoints>
                            <BlockBottom>
                                <DividerSmall />
                                <ButtonWrap>
                                    <Button>
                                        {t('product_page.contact_us')}
                                        <ArrowForwardIcon />
                                    </Button>
                                </ButtonWrap>
                            </BlockBottom>
                        </PlanBlock>
                        <PlanBlock href={'https://klientams.imones.lt/'} target="_blank">
                            <Popular>{t('most_popular')}</Popular>
                            <PlanName>{t('product_page.card.interactive')}</PlanName>
                            <Price>
                                360€<SmallerPrice>{t('product_page./month')}</SmallerPrice>
                            </Price>
                            <Text>{t('product_page.card.interactive_plan_text')}</Text>
                            <DividerSmall />
                            <BulletPoints>
                                <Bullet>
                                    <BulletIcon />
                                    {t('product_page.card.props')}
                                </Bullet>
                                <Bullet>
                                    <BulletIcon />
                                    {t('product_page.card.business_information_with_manager')}
                                </Bullet>
                                <Bullet>
                                    <BulletIcon />
                                    {t('product_page.card.communication_tools')}
                                </Bullet>
                            </BulletPoints>
                            <DividerSmall />
                            <ButtonWrap>
                                <Button>
                                    {t('product_page.contact_us')}
                                    <ArrowForwardIcon />
                                </Button>
                            </ButtonWrap>
                        </PlanBlock>
                        <PlanBlock className="smaller-block" href={'https://klientams.imones.lt/'} target="_blank">
                            <PlanName>{t('product_page.card.informative')}</PlanName>
                            <Price>
                                180€<SmallerPrice>{t('product_page./month')}</SmallerPrice>
                            </Price>
                            <Text>{t('product_page.card.informative_plan_text')}</Text>
                            <DividerSmall />
                            <BulletPoints>
                                <Bullet>
                                    <BulletIcon />
                                    {t('product_page.card.props')}
                                </Bullet>
                                <Bullet>
                                    <BulletIcon />
                                    {t('product_page.card.business_information_self_service')}
                                </Bullet>
                            </BulletPoints>
                            <BlockBottom>
                                <DividerSmall />
                                <ButtonWrap>
                                    <Button>
                                        {t('product_page.contact_us')}
                                        <ArrowForwardIcon />
                                    </Button>
                                </ButtonWrap>
                            </BlockBottom>
                        </PlanBlock>
                    </PlanBlocks>
                </Centering>
            </Container>
        </ProductPageContainer>
    );
};
