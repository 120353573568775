import styled, { css } from 'styled-components';
import { BannerItem } from '../../types/api';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import arrowForwardIcon from '@mui/icons-material/ArrowForward';

const Card = styled.img`
    height: 43px;
    width: auto;
    border-radius: 0 0 10px 10px;
    &.clean-border {
        border-radius: 10px;
    }
`;

const InnerWrapper = styled.div<{ height?: string | number; width?: string | number; maxWidth?: string | number }>`
    ${(props) => css`
        border: 1px solid ${props.theme.colors.outlineLight};
        border-radius: 10px;
    `};
`;
const Heading = styled.div`
    ${(props) => css`
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        font-family: 'Albert-Regular';
        font-size: 24px;
        font-weight: 700;
        color: ${props.theme.colors.black};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 18px;
        `};
    `};
`;

const Header = styled.div`
    ${(props) => css`
        background-color: #e9ede7;
        padding: 24px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
    `}
`;

const Divider = styled.div`
    ${(props) => css`
        height: 1px;
        background-color: ${props.theme.colors.outlineLight};
        margin: 24px 0;
        ${!props.theme.screen.desktop &&
        css`
            margin: 16px 0 22px;
        `}
    `}
`;

const Wrapper = styled.div`
    padding: 24px 24px 16px;
`;

const Description = styled.p`
    font-size: 16px;
`;

const CompanyLink = styled.a`
    color: #dd0014;
    text-decoration: none;
    cursor: pointer;
`;

const InnerButtonWrap = styled.div`
    ${(props) => css`
        font-family: 'Albert-Regular';
        font-size: 15px;
        font-weight: 500;
        line-height: 19px;
        color: ${props.theme.colors.brandRed};
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: flex-start;
        &.branches-button {
            margin-top: 8px;
            ${!props.theme.screen.desktop &&
            css`
                margin-top: 16px;
            `}
        }
        ${!props.theme.screen.desktop &&
        css`
            font-size: 14px;
            line-height: 20px;
        `}
    `}
`;
const InnerButton = styled.div`
    &:hover {
        font-weight: 700;
    }
`;

const ArrowForwardIcon = styled(arrowForwardIcon)`
    height: 18px !important;
    width: 18px !important;
    margin-left: 10px;
`;

interface Props {
    banner: BannerItem;
    adUrl: string;
    adHref: string;
    header?: string;
    customDimensions?: {
        width?: string;
        height?: string;
        maxWidth?: string;
    };
    fullWidth?: boolean;
    cleanBorder?: boolean;
    reviewAd?: boolean;
}

export const BannerCategorySupporter = (props: Props) => {
    const navigate = useNavigate();

    const cardInnerPage = () => {
        navigate(`/imones-kortele/${props.banner.companyId}`);
    };
    return (
        <InnerWrapper
            height={props.customDimensions?.height || '100%'}
            width={props.customDimensions?.width}
            maxWidth={props.customDimensions?.maxWidth}
        >
            <Header>{t('category_sponsor')}</Header>
            <Wrapper>
                <Card src={props.adUrl} />
                <Divider />
                {props.header && <Heading>{props.banner.header}</Heading>}
                <Description>{props.banner.summary}</Description>
            </Wrapper>
            <Wrapper>
                <InnerButtonWrap onClick={cardInnerPage} className="branches-button">
                    <InnerButton className="inner-button">{t('more_about_the_company')}</InnerButton>
                    <ArrowForwardIcon fontSize="small" />
                </InnerButtonWrap>
            </Wrapper>
        </InnerWrapper>
    );
};
