import { createSlice } from '@reduxjs/toolkit';
import { initialUserState, IUserState } from './types';

const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        setUser: (state: IUserState, action) => {
            state.me = action.payload;
        },
    },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
