import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Link, useLocation, useParams } from 'react-router-dom';
import { routes } from '../../constants/routes';
import classnames from 'classnames';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { IState } from '../../store';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const BreadCrumbsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 8px;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            padding-bottom: 0;
        `}
`;
const BreadCrumbsHolder = styled.div`
    width: 1254px;
    margin: 0 16px;
    display: flex;
    justify-content: space-between;
`;
const BreadCrumbItem = styled(Link)`
    ${(props) => css`
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.005em;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: ${props.theme.colors.outlineAndPlaceholder};
        fill: ${props.theme.colors.outlineAndPlaceholder};
        margin: 24px 0 16px;
        flex: 1;
        &.active {
            color: ${props.theme.colors.brandRed};
        }
    `}
`;
const StyledMuiBreadcrumbs = styled(MuiBreadcrumbs)`
    .MuiButtonBase-root {
        background-color: ${(props) => props.theme.colors.white};
    }
    .MuiBreadcrumbs-separator {
        margin-top: 8px;
    }
`;

const Icon = styled.div`
    ${(props) => css`
        padding-left: 8px;
        color: ${props.theme.colors.darkNeutral};
        ${props.theme.screen.mobile &&
        css`
            align-self: center;
            height: 24px;
            padding-left: 0px;
            &.img {
                width: 100%;
            }
        `}
    `}
`;

const Edit = styled.a`
    display: flex;
    align-items: center;
`;

export const Breadcrumbs = () => {
    const urlParams = useParams();
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const { user } = useSelector((state: IState) => state);
    const breadcrumbs = useBreadcrumbs(routes);
    const location = useLocation();

    return (
        <BreadCrumbsWrapper>
            <BreadCrumbsHolder>
                <StyledMuiBreadcrumbs separator={'/'} maxItems={screenViewPort === 'desktop' ? 4 : 2}>
                    {breadcrumbs.map(({ match, breadcrumb }, index) => {
                        if (
                            match.params?.headId === 'imones-kortele' ||
                            match.params?.topicId === 'imones-kortele' ||
                            match.params?.categoryId === 'imones-kortele'
                        ) {
                            return null;
                        }
                        return (
                            <BreadCrumbItem
                                key={index}
                                to={match.pathname}
                                className={classnames({
                                    active: match.pathname === location.pathname,
                                })}
                            >
                                {breadcrumb}
                            </BreadCrumbItem>
                        );
                    })}
                </StyledMuiBreadcrumbs>
                {urlParams.companyId && user?.me?.companyId && user?.me?.companyId == parseInt(urlParams.companyId) && (
                    <Edit
                        href={`https://klientams.imones.lt/web#id=${urlParams.companyId}&cids=1&menu_id=257&action=374&model=res.partner&view_type=form`}
                        target="_blank"
                    >
                        <Icon>
                            <EditOutlinedIcon className="img" />
                        </Icon>
                    </Edit>
                )}
            </BreadCrumbsHolder>
        </BreadCrumbsWrapper>
    );
};
